import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { IProject } from "../services";

// export interface IProjectList {
//   id: number;
//   title: string;
//   description: string;
//   system_tone: string;
//   is_public: boolean;
//   internal_knowledge_only: boolean;
//   icon: string;
//   priority: number;
// }

interface LoadingState {
  load: boolean;
  loadingProgess: number;
  loadingText: string;
}

interface ProjectState {
  projects: IProject[];
  loading: LoadingState; // progresso de 0 a 100 e texto de carregamento
}

const initialState: ProjectState = {
  projects: [],
  loading: { load: false, loadingProgess: 0, loadingText: "" },
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    actionSetProjects(state, action: PayloadAction<IProject[]>) {
      state.projects = action.payload;
      state.loading = { load: false, loadingProgess: 0, loadingText: "" };
    },
    actionAddProject(state, action: PayloadAction<IProject>) {
      state.projects.push(action.payload);
      state.loading = { load: false, loadingProgess: 0, loadingText: "" };
    },
    actionSetLoading(state, action: PayloadAction<LoadingState>) {
      state.loading = action.payload;
    },
  },
});

export const { actionSetProjects, actionSetLoading, actionAddProject } = projectSlice.actions;

export const selectorLoading = (state: RootState) => state.project.loading;
export const selectorLoadingProgress = (state: RootState) => state.project.loading.loadingProgess;
export const selectorProjects = (state: RootState) => state.project.projects;

export default projectSlice.reducer;
