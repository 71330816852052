/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import ProfileDropdown from "../components/Common/ProfileDropdown";
import LanguageDropdown from "../components/Common/LanguageDropdown";
import { useDispatch } from "react-redux";
import { changeSidebarVisibility } from "../slices/thunks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectPageTitle } from "../redux/layout/selectors";

interface HeaderProps {
  headerClass: string;
}

const Header = ({ headerClass }: HeaderProps) => {
  const dispatch: any = useDispatch();
  const title = useSelector(selectPageTitle);
  const agent = useSelector(selectPageTitle);
  const { t } = useTranslation();

  const toogleMenuBtn = () => {
    const windowSize = document.documentElement.clientWidth;
    const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;
    dispatch(changeSidebarVisibility("show"));

    //faz o icone do hamburguer mudar quando abre ou fecha o menu lateral
    if (windowSize > 767) humberIcon.classList.toggle("open");

    if (windowSize < 1025 && windowSize > 767) {
      document.body.classList.remove("vertical-sidebar-enable");
      document.documentElement.getAttribute("data-sidebar-size") === "sm"
        ? document.documentElement.setAttribute("data-sidebar-size", "")
        : document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    } else if (windowSize > 1025) {
      document.body.classList.remove("vertical-sidebar-enable");
      document.documentElement.getAttribute("data-sidebar-size") === "lg"
        ? document.documentElement.setAttribute("data-sidebar-size", "sm-hover")
        : document.documentElement.setAttribute("data-sidebar-size", "lg");
    } else if (windowSize <= 767) {
      document.body.classList.add("vertical-sidebar-enable");
      document.documentElement.setAttribute("data-sidebar-size", "lg");
    }
  };

  const [projectTitle, setProjectTitle] = useState("");

  useEffect(() => {
    if (!title) return;
    setProjectTitle(title);
  }, [title]);

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
              {title ? (
                <div className="title-container">
                  <span>
                    <b className="navbar-title-custom">{projectTitle}</b>
                  </span>
                  <span>
                    <small className="text-muted">
                      {t("pages.home.currentModel")} OpenAI GPT-4o
                    </small>
                  </span>
                </div>
              ) : null}
            </div>

            <div className="d-flex align-items-center">
              <LanguageDropdown />
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
