import styled from "styled-components";
import { FormText } from "reactstrap";

export const ProjectDetailsContainer = styled.section`
  width: auto;
  max-width: 920px;
  margin: 70px auto 0 auto;
  min-height: calc(100svh - 70px);
  padding: 0 1rem;

  .page-title {
    font-size: 26px;
    line-height: 31.69px;
    padding: 4rem 0;
  }
`;

export const GptForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 1.5rem;

  .form-item {
    .form-description {
      font-size: 11.55px;
      line-height: 13.98px;
      color: var(--vz-gray-800);
      margin-bottom: 0.8rem;
      max-width: 590px;
    }
  }
`;

export const FormInputText = styled(FormText)<{ error?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: ${(props) => (props.error ? "red" : "var(--vz-gray-600)")};
  }
`;

export const GptKnowledgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 2rem;
  .title {
    font-size: 13px;
    line-height: 15.85px;
    letter-spacing: 0.2px;
    font-weight: 600;
    color: #7438b0;
    margin-top: 56px;
  }

  .subtitle {
    font-size: 13px;
    line-height: 15.85px;
    letter-spacing: 0.2px;
    font-weight: 600;
    color: var(--vz-gray-600);
    margin-top: 22px;
  }

  .description {
    font-weight: 400;
    font-size: 13.2px;
    line-height: 20px;
    color: var(--vz-gray-800);
    margin-bottom: 0;
    .internal-policy-link {
      color: var(--vz-info-600);
      cursor: pointer;
    }
  }

  .share-header-container {
    display: flex;
    margin: 2rem 0 1rem 0;
    flex-direction: column;
    gap: 1rem;
    .title {
      margin-bottom: 0;
    }
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  @media (min-width: 768px) {
    padding-bottom: 5rem;
  }
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 5rem;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .create-btn {
      flex: 1;
      max-width: 218px;
    }
  }
`;
