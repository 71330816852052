import { useEffect, useState } from "react";
import moment from "moment";
import { Avatar, Typography, Button } from "antd";
import {
  GridContainer,
  ChatColumn,
  BubbleContainer,
  BubbleContent,
  MessageBox,
  ReferenceContainer,
  StyledMarkdown,
  LegendText,
  ReferenceBox,
} from "./styled";
import { useTranslation } from "react-i18next";
import { LANGUAGE_LOCAL_STORAGE } from "../../../utils/setLanguageToStorage";
import {
  RiFileMarkedLine,
  RiFileCopyLine,
  RiAlertLine,
  RiCheckboxCircleLine,
} from "@remixicon/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

interface IMessageBubbleProps {
  markdownText: string;
  float?: "left" | "right";
  references?: string[];
  date_time?: string;
  showResponseTime?: boolean;
}

function markdownToHtmlTable(markdown: string): string {
  const lines = markdown
    .trim()
    .split("\n")
    .filter((line) => line.trim() !== "");

  console.log("Linhas capturadas:", lines);

  if (lines.length < 3) {
    return "<p>Tabela inválida ou sem conteúdo suficiente.</p>";
  }

  const headers = lines[1]
    .split("|")
    .filter(Boolean)
    .map((header) => header.trim());
  const separatorLine = lines[2].trim();
  console.log("Linha separadora:", separatorLine);

  if (!/^\s*\|\s*-+\s*(\|\s*-+\s*)*\|\s*$/.test(separatorLine)) {
    return "<p>Formato de tabela inválido.</p>";
  }

  let html = "<table style='border-collapse: collapse, width: 100%'>\n  <thead>\n    <tr>\n";
  headers.forEach((header) => {
    html += `      <th style='padding: 8px; text-align: left; border: 1px solid #2eb7a6; background-color:#c2eae5'>${header}</th>\n`;
  });
  html += "    </tr>\n  </thead>\n  <tbody>\n";

  for (let i = 3; i < lines.length; i++) {
    const row = lines[i]
      .split("|")
      .filter(Boolean)
      .map((cell) => cell.trim());
    if (row.length === headers.length) {
      html += "    <tr>\n";
      row.forEach((cell) => {
        html += `      <td style='padding: 8px; text-align: left; border: 1px solid #2eb7a6'>${cell}</td>\n`;
      });
      html += "    </tr>\n";
    }
  }

  html += "  </tbody>\n</table>";
  return html;
}

// // Check if the markdownText contains a table
// const containsTable = /\|.*\|.*\n\|\s*-+(\s*\|+\s*-+)*\|/.test(markdownToHtmlTable);

const MessageBubble = ({
  references,
  float,
  markdownText,
  date_time,
  showResponseTime = true,
}: IMessageBubbleProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [showReferences, setShowReferences] = useState(false);
  const selectedLanguage = localStorage.getItem(LANGUAGE_LOCAL_STORAGE);
  const [momentFinal, setMomentFinal] = useState(moment(date_time).fromNow());
  const { t } = useTranslation();
  const isBotBubble = float === "left" ? "bot" : "user";
  const maxSteps = references?.length || 0;

  // Check if the markdownText contains a table
  const containsTable = /\|.*\|.*\n\|\s*-+(\s*\|+\s*-+)*\|/.test(markdownText);

  const handleClickLeft = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? prevActiveStep : prevActiveStep - 1));
  };

  const handleClickRight = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === maxSteps - 1 ? prevActiveStep : prevActiveStep + 1,
    );
  };

  const copyMessage = () => {
    navigator.clipboard
      .writeText(markdownText)
      .then(() => {
        toast(t("Toast.copySuccess"), {
          toastId: "success1",
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          autoClose: 5000,
          className: "bg-success text-white",
          closeButton: true,
          icon: <RiCheckboxCircleLine />,
        });
      })
      .catch((err) => {
        toast(t("Toast.copyError") + err, {
          toastId: "error1",
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          autoClose: 5000,
          className: "bg-danger text-white",
          closeButton: true,
          icon: <RiAlertLine />,
        });
      });
  };

  useEffect(() => {
    if (!selectedLanguage) return;
    moment.updateLocale(selectedLanguage, {
      relativeTime: {
        future: t("global.relativeTime.future"),
        past: t("global.relativeTime.past"),
        s: t("global.relativeTime.s"),
        m: t("global.relativeTime.m"),
        mm: t("global.relativeTime.mm"),
        h: t("global.relativeTime.h"),
        hh: t("global.relativeTime.hh"),
        d: t("global.relativeTime.d"),
        dd: t("global.relativeTime.dd"),
        M: t("global.relativeTime.M"),
        MM: t("global.relativeTime.MM"),
        y: t("global.relativeTime.y"),
        yy: t("global.relativeTime.yy"),
      },
    });
    setMomentFinal(moment(date_time).fromNow());
  }, [selectedLanguage]);

  return (
    <GridContainer>
      <ChatColumn>
        <BubbleContainer>
          <BubbleContent $owner={isBotBubble}>
            {isBotBubble === "bot" ? (
              <Avatar
                // src="/kyno-avatar.svg"
                className="bot_avatar"
                src="/kyno-avatar.svg"
                style={{
                  backgroundColor: "#F3F6F9",
                  width: "50px",
                  height: "50px",
                  position: "absolute",
                  left: "-55px",
                  top: 0,
                  zIndex: 90,
                }}
              />
            ) : null}
            <MessageBox>
              {containsTable ? (
                <div dangerouslySetInnerHTML={{ __html: markdownToHtmlTable(markdownText) }} />
              ) : (
                <StyledMarkdown>{markdownText}</StyledMarkdown>
              )}
            </MessageBox>
            {isBotBubble === "bot" && (
              <>
                {references && references.length ? (
                  <ReferenceContainer>
                    <RiFileMarkedLine
                      size={20}
                      color="#878a99"
                      cursor={"pointer"}
                      onClick={() => setShowReferences((prev) => !prev)}
                    />
                    <RiFileCopyLine
                      size={20}
                      color="#878a99"
                      cursor={"pointer"}
                      onClick={() => copyMessage()}
                    />

                    {references.length && showReferences ? (
                      <ReferenceBox>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <button
                            onClick={() => setShowReferences((prev) => !prev)}
                            color="light"
                            className="btn-close mt-3 me-3"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div
                          style={{
                            marginTop: "8px",
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "2px",
                            alignItems: "center",
                            paddingLeft: "20px",
                            paddingTop: "15px",
                          }}
                        >
                          <Button
                            icon={
                              <KeyboardArrowLeft
                                sx={{
                                  color: activeStep === 0 ? "#ccc" : "#888",
                                  cursor: "pointer",
                                }}
                              />
                            }
                            onClick={handleClickLeft}
                            disabled={activeStep === 0}
                          ></Button>
                          <Typography.Text
                            style={{
                              width: "419px",
                              height: "32px",
                              border: "1px solid #ccc",
                              padding: "4px 12px",
                              borderRadius: "4px",
                              display: "inline-block",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                              marginLeft: "10px",
                              marginRight: "10px",
                            }}
                            color="#006458"
                          >
                            {showReferences
                              ? `(${activeStep + 1}/${maxSteps})  ${references?.[activeStep]}`
                              : null}
                          </Typography.Text>
                          <Button
                            icon={
                              <KeyboardArrowRight
                                sx={{
                                  color: activeStep === maxSteps - 1 ? "#ccc" : "#888",
                                  cursor: "pointer",
                                }}
                              />
                            }
                            onClick={handleClickRight}
                            disabled={activeStep === maxSteps - 1}
                          ></Button>
                        </div>
                        {showReferences && (
                          <LegendText
                            style={{
                              paddingLeft: "20px",
                              paddingBottom: "25px",
                            }}
                          >
                            Atenção: A IA Generativa cria respostas novas e criativas com base
                            nesses documentos,<br></br> sem replicá-los. Analise criticamente as
                            respostas geradas.
                          </LegendText>
                        )}
                      </ReferenceBox>
                    ) : null}
                  </ReferenceContainer>
                ) : null}
              </>
            )}
            {date_time && showResponseTime && (
              <Typography.Text className="message_time" type="secondary" style={{ fontSize: 12 }}>
                {momentFinal}
              </Typography.Text>
            )}
          </BubbleContent>
        </BubbleContainer>
      </ChatColumn>
    </GridContainer>
  );
};

export { MessageBubble };
