import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  width: 100%;
  max-width: 500px;

  img {
    margin-bottom: 2rem;
  }

  .modal-title {
    font-size: 19.5px;
    font-weight: 600;
    line-height: 23.77px;
    letter-spacing: 0.4px;
    color: var(--vz-gray-800);
  }

  .modal-message {
    font-size: 13.2px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 0.33rem;
    color: var(--vz-gray-500);
  }

  .action-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
  }
`;
