
const languages = {
  es: {
    label: "Espanhol",
    flag: "countryFlags/spain.svg",
  },
  en: {
    label: "Inglês",
    flag: "countryFlags/usa.svg",
  },
  pt: {
    label: "Português",
    flag: "countryFlags/brazil.svg",
  },
};

export default languages;
