import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DivMain,
  DivSearch,
  TabContainer,
  LoadingSpinner,
  PromptList,
  Container,
  PageHeaderPrompt,
} from "./styled";
import { IPrompt, promptsService } from "../../services/prompts";
import { RiAddBoxLine, RiSearchLine } from "@remixicon/react";
import { NewPromptItem } from "../../components/Prompts/NewPromptItem";
import { CreateModalPrompt } from "../../components/Modals/NewPromptModal";
import { Button, Spinner, Nav, NavItem, NavLink, Input } from "reactstrap";
import EmptyPrompt from "../../assets/empty-prompt.png";
import classnames from "classnames";

const NewPrompts: React.FC = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(3);
  const [prompts, setPrompts] = useState<IPrompt[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedPromptId, setSelectedPromptId] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const addToPrompts = (newPrompt: IPrompt) => {
    setPrompts((prev) => (prev ? [newPrompt, ...prev] : [newPrompt]));
  };

  const handleSelectPrompt = (promptId: number) => {
    setSelectedPromptId(promptId);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (index: number) => {
    setValue(index);
  };

  const removeFromPrompts = (promptId: number) => {
    setPrompts((prev) => prev?.filter((prompt) => prompt.id !== promptId));
  };

  const filteredPrompts = prompts?.filter(
    (prompt) =>
      prompt.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      prompt.prompt.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const updatePrompt = (updatedPrompt: IPrompt) => {
    setPrompts((prevPrompts) =>
      prevPrompts?.map((prompt) => (prompt.id === updatedPrompt.id ? updatedPrompt : prompt)),
    );
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let _prompts;
      if (value === 0) {
        _prompts = await promptsService.getLatest();
      } else if (value === 1) {
        _prompts = await promptsService.getTop();
      } else if (value === 2) {
        _prompts = await promptsService.getLiked();
      } else if (value === 3) {
        _prompts = await promptsService.getOwn();
      }
      setPrompts(_prompts);
      setIsLoading(false);
    })();
  }, [value]);

  const handleConfirm = () => {
    handleClose();
  };

  return (
    <DivMain>
      <PageHeaderPrompt>
        <div className="page-header-top">
          <h2 className="title">Prompts</h2>
        </div>
      </PageHeaderPrompt>
      <DivSearch>
        <div className="input-container">
          <RiSearchLine size="1rem" className="search-icon" />
          <Input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={t("global.search")}
            className="input-with-icon"
          />
        </div>
        <Button
          color="primary"
          className={classnames({ "btn-load": isLoading }, "btn-new-prompt", "btn-label")}
          disabled={isLoading}
          onClick={handleOpen}
        >
          {isLoading ? (
            <i className="label-icon align-middle fs-16 me-2">
              <Spinner size="sm"></Spinner>
            </i>
          ) : (
            <i className="label-icon align-middle fs-16 me-2">
              <RiAddBoxLine size="1rem" />
            </i>
          )}
          <p>{t("pages.prompts.components.newProjectBtn")}</p>
        </Button>
      </DivSearch>
      <CreateModalPrompt
        onAddPrompt={addToPrompts}
        ModalToggle={open}
        setModalToggle={setOpen}
        onConfirm={handleConfirm}
      />
      <TabContainer>
        <Nav className="nav-tabs-custom nav-primary">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={value === 3 ? "active" : ""}
              onClick={() => handleChange(3)}
            >
              {t("pages.prompts.components.tabMenu.my")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={value === 2 ? "active" : ""}
              onClick={() => handleChange(2)}
            >
              {t("pages.prompts.components.tabMenu.likes")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={value === 1 ? "active" : ""}
              onClick={() => handleChange(1)}
            >
              {t("pages.prompts.components.tabMenu.top")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={value === 0 ? "active" : ""}
              onClick={() => handleChange(0)}
            >
              {t("pages.prompts.components.tabMenu.all")}
            </NavLink>
          </NavItem>
        </Nav>
      </TabContainer>
      <PromptList>
        {isLoading ? (
          <LoadingSpinner />
        ) : prompts?.length === 0 ? (
          <Container>
            <img style={{ paddingTop: "30px" }} src={EmptyPrompt} />
            <h5
              style={{
                fontWeight: "bold",
                fontSize: "Montserrat",
                color: "#ADB5BD",
                fontFamily: "Montserrat",
                textAlign: "center",
                paddingTop: "20px",
              }}
              className="mb-3"
            >
              Você ainda não possui nenhum prompt
            </h5>
          </Container>
        ) : filteredPrompts?.length === 0 ? (
          <Container>
            <img style={{ paddingTop: "30px" }} src={EmptyPrompt} />
            <h5
              style={{
                fontWeight: "bold",
                fontSize: "Montserrat",
                color: "#ADB5BD",
                fontFamily: "Montserrat",
                textAlign: "center",
                paddingTop: "20px",
              }}
              className="mb-3"
            >
              Opa! Sua busca não teve nenhum resultado!
            </h5>
          </Container>
        ) : (
          filteredPrompts?.map((prompt) => (
            <NewPromptItem
              currentTab={value}
              key={prompt.id}
              prompt={prompt}
              removeFromPrompts={removeFromPrompts}
              selectedPromptId={selectedPromptId}
              onSelectPrompt={handleSelectPrompt}
              updatePrompt={updatePrompt}
            />
          ))
        )}
      </PromptList>
    </DivMain>
  );
};

export { NewPrompts };
