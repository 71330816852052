import React, { useEffect } from "react";
import {
  LoginPageBackground,
  FormContainer,
  LogoContainer,
  Logo,
  BoxButton,
  ParagraphForm,
  AlingLogo,
  ContentContainer,
  RectanguleVideo,
  LogoKynoImage,
  ContainerProduct,
} from "./styled";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { actionDisplayNotification } from "../../redux/notificationSlice";
import {
  actionLoginWithGoogle,
  IIncomingTokenCredentials,
  selectError,
} from "../../redux/authenticationSlice";
// import { GoogleLogin } from "../../components/Login/GoogleLogin";
import { MicrosoftLogin } from "../../components/Login/MicrosoftLogin";
import { app } from "@microsoft/teams-js";
import { authService } from "../../services";
import * as jwt from "jwt-decode";
import logoImage from "../../assets/logo_kyno_login.png";
import sampleVideo from "../../assets/KynoLogin06.gif";
import productTimenowLogo from "../../assets/product_timenow_logo.png";
// import LanguageDropdown from "../../components/Common/LanguageDropdown";

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectError);

  useEffect(() => {
    // Inicializar a SDK do Teams
    app
      .initialize()
      .then(() => {
        // Verificar se a aplicação está sendo executada dentro do Teams
        app
          .getContext()
          .then(async (context: app.Context) => {
            if (context.user?.userPrincipalName) {
              const data = await authService.teamsSignin(context.user.userPrincipalName);
              const decodedToken = jwt.jwtDecode(data.access_token) as IIncomingTokenCredentials;

              if (decodedToken) {
                const email = (decodedToken as any).email;
                const role = (decodedToken as any).is_admin ? "Administrador" : "Usuário";
                const username = email ? email.split("@")[0] : "";

                const [firstname, lastname] = username
                  .split(".")
                  .map((part: any) => part.charAt(0).toUpperCase() + part.slice(1));

                const userData = {
                  name: firstname + " " + lastname,
                  role: role,
                  username: username,
                };

                localStorage.setItem("userData", JSON.stringify(userData));

                dispatch(actionLoginWithGoogle(data));
              }
            } else {
              console.error("User principal name is undefined");
            }
          })
          .catch((error) => {
            console.error("Erro ao obter o contexto do Teams:", error);
          });
      })
      .catch((error) => {
        console.error("Erro ao inicializar o Microsoft Teams SDK:", error);
      });
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(
        actionDisplayNotification({
          messages: [error],
          severity: "error",
        }),
      );
    }
  }, [error, dispatch]);

  return (
    <LoginPageBackground>
      <LogoContainer>
        <AlingLogo>
          <Logo src={logoImage} alt="Logo" />
        </AlingLogo>
      </LogoContainer>
      <FormContainer>
        <ContentContainer>
          {/* <LanguageDropdown /> */}
          <ParagraphForm>
            Seja bem-vindo(a)! Faça o login para entrar na{" "}
            <strong style={{ color: "#00a793" }}>
              plataforma de agentes de Inteligência Artificial
            </strong>{" "}
            da Timenow.
          </ParagraphForm>
          {/* <BoxButton>
            <GoogleLogin />
          </BoxButton> */}
          <BoxButton>
            <MicrosoftLogin />
          </BoxButton>
        </ContentContainer>
        {/* <LanguageContainer>
          <LanguageDropdown/>
        </LanguageContainer> */}
      </FormContainer>
      <ContainerProduct>
        <label
          style={{
            color: "#495057",
            fontSize: "13px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
          className="mb-3"
        >
          Um produto
          <img
            src={productTimenowLogo}
            alt="Timenow Logo"
            style={{
              maxWidth: "65px",
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </label>
      </ContainerProduct>
      <LogoKynoImage />
      <RectanguleVideo>
        <img
          src={sampleVideo}
          alt="Sample GIF"
          style={{
            width: "100%",
            height: "101%",
            objectFit: "cover",
            paddingLeft: "160px",
          }}
        />
      </RectanguleVideo>
    </LoginPageBackground>
  );
};

export { LoginPage };
