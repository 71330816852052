/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Badge,
  CardBody,
  Col,
  Card,
  Container,
  Row,
  Button,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalBody,
} from "reactstrap";
import { Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import EmptyCardPrompt from "../EmptyCardPrompt";
import { IPrompt } from "../../../services/prompts";
import { Typography } from "antd";
import { EmptyPromptItem } from "../../Prompts/EmptyPromptItem";
import { useNavigate } from "react-router-dom";
import EmptyPrompt from "../../../assets/empty-prompt.png";
import { ModalHeaderEmptyState } from "./styled";
import { routesManager } from "../../../routes/routesManager";

interface IBadgeSelectorProps {
  onBadgeSelect: (index: number) => void;
  selectedBadge: number | null;
  topPrompt: IPrompt[];
  likedPrompt: IPrompt[];
  ownPrompt: IPrompt[];
  isLoading: boolean;
}

const BadgeSelector = ({
  onBadgeSelect,
  selectedBadge,
  topPrompt,
  likedPrompt,
  ownPrompt,
  isLoading,
}: IBadgeSelectorProps) => {
  const { t } = useTranslation();
  const badges = [
    t("pages.prompts.components.tabMenu.my"),
    t("pages.prompts.components.tabMenu.likes"),
    t("pages.prompts.components.tabMenu.top")
  ];
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState(0);
  const handleTabChange = (index: number) => setValue(index);

  const emptyMessages = [
    {
      title: t("pages.prompts.components.tabMenu.top"),
      messageLine1: t("pages.prompts.conversation.top.messageLine1"),
      messageLine2: t("pages.prompts.conversation.top.messageLine2"),
    },
    {
      title: t("pages.prompts.components.tabMenu.likes"),
      messageLine1: t("pages.prompts.conversation.likes.messageLine1"),
      messageLine2: t("pages.prompts.conversation.likes.messageLine2"),
    },
    {
      title: t("pages.prompts.components.tabMenu.my"),
      messageLine1: t("pages.prompts.conversation.my.messageLine1"),
      messageLine2: t("pages.prompts.conversation.my.messageLine2"),
    },
  ];

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    if (selectedBadge === null) {
      onBadgeSelect(0);
    }
  }, [onBadgeSelect, selectedBadge]);

  const renderPrompts = () => {
    const prompts = selectedBadge === 0 ? topPrompt : selectedBadge === 1 ? ownPrompt : likedPrompt;
    const emptyState = emptyMessages[selectedBadge || 0];

    return (
      <Container fluid style={{ paddingLeft: "3px", paddingTop: "5px" }}>
        <Row>
          <Col lg={12} style={{ paddingLeft: "0px" }}>
            <Card style={{ boxShadow: "none", marginBottom: "0.5rem" }}>
              <CardBody style={{ padding: "0px" }}>
                <Swiper
                  slidesPerView={2}
                  spaceBetween={1}
                  pagination={{
                    el: ".swiper-pagination",
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 1,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 1,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 1,
                    },
                  }}
                  loop={false}
                  modules={[Pagination]}
                  className="mySwiper swiper responsive-swiper rounded pb-4"
                >
                  <div className="swiper-wrapper">
                    {selectedBadge === 0 && (
                      <SwiperSlide onClick={openModal}>
                        <div
                          className="gallery-box card"
                          style={{
                            marginTop: "8px",
                            width: "93%",
                            maxHeight: "100%",
                            height: "90px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "2px solid #EFF2F7",
                            borderRadius: "8px",
                            backgroundColor: "#F8F9FA",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                        >
                          <div className="gallery-container">
                            <p style={{ color: "#ADB5BD", fontWeight: "bold" }}>
                              {t("pages.projects.components.emptyState.promptCatalog")}
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                    )}
                    {prompts.length > 0
                      ? prompts.map((prompt) => (
                          <SwiperSlide key={prompt.id}>
                            <div
                              className="gallery-box card"
                              style={{ paddingBottom: "0px", marginBottom: "7px" }}
                            >
                              <div className="gallery-container">
                                <EmptyCardPrompt
                                  key={prompt.id}
                                  title={prompt.title}
                                  text={prompt.prompt}
                                  isLoading={isLoading}
                                />
                              </div>
                            </div>
                          </SwiperSlide>
                        ))
                      : selectedBadge !== 0 && (
                          <div
                            style={{
                              width: "880px",
                              height: "88px",
                              paddingLeft: "12px",
                              border: "2px solid",
                              borderColor: "#EFF2F7",
                              marginBottom: "7px",
                              borderRadius: "4px",
                              paddingTop: "16px",
                            }}
                          >
                            <div>
                              <p
                                color="#363D48"
                                className="fw-bolder"
                                style={{ fontFamily: "Inter" }}
                              >
                                {emptyState.messageLine1}
                              </p>
                              <p color="#a9a9a9" className="fs-6" style={{ fontFamily: "Inter" }}>
                                {emptyState.messageLine2}
                              </p>
                            </div>
                          </div>
                        )}
                  </div>
                  <div className="swiper-pagination swiper-pagination-dark"></div>
                </Swiper>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };

  const renderModalContent = () => {
    const prompts = value === 0 ? topPrompt : value === 1 ? ownPrompt : likedPrompt;

    if (prompts.length === 0) {
      return (
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            style={{
              paddingTop: "30px",
            }}
            src={EmptyPrompt}
            alt="Empty Prompt"
          />
          <h5
            style={{
              fontWeight: "bold",
              fontSize: "Montserrat",
              color: "#ADB5BD",
              fontFamily: "Montserrat",
              textAlign: "center",
              paddingTop: "20px",
            }}
            className="mb-3"
          >
            Você ainda não possui nenhum prompt
          </h5>
        </Container>
      );
    }

    return (
      <div className="modal-prompt-content-custom">
        {prompts.map((prompt) => (
          <EmptyPromptItem key={prompt.id} prompt={prompt} />
        ))}
      </div>
    );
  };

  return (
    <div>
      <div>
        {badges.map((text, index) => (
          <Badge
            key={index}
            color={selectedBadge === index ? "primary" : undefined}
            onClick={() => onBadgeSelect(index)}
            className={selectedBadge !== index ? "badge bg-primary-subtle text-primary" : ""}
            style={{ marginLeft: index !== 0 ? "10px" : "0", paddingBottom: "5px" }}
          >
            {text}
          </Badge>
        ))}
      </div>
      {renderPrompts()}
      <Modal isOpen={isModalOpen} toggle={closeModal} size="lg" centered>
        <ModalHeaderEmptyState className="modal-header">
          <h5 style={{ paddingLeft: "6px" }} className="modal-title">
            {t("pages.projects.components.emptyState.promptCatalog")}
          </h5>
          <Button
            style={{
              transition: "background-color 0.3s ease",
            }}
            type="button"
            className="btn-close primary"
            aria-label="Close"
            onClick={closeModal}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#d3d3d3")}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "")}
          ></Button>
        </ModalHeaderEmptyState>
        <ModalBody>
          <Swiper
            style={{ width: "100%", overflow: "hidden", padding: "5px" }}
            slidesPerView={3}
            breakpoints={{
              640: {
                slidesPerView: 4,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            pagination={false}
            className="mySwiper swiper-nav-tabs"
          >
            <SwiperSlide>
              <Nav className="nav-tabs-custom nav-primary">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                    className={`${value === 1 ? "active" : ""}`}
                    onClick={() => handleTabChange(1)}
                  >
                    {t("pages.prompts.components.tabMenu.top")}
                  </NavLink>
                </NavItem>
              </Nav>
            </SwiperSlide>
            <SwiperSlide>
              <Nav className="nav-tabs-custom nav-primary">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                    className={`${value === 3 ? "active" : ""}`}
                    onClick={() => handleTabChange(3)}
                  >
                    {t("pages.prompts.components.tabMenu.my")}
                  </NavLink>
                </NavItem>
              </Nav>
            </SwiperSlide>
            <SwiperSlide>
              <Nav className="nav-tabs-custom nav-primary">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                    className={`${value === 2 ? "active" : ""}`}
                    onClick={() => handleTabChange(2)}
                  >
                    {t("pages.prompts.components.tabMenu.likes")}
                  </NavLink>
                </NavItem>
              </Nav>
            </SwiperSlide>
            <SwiperSlide>
              <Nav className="nav-tabs-custom nav-primary">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                    className={`${value === 0 ? "active" : ""}`}
                    onClick={() => handleTabChange(0)}
                  >
                    {t("pages.prompts.components.tabMenu.all")}
                  </NavLink>
                </NavItem>
              </Nav>
            </SwiperSlide>
          </Swiper>
          {renderModalContent()}

          <div style={{ paddingTop: "40px" }} className="modal-prompt-footer-custom">
            <Typography className="modal-prompt-showing">
              {value === 3 ? (
                <>
                  {t("pages.home.modal.showed", {
                    quantidade: ownPrompt.length,
                    total: ownPrompt.length,
                  })}
                </>
              ) : value === 2 ? (
                <>
                  {t("pages.home.modal.showed", {
                    quantidade: likedPrompt.length,
                    total: likedPrompt.length,
                  })}
                </>
              ) : value === 1 ? (
                <>
                  {t("pages.home.modal.showed", {
                    quantidade: topPrompt.length,
                    total: topPrompt.length,
                  })}
                </>
              ) : (
                <>
                  {t("pages.home.modal.showed", {
                    quantidade: [...topPrompt, ...likedPrompt, ...ownPrompt].length,
                    total: [...topPrompt, ...likedPrompt, ...ownPrompt].length,
                  })}
                </>
              )}
            </Typography>
            <Button
              color="light"
              style={{ fontSize: "11px" }}
              className="modal-prompt-button"
              onClick={() => {
                navigate(routesManager.getNewPromptsRoute());
              }}
            >
              {t("pages.home.modal.button")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BadgeSelector;
