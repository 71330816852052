import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import _ from "lodash";
import { SelectedUsersWithPermissions } from "../../../pages/Projects/form";
import { RiDeleteBinLine, RiSearch2Line } from "@remixicon/react";
import React, { useCallback, useEffect, useState } from "react";
import { GrantedUsersContainer } from "./styled";
import { projectService } from "../../../services";
import { useTranslation } from "react-i18next";

interface GrantedUsersTableProps {
  grantedUsers: SelectedUsersWithPermissions[];
  setGrantedUsers: React.Dispatch<React.SetStateAction<SelectedUsersWithPermissions[]>>;
  setDirt: React.Dispatch<React.SetStateAction<boolean>>;
  projectId: number | string;
  isEditing: boolean;
  isOwner: boolean;
}

const MAX_USERS_TO_PAGINATE = 8;
const DEBOUNCE_TIME = 250;

export function GrantedUsersTable({
  grantedUsers,
  setGrantedUsers,
  projectId,
  isEditing,
  setDirt,
  isOwner,
}: GrantedUsersTableProps) {
  const { t } = useTranslation();

  const [query, setQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<SelectedUsersWithPermissions[]>(grantedUsers);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(filteredUsers.length / MAX_USERS_TO_PAGINATE);
  const startUserIndex = (currentPage - 1) * MAX_USERS_TO_PAGINATE + 1;
  const endUserIndex = Math.min(currentPage * MAX_USERS_TO_PAGINATE, filteredUsers.length);

  function handleModifyPermissions(
    email: string,
    permissions: { delete: boolean; upload: boolean },
  ) {
    const newGrantedUsers = grantedUsers.map((grantedUser) => {
      if (grantedUser.user.email === email) {
        return {
          ...grantedUser,
          upload: permissions.upload,
          delete: permissions.delete,
          change: true,
        };
      }
      return grantedUser;
    });
    setGrantedUsers(newGrantedUsers);
    setDirt(true);
  }

  async function handleDeleteUser(email: string) {
    if (isEditing) {
      await projectService.deleteUserFromProject(projectId, email);
    }
    const newGrantedUsers = grantedUsers.filter((grantedUser) => grantedUser.user.email !== email);
    setGrantedUsers(newGrantedUsers);
    setDirt(true);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when filtering
  }

  const debouncedFilterUsers = useCallback(
    _.debounce((query: string) => {
      setFilteredUsers(
        grantedUsers.filter((grantedUser) =>
          grantedUser.user.email.toLowerCase().includes(query.toLowerCase()),
        ),
      );
    }, DEBOUNCE_TIME),
    [grantedUsers],
  );

  useEffect(() => {
    if (query === "") {
      setFilteredUsers(grantedUsers);
    } else {
      debouncedFilterUsers(query);
    }
  }, [query, grantedUsers]);

  function handlePageChange(event: React.MouseEvent, page: number) {
    event.stopPropagation();
    event.preventDefault();
    setCurrentPage(page);
  }

  const displayedUsers = filteredUsers.slice(
    (currentPage - 1) * MAX_USERS_TO_PAGINATE,
    currentPage * MAX_USERS_TO_PAGINATE,
  );

  if (grantedUsers.length === 0) {
    return null;
  }

  return (
    <GrantedUsersContainer>
      <InputGroup>
        <InputGroupText>
          <RiSearch2Line className="text-muted" size={11} />
        </InputGroupText>
        <Input
          placeholder="Buscar"
          type="search"
          className="input-search"
          value={query}
          onChange={handleInputChange}
        />
      </InputGroup>
      <Table className="align-middle table-nowrap mb-0" responsiveTag="div" responsive>
        <thead className="table-light">
          <tr>
            <th scope="col">{t("pages.projects.components.createEditPage.sharing.table.user")}</th>
            <th scope="col">
              {t("pages.projects.components.createEditPage.sharing.table.upload")}
            </th>
            <th scope="col">
              {t("pages.projects.components.createEditPage.sharing.table.delete")}
            </th>
            <th scope="col">
              {t("pages.projects.components.createEditPage.sharing.table.action")}
            </th>
          </tr>
        </thead>
        <tbody>
          {displayedUsers.map((grantedObj) => (
            <tr key={grantedObj.user.email}>
              <td>
                {grantedObj.user.name} <span className="text-muted">({grantedObj.user.email})</span>
              </td>
              <td>
                <div className="form-check form-switch form-switch-md">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={grantedObj.upload}
                    style={{ width: "30px", height: "16px" }}
                    onChange={() => {
                      if (!isOwner) return;
                      handleModifyPermissions(grantedObj.user.email, {
                        delete: grantedObj.delete,
                        upload: !grantedObj.upload,
                      });
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="form-check form-switch form-switch-md">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={grantedObj.delete}
                    style={{ width: "30px", height: "16px" }}
                    onChange={() => {
                      if (!isOwner) return;
                      handleModifyPermissions(grantedObj.user.email, {
                        delete: !grantedObj.delete,
                        upload: grantedObj.upload,
                      });
                    }}
                  />
                </div>
              </td>
              <td>
                <Button
                  type="button"
                  onClick={() => {
                    if (!isOwner) return;
                    handleDeleteUser(grantedObj.user.email);
                  }}
                  className="delete-user-btn"
                  color="link"
                >
                  <RiDeleteBinLine className="text-muted" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {filteredUsers.length > MAX_USERS_TO_PAGINATE && (
        <div className="pagination-container">
          <p className="pagination-info">
            {t("pages.projects.components.createEditPage.sharing.table.total", {
              startUserIndex,
              endUserIndex,
              filteredUsers: filteredUsers.length,
            })}
          </p>
          <Pagination size="md">
            <PaginationItem disabled={currentPage === 1}>
              <PaginationLink
                href="#"
                previous
                onClick={(e) => handlePageChange(e, currentPage - 1)}
              >
                {t("global.previousBtn")}
              </PaginationLink>
            </PaginationItem>
            {Array.from({ length: totalPages }).map((_, index) => (
              <PaginationItem active={index + 1 === currentPage} key={index}>
                <PaginationLink href="#" onClick={(e) => handlePageChange(e, index + 1)}>
                  {index + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem disabled={currentPage === totalPages}>
              <PaginationLink next href="#" onClick={(e) => handlePageChange(e, currentPage + 1)}>
                {t("global.nextBtn")}
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
      )}
    </GrantedUsersContainer>
  );
}
