import { createTheme } from "@mui/material";
import { ThemeConfig } from "antd";

const config: ThemeConfig = {
  token: {
    colorPrimary: "#00A793",
    colorText: "#3e4352",
    fontFamily: "Inter",
  },
};

const DefaultTheme = createTheme({
  typography: {
    fontFamily: "'Inter', 'Roboto', sans-serif",
  },
});

export { DefaultTheme, config };
