import { TextAreaComponent } from "../TextArea";
import { ICustomerData } from "../../../redux/authenticationSlice";
import { TextChatContainer2 } from "./styled";

interface ITextChatProps {
  currentMessage: string;
  handleChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  handleEnterPressed(e: React.KeyboardEvent<Element>): void;
  handleSendMessage(): Promise<void>;
  setSpeechResponse: (value: string) => void;
  customerData?: ICustomerData | null;
  messageCount: number;
  isEmptyState: boolean;
  isBlockedMessage: boolean;
}
const TextChat = ({
  currentMessage,
  handleChange,
  handleEnterPressed,
  handleSendMessage,
  messageCount,
  isEmptyState,
  isBlockedMessage,
  setSpeechResponse,
}: ITextChatProps) => {
  return (
    <TextChatContainer2>
      <TextAreaComponent
        isEmptyState={isEmptyState}
        value={currentMessage}
        onChange={handleChange}
        onKeyDown={handleEnterPressed}
        handleSendMessage={handleSendMessage}
        messageCount={messageCount}
        isBlockedMessage={isBlockedMessage}
        setSpeechResponse={setSpeechResponse}
      />
    </TextChatContainer2>
  );
};
export { TextChat };
