/* eslint-disable no-console */
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "./authConfig";
import { useEffect, useState } from "react";
import { authService } from "../../../services";
import * as jwt from "jwt-decode";
import {
  IIncomingTokenCredentials,
  actionLoginWithGoogle,
} from "../../../redux/authenticationSlice";
import { useAppDispatch } from "../../../redux/hooks";
import "./index.css";

const msalInstance = new PublicClientApplication(msalConfig);
console.log(msalConfig);

const MicrosoftLogin = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await msalInstance.initialize();
        setIsInitialized(true);
      } catch (error) {
        console.error("MSAL initialization failed:", error);
      }
    };

    initializeMsal();
  }, []);

  const handleLogin = async () => {
    if (!isInitialized) {
      console.error("MSAL is not initialized");
      return;
    }

    try {
      const loginResponse = await msalInstance.loginPopup(loginRequest);
      const data = await authService.signUpOrInWithMicrosoft(loginResponse.idToken);
      const decodedToken = jwt.jwtDecode(data.access_token) as IIncomingTokenCredentials;

      console.log(loginResponse);

      if (decodedToken) {
        const name = (loginResponse.idTokenClaims as any).name;
        const email = (decodedToken as any).email;
        const role = (decodedToken as any).is_admin ? "Administrador" : "Usuário";
        const username = email ? email.split("@")[0] : "";

        const userData = {
          name: name,
          role: role,
          username: username,
        };

        localStorage.setItem("userData", JSON.stringify(userData));

        dispatch(actionLoginWithGoogle(data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <button className="microsoft-sso-button" onClick={handleLogin}>
        <img src="microsoft-icon.png" alt="Microsoft Logo" width="20" height="20" />
        Entrar com Microsoft
      </button>
    </div>
  );
};

export { MicrosoftLogin };
