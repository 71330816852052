import { Button, Modal } from "reactstrap";
import { ModalContainer } from "./styled";
import { useTranslation } from "react-i18next";

interface ExitWithoutSaveModalProps {
  isOpen: boolean;
  toggle: () => void;
  onConfirm: () => void;
}

export function ExitWithoutSaveModal({ isOpen, toggle, onConfirm }: ExitWithoutSaveModalProps) {
  const { t } = useTranslation();
  function handleConfirm() {
    onConfirm();
    toggle();
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalContainer>
        <i>
          <img src="/modals/error.gif" alt="warning" height={'101px'} />
        </i>
        <h6 className="modal-title">
          {t("pages.projects.components.createEditPage.exitWithoutSave.title")}
        </h6>
        <p className="modal-message">
          {t("pages.projects.components.createEditPage.exitWithoutSave.message")}
        </p>
        <div className="action-container">
          <Button color="light" onClick={toggle}>
            {t("global.cancel")}
          </Button>
          <Button color="danger" onClick={handleConfirm}>
            {t("global.continue")}
          </Button>
        </div>
      </ModalContainer>
    </Modal>
  );
}
