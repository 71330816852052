import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { get } from "lodash";

//i18n
import i18next from "i18next";
import languages from "../../common/languages";
import { setLanguageToStorage, LANGUAGE_LOCAL_STORAGE } from "../../utils/setLanguageToStorage";
import { useTranslation } from "react-i18next";

type LanguageSelectorProps = {
  callback?: () => void;
};

const LanguageDropdown: React.FC<LanguageSelectorProps> = ({ callback }) => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState( (localStorage.getItem(LANGUAGE_LOCAL_STORAGE) != null) ? localStorage.getItem(LANGUAGE_LOCAL_STORAGE) : "pt");
  const { t } = useTranslation();

  const [isLanguageDropdown, setIsLanguageDropdown] = useState<boolean>(false);

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdown(!isLanguageDropdown);
  };
  const handleChangeLanguage = (key: string) => {
    setSelectedLang(key);
    setLanguageToStorage(key as "en" | "pt" | "es");
    i18next.changeLanguage(key);
    if (callback) callback();
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isLanguageDropdown}
        toggle={toggleLanguageDropdown}
        className="ms-1 topbar-head-dropdown header-item"
      >
        <DropdownToggle
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          tag="button"
        >
          <img
            src={get(languages, `${selectedLang}.flag`)}
            alt="Header Language"
            height="20"
            className="rounded"
          />
        </DropdownToggle>

        <DropdownMenu className="notify-item language py-2">
          {Object.keys(languages).map((key) => (
            <DropdownItem
              key={key}
              onClick={() => handleChangeLanguage(key)}
              className={`notify-item ${selectedLang === key ? "active" : "none"}`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Skote"
                className="me-2 rounded"
                height="18"
              />
              <span className="align-middle">{t(`pages.home.language.${key}`)}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default LanguageDropdown;
