import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
  ScrollRestoration,
} from "react-router-dom";
import { routesManager } from "./routesManager";
import {
  ChatPage,
  ProjectsPage,
  //LandingPage,
  LoginPage,
  // SourcesPage,
  ConfirmEmailPage,
  AcceptInvitationOrResetPasswordPage,
  AdminPage,
  PromptsPage,
  SuperuserPage,
  SearchPage,
  ContractAnalysisPage,
  ContractPage,
} from "../pages";
import { PrivateRoute, PublicRoute } from "../components/shared";
import HomePage from "../pages/Home";
import Layout from "../layouts";
import { NewPrompts } from "../pages/NewPrompts";
import { ProjectDetails } from "../pages/Projects/form";
import { DeleteDialogProvider } from "../context/useDeleteDialog";

const AppRoutes = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route
          path={routesManager.getLandingRoute()}
          element={
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          }
        />

        <Route path={routesManager.getConfirmEmailRoute()} element={<ConfirmEmailPage />} />

        <Route
          path={routesManager.getAcceptInvitationRoute()}
          element={<AcceptInvitationOrResetPasswordPage />}
        />

        <Route
          path={routesManager.getResetPasswordRoute()}
          element={<AcceptInvitationOrResetPasswordPage />}
        />

        <Route
          path={routesManager.getLoginRoute()}
          element={
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          }
        />

        <Route
          path="*"
          element={
            <DeleteDialogProvider>
              <Layout>
                <ScrollRestoration />
                <PrivateRoute>
                  <Routes>
                    <Route path={routesManager.getChatRoute()} element={<ChatPage />} />
                    <Route path={routesManager.getProjectsRoute()} element={<ProjectsPage />} />
                    <Route
                      path={routesManager.getProjectDetailsRoute()}
                      element={<ProjectDetails />}
                    />
                    {/* <Route path={routesManager.getSourcesRoute()} element={<SourcesPage />} /> */}
                    <Route path={routesManager.getAdminRoute()} element={<AdminPage />} />
                    <Route path={routesManager.getPromptsRoute()} element={<PromptsPage />} />
                    <Route path={routesManager.getSuperuserRoute()} element={<SuperuserPage />} />
                    <Route path={routesManager.getSearchRoute()} element={<SearchPage />} />
                    <Route path={routesManager.getContractsRoute()} element={<ContractPage />} />
                    <Route path={routesManager.getHomeRoute()} element={<HomePage />} />
                    <Route path={routesManager.getNewPromptsRoute()} element={<NewPrompts />} />
                    <Route
                      path={routesManager.getContractAnalysisRoute()}
                      element={<ContractAnalysisPage />}
                    />
                  </Routes>
                </PrivateRoute>
              </Layout>
            </DeleteDialogProvider>
          }
        ></Route>
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};

export { AppRoutes };
