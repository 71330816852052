import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

export function useNavTab() {
  const { t, i18n } = useTranslation();

  const getTranslatedNavItems = useCallback(
    () => [
      { id: 1, label: t("pages.projects.components.gptTipes.corporative") },
      { id: 2, label: t("pages.projects.components.gptTipes.personal") },
    ],
    [t],
  );

  const [navItems, setNavItems] = useState(getTranslatedNavItems());
  const [customActiveTab, setCustomActiveTab] = useState(1);

  const getNavLinkClassName = useCallback(
    (id: number): string =>
      classnames({
        active: customActiveTab === id,
      }),
    [customActiveTab],
  );

  const handleScrollTab = (tab: number) => {
    setCustomActiveTab(tab);
    const targetContainer = document.getElementById(`gpt-container-${tab}`);
    const pageHeader = document.getElementById("project-header-container");

    if (targetContainer && pageHeader) {
      const PADDING = 50;
      const headerHeight = pageHeader.clientHeight;
      const offset =
        window.scrollY + targetContainer.getBoundingClientRect().top - headerHeight - PADDING;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const pageHeader = document.getElementById("project-header-container");
      const headerHeight = pageHeader ? pageHeader.clientHeight : 0;

      let newActiveTab = customActiveTab;

      navItems.forEach((item) => {
        const section = document.getElementById(`gpt-container-${item.id}`);
        if (section) {
          const PADDING = 60;
          const sectionTop = section.getBoundingClientRect().top - headerHeight - PADDING;

          if (sectionTop <= 0) {
            newActiveTab = item.id;
          }
        }
      });

      if (newActiveTab !== customActiveTab) {
        setCustomActiveTab(newActiveTab);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [customActiveTab, navItems]);

  useEffect(() => {
    const handleLanguageChange = () => {
      setNavItems(getTranslatedNavItems());
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n, getTranslatedNavItems]);

  return { navItems, setNavItems, handleScrollTab, getNavLinkClassName };
}
