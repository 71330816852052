import { Button, Spinner } from "reactstrap";
import { EmptyStateGPTCardContainer } from "./styled";
import classnames from "classnames";
import { RiAddBoxLine } from "@remixicon/react";
import { useTranslation } from "react-i18next";

export function EmptyStateGPTCard({
  navigateToCreateGPT,
  loading,
}: {
  navigateToCreateGPT: () => void;
  loading: boolean;
}) {
  const { t } = useTranslation();
  return (
    <EmptyStateGPTCardContainer>
      <div className="empty-content">
        <h6 className="empty-state-title text-primary">
          {t("pages.projects.components.emptyState.title")}
        </h6>
        <p className="empty-state-description">
          {t("pages.projects.components.emptyState.message")}{" "}
          <span>{t("pages.projects.components.emptyState.call")}</span>
        </p>
        <Button
          color="primary"
          type="button"
          onClick={() => navigateToCreateGPT()}
          disabled={loading}
          className={classnames({ "btn-load": loading }, "bnt-label")}
        >
          {loading ? (
            <Spinner
              size="sm"
              className="button-icon flex-shrink-0 align-middle fs-16 me-2"
            ></Spinner>
          ) : (
            <RiAddBoxLine size="1rem" className="button-icon label-icon align-middle fs-16 me-2" />
          )}
          {t("pages.projects.components.emptyState.createBtn")}
        </Button>
      </div>
      <i className="empty-icon">
        <img src="emptyStarPrimaryColor.svg" alt="star icon" />
      </i>
    </EmptyStateGPTCardContainer>
  );
}
