import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface IChatList {
  project_id: number;
  title: string;
  id: number;
  created_at: string;
}

export interface IChatParams{
  selectedChatId?: number;
  emptyChat: boolean;
}

interface ChatState {
  chatParams?: IChatParams;
  inputText?: string;
  conversations: IChatList[];
}

const initialState: ChatState = {
  chatParams: {
    selectedChatId: undefined,
    emptyChat: false,
  },
  inputText: "",
  conversations: [],
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    actionSetInputText(state, action: PayloadAction<string>){
      state.inputText = action.payload;
    },
    actionSelectChat(state, action: PayloadAction<IChatParams>) {
      state.chatParams = action.payload;
    },
    actionDeselectChat(state) {
      if (state.chatParams) {
        state.chatParams.selectedChatId = undefined;
        state.chatParams.emptyChat = false;
      }
    },
    actionSetConversations(state, action: PayloadAction<IChatList[]>) {
      state.conversations = action.payload;
    },
  }
});

export const { actionDeselectChat, actionSelectChat, actionSetConversations, actionSetInputText } = chatSlice.actions;
export const selectorInputText = (state: RootState) => state.chat.inputText;
export const selectorChatId = (state: RootState) => state.chat.chatParams;
export const selectorConversation = (state: RootState) => state.chat.conversations;

export default chatSlice.reducer;
