import styled from "styled-components";
import { ContentCopy, ExpandMore, LockOutlined } from "@mui/icons-material";

export const PromptContainer = styled.div<{ isSelected: boolean }>`
  width: 100%;
  height: auto;
  padding: 0;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid ${(props) => (props.isSelected ? "#00A793" : "#ddd")};
  border-radius: 4px;
  overflow: hidden;

  .div-prompt-text {
    padding: 20px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TitleBox = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  padding: 16px;
  background-color: ${(props) => (props.isSelected ? "#eff2f7" : "#fff")};
  border-bottom: none;
  border-radius: 4px 4px 0 0;

  @media (max-width: 768px) {
    height: auto;
    align-items: flex-start;
    padding: 10px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TitleIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  .section-title {
    height: 2px;
    font-size: 13px;
    font-weight: 600;
    color: #363d48;
    padding-bottom: 7px;
    padding-left: 12px;
    font-family: "Montserrat", sans-serif;
  }

  @media (max-width: 768px) {
    gap: 1px;
  }
`;

export const TitleText = styled.h2`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 20px;
  color: #363d48;
  cursor: pointer;
  padding-left: 11px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const TitleEmailDiv = styled.div`
  padding: 12px;
  margin-top: 2px;
  @media (max-width: 768px) {
    padding: 6px;
  }
`;

export const EmailText = styled.span`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 11.55px;
  line-height: 13.98px;
  color: #878a99;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  padding-bottom: 8px;
`;

export const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 3px;
  }
`;

export const CopyIcon = styled(ContentCopy)`
  color: #555;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const ToggleIcon = styled(ExpandMore)<{ isVisible: boolean }>`
  transform: ${(props) => (props.isVisible ? "rotate(0deg)" : "rotate(180deg)")};
  color: #555;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const PromptText = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13.2px;
  line-height: 20px;
  padding: 16px;
  color: #555;
  margin: 0;
  padding-left: 11px;
  background-color: #fff;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 12px;
  }
`;

export const FooterText = styled.span`
  color: #999;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const PrivateIcon = styled(LockOutlined)`
  color: #f00;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const PrivateText = styled.span`
  color: #f00;
  margin-left: 4px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const ModalEdit = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 53%;
  width: 755px;
  height: 543px;
  background: #fff;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 16px;
    width: 327px;
    height: 496px;
  }
`;

export const ModalHeaderEditPrompt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  background-color: #ccede9;
  padding: 0 20px;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    height: auto;
    padding: 10px 20px;
  }
`;

export const ModalHeaderTitle = styled.h4`
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  color: #495057;
  padding-top: 18px;
  font-weight: bold;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding-top: 13px;
  }
`;

export const ModalHeaderButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #495057;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const ModalSubtitle = styled.div`
  height: 20px;
  width: 702px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #212529;
  margin-left: 23px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    font-size: 12px;
  }
`;

export const ModalInput = styled.input<{ error: boolean }>`
  width: 702px;
  padding: 9px;
  margin-bottom: 20px;
  border: 1px solid ${(props) => (props.error ? "red" : "#ccc")};
  border-radius: 4px;
  margin-left: 23px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const ModalLabel = styled.p`
  font-size: 12px;
  color: #878a99;
  margin-left: 23px;

  @media (max-width: 768px) {
    margin-left: 0;
    font-size: 11px;
  }
`;

export const ModalTextArea = styled.textarea<{ error: boolean }>`
  width: 702px;
  height: 178px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid ${(props) => (props.error ? "red" : "#ccc")};
  border-radius: 4px;
  margin-left: 23px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    height: 89px;
  }
`;

export const ModalButton = styled.button`
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
`;
