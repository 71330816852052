import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { Formik } from "formik";
import * as yup from "yup";
import {
  ModalHeaderEditPrompt,
  ModalHeaderTitle,
  ModalHeaderButton,
  ModalSubtitle,
  ModalInput,
  ModalLabel,
  ModalTextArea,
  ModalButton,
  ModalSubtitleTextArea,
} from "./styled";
import { IPrompt, promptsService } from "../../../services/prompts";
import { useAppDispatch } from "../../../redux/hooks";
import { actionDisplayNotification } from "../../../redux/notificationSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { RiCheckboxCircleLine, RiAlertLine } from "@remixicon/react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";

interface ToggleCustomSuccessModalPromptEditProps {
  ModalToggle: boolean;
  setModalToggle: (value: boolean) => void;
  prompt: IPrompt;
  setEditedPrompt: React.Dispatch<React.SetStateAction<IPrompt>>;
  updatePrompt: (updatedPrompt: IPrompt) => void;
}

const EditModalPrompt: React.FC<ToggleCustomSuccessModalPromptEditProps> = ({
  ModalToggle,
  setModalToggle,
  prompt,
  setEditedPrompt,
  updatePrompt,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleClose = () => {
    setModalToggle(false);
  };

  interface IFormData {
    title: string;
    prompt: string;
    is_public: boolean;
  }

  const FormValidationSchema: yup.Schema<IFormData> = yup.object().shape({
    title: yup.string().required(),
    prompt: yup.string().required(),
    is_public: yup.boolean().required(),
  });

  return (
    <Modal size="lg" isOpen={ModalToggle} toggle={handleClose} centered>
      <ModalHeaderEditPrompt>
        <ModalHeaderTitle>{t("pages.prompts.components.editNewPromptModal")}</ModalHeaderTitle>
        <ModalHeaderButton onClick={handleClose}>×</ModalHeaderButton>
      </ModalHeaderEditPrompt>
      <ModalBody>
        <Formik
          enableReinitialize
          initialValues={{
            title: prompt.title,
            prompt: prompt.prompt,
            is_public: prompt.is_public,
          }}
          validate={async (values) => {
            const errors: Record<string, string> = {};
            try {
              await FormValidationSchema.validate(values, {
                abortEarly: false,
              });
            } catch (err) {
              (err as yup.ValidationError).inner.forEach((item) => {
                if (!item.path) return;
                errors[item.path] = item.message;
              });
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const updatedPrompt = await promptsService.edit({
                ...prompt,
                ...values,
              });
              setEditedPrompt(updatedPrompt);
              updatePrompt(updatedPrompt);
              setModalToggle(false);
              toast(`${t("Toast.editPrompt")}`, {
                toastId: "success1",
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                autoClose: 5000,
                className: "bg-success text-white",
                closeButton: true,
                icon: <RiCheckboxCircleLine />,
              });
            } catch (err) {
              dispatch(
                actionDisplayNotification({
                  messages: ["Unable to update prompt"],
                }),
              );
              toast(`${t("Toast.errorEditPrompt")}`, {
                toastId: "error1",
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                autoClose: 5000,
                className: "bg-danger text-white",
                closeButton: true,
                icon: <RiAlertLine />,
              });
            }
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <ModalSubtitle>{t("pages.prompts.components.promptTitle")}</ModalSubtitle>
              <ModalInput
                type="text"
                name="title"
                placeholder={t("pages.prompts.components.enterPrompt")}
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.title && touched.title)}
              />
              {errors.title && touched.title && <div className="error">{errors.title}</div>}
              <ModalLabel>{t("pages.prompts.components.promptDefaultTitle")}</ModalLabel>
              <ModalSubtitleTextArea>{t("pages.prompts.components.enterPrompt")}</ModalSubtitleTextArea>
              <ModalTextArea
                name="prompt"
                placeholder={t("pages.prompts.components.enterPrompt")}
                value={values.prompt}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={2000}
                error={Boolean(errors.prompt && touched.prompt)}
              />
              <ModalLabel>
              {`    ${t("pages.prompts.components.mustContaintCaracters")}`}
              </ModalLabel>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="is_public"
                  checked={values.is_public}
                  onChange={handleChange}
                />
                <Typography.Text className="ms-2">{`    ${t("pages.prompts.components.makePublicPrompt")}`}</Typography.Text>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <ModalButton
                  type="button"
                  onClick={handleClose}
                  style={{ backgroundColor: "#F3F6F9", color: "#212529" }}
                >
                  {t("global.cancel")}
                </ModalButton>
                <ModalButton
                  type="submit"
                  disabled={isSubmitting}
                  style={{ marginLeft: "10px", backgroundColor: "#00A793", color: "#F3F6F9" }}
                >
                  {t("pages.prompts.components.editNewPromptModal")}
                </ModalButton>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export { EditModalPrompt };
