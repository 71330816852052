import { RiDeleteBinLine, RiDownload2Line } from "@remixicon/react";
import { FileThumbCard } from "./styled";
import { Button } from "reactstrap";
import { ISource, sourcesService } from "../../../../services";
import { useState } from "react";
import useFileIcon from "./useFileIcon";
import { useDeleteDialog } from "../../../../context/useDeleteDialog";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { actionDisplayNotification } from "../../../../redux/notificationSlice";

interface FileThumbsProps {
  file: ISource;
  accessToDelete: boolean;
  isCreating: boolean;
  setSources: React.Dispatch<React.SetStateAction<ISource[]>>;
  setDirt: React.Dispatch<React.SetStateAction<boolean>>;
}

export function FileThumbs({
  file,
  accessToDelete,
  isCreating,
  setSources,
  setDirt,
}: FileThumbsProps) {
  const FileIcon = useFileIcon(file.media_type);
  const { onDeleteDialog } = useDeleteDialog();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  function formatName(name: string) {
    const splitName = name.split(".");
    splitName.length > 0 && splitName.pop();
    return splitName.join(" ").trim();
  }

  function formatType(type: string) {
    const mimeMap: { [key: string]: string } = {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
      "application/pdf": "pdf",
      "text/plain": "txt",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
      "application/vnd.ms-powerpoint": "ppt",
      "text/csv": "csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
      // Adicione mais tipos MIME conforme necessário
    };

    return mimeMap[type] || "unknown";
  }

  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  async function handleDelete() {
    if (!isCreating && file.project_id && file.file_path) {
      try {
        setLoading(true);
        await sourcesService.deleteSource({
          projectId: file.project_id,
          sourcePath: file.file_path,
        });
      } catch (error) {
        dispatch(
          actionDisplayNotification({
            messages: [t("global.failureDeleteMessage")],
            severity: "error",
          }),
        );
      } finally {
        setLoading(false);
        setDirt(true);
      }
    }
    setSources((prev) => prev.filter((source) => source.id !== file.id));
    return;
  }

  async function downloadFile() {
    try {
      await sourcesService
        .getUploadUrl({
          projectId: file.project_id,
          sourceId: Number(file.id),
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = file.file_name;
          a.click();
        });
    } catch (er) {
      dispatch(
        actionDisplayNotification({
          messages: [t("global.failureDownloadMessage")],
          severity: "error",
        }),
      );
    }
  }

  return (
    <FileThumbCard loading={loading}>
      <div className="file-icon-container">
        <FileIcon className="drop-icon" />
      </div>
      <div className="file-info-container">
        <h6>{formatName(file.file_name)}</h6>
        <p>
          <strong>.{formatType(file.media_type)}</strong> {formatBytes(file.size_in_bytes)}
        </p>
      </div>
      <div className="action-btn-container">
        {file.file_path ? (
          <Button
            className="btn btn-icon"
            color="light"
            size="sm"
            type="button"
            style={{ backgroundColor: "white" }}
            onClick={downloadFile}
          >
            <RiDownload2Line style={{ width: "18px", height: "18px" }} />
          </Button>
        ) : null}
        <Button
          className="btn btn-icon"
          color="light"
          size="sm"
          type="button"
          style={{ backgroundColor: "white" }}
          onClick={() =>
            onDeleteDialog({
              callback: handleDelete,
              message: t(
                "pages.projects.components.createEditPage.dropzone.deleteConfirmationMessage",
              ),
            })
          }
          disabled={!accessToDelete}
        >
          <RiDeleteBinLine style={{ width: "18px", height: "18px" }} />
        </Button>
      </div>
    </FileThumbCard>
  );
}
