import { MessageBubble } from "./MessageBubble";

interface IUserMessageBubbleProps {
  markdownText: string;
  references?: string[];
  date_time?: string;
}

const BotMessageBubble = ({ markdownText, references, date_time }: IUserMessageBubbleProps) => {
  return <MessageBubble float="left" markdownText={markdownText} references={references} date_time={date_time} showResponseTime={false}/>;
};

export { BotMessageBubble };