import styled from "styled-components";

export const DropzoneContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  .dropzone {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;

    .drop-icon {
      color: var(--vz-gray-500);
    }

    > p {
      font-size: 13.2px;
      text-align: center;
      line-height: 20px;
      font-weight: 400;
      color: var(--vz-gray-900);
    }
  }
`;

export const ThumbsContainer = styled.aside`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
`;
