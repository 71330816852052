/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate } from "react-router-dom";
import "./index.css";
import { routesManager } from "../../../routes/routesManager";
import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink } from "reactstrap";
import { useTranslation } from "react-i18next";
import { IPrompt, promptsService } from "../../../services/prompts";
import { EmptyPromptItem } from "../../Prompts/EmptyPromptItem";
import { Typography } from "antd";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface EmptyCardPrompProps {
  isLoading?: boolean;
  collection?: boolean;
  newPrompt?: boolean;
  title: string;
  text: string;
}

const EmptyCardPrompt: React.FC<EmptyCardPrompProps> = ({
  isLoading = false,
  collection = false,
  newPrompt = false,
  title,
  text,
}) => {
  const sendEvent = () => {
    if (collection == false && newPrompt == false) {
      const chatInput = document.querySelector("#chat-input") as HTMLTextAreaElement | null;
      if (chatInput) {
        chatInput.value = text;
        chatInput?.focus();
        chatInput?.addEventListener("input", () => {
          chatInput.style.height = `${chatInput.scrollHeight}px`;
        });
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="card-container-prompt">
          <Skeleton height={50} width={50} />
          <Skeleton height={20} width={110} />
        </div>
      ) : (
        <div className="card-container-prompt" onClick={sendEvent}>
          <p className="card-text">{title}</p>
        </div>
      )}
    </>
  );
};

export default EmptyCardPrompt;
