import { Button } from "antd";
import styled from "styled-components";

export const TypeContent = styled.div`
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TypeTextArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  textarea {
    display: flex;
    width: 100%;
    align-items: center;
    border: none;
    resize: none;
    color: #3e4352;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    padding: 15px 20px;
    line-height: 1;
    box-sizing: border-box;

    & {
      scrollbar-width: thin;
      scrollbar-color: #545454 #ffffff;
      background-color: #f3f6f9;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #808080;
      border-radius: 10px;
      border: 3px solid #ffffff;
    }

    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.1);
      outline: none;
    }

    &::placeholder {
      color: #3e435270;
    }
  }

  .add-button {
  position: absolute;
  left: 11px;
  top: 47%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2;
}
`;

export const SendButton = styled(Button)`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00a793;
  border: none;
  border-radius: 4px;
  padding: 25px !important;
  margin-left: 4px;

  svg {
    font-size: 16px;
    color: #ffffff;
  }

  &:hover,
  &:focus {
    background-color: #2fc19a !important;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: center;
  margin-left: 8px;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: #00a793;
  cursor: pointer;
`;

export const BadgeSelectorContainer = styled.div`
  position: relative;
  bottom: 0;
  left: 7px;
  width: 90%;
`;
