/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import Cookies from "js-cookie";
import { actionLogout } from "../../redux/authenticationSlice";
import { useAppDispatch } from "../../redux/hooks";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { RiDoorOpenLine, RiTodoLine, RiBookOpenLine, RiInformationLine } from "@remixicon/react";

//import images
//import avatar1 from "../../assets/images/users/avatar-1.jpg";

interface IUserData {
  name: string;
  role: string;
  picture: string;
}

const ProfileDropdown = () => {
  const [userData, setUserData] = useState<IUserData | null>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const trackGTMEvent = (eventName: string, eventData: Record<string, unknown>) => {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...eventData,
        timestamp: new Date().toISOString(),
      },
    });
  };

  const handleLogout = () => {
    // Lógica de logout
    dispatch(actionLogout());

    // Envio do evento para o GTM
    trackGTMEvent("logout", {
      action: "User logged out",
    });
  };

  useEffect(() => {
    const sessionData = localStorage.getItem("userData");
    if (sessionData) {
      try {
        const dados: IUserData = JSON.parse(sessionData);
        setUserData(dados);
      } catch (e) {
        console.error("Erro ao converter os dados:", e);
      }
    }
  }, []);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src="user-avatar.svg"
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {userData?.name}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {userData?.role == "Administrador" ? t("pages.home.admin") : t("pages.home.user")}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end" style={{ width: "170px" }}>
          {/* <DropdownItem className="p-0">
            <Link to="" className="dropdown-item">
              <RiInformationLine size={14} /> <span className="align-middle">{t("global.helpCenter")}</span>
            </Link>
          </DropdownItem> */}
          <DropdownItem className="p-0">
            <Link to="/newPrompts" className="dropdown-item">
              <RiTodoLine size={14} />
              <span className="align-middle ms-2">Prompts</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link
              to="userManual/user-manual.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="dropdown-item"
            >
              <RiBookOpenLine size={14} />
              <span className="align-middle ms-2">{t("global.userManual")}</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to="" className="dropdown-item" onClick={handleLogout}>
              <RiDoorOpenLine size={14} />
              <span className="align-middle ms-2" data-key="t-logout">
                {t("global.logout")}
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
