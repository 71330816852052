import styled from "styled-components";

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 53%;
  width: 755px;
  height: 543px;
  background: #fff;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px){
    padding: 16px;
    width: 327px;
    height: 496px;
  }
`;

export const ModalHeaderEditPrompt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  background-color: #ccede9;
  padding: 0 20px;

  @media (max-width: 768px) {
    height: auto;
    padding: 10px 20px;
  }
`;

export const ModalHeaderTitle = styled.h4`
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  color: #495057;
  padding-top: 18px;
  font-weight: bold;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding-top: 13px;
  }
`;

export const ModalHeaderButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #495057;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const ModalSubtitle = styled.div`
  height: 20px;
  width: auto;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #212529;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    font-size: 12px;
  }
`;

export const ModalSubtitleTextArea = styled.div`
  height: 20px;
  width: auto;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #212529;
  margin-bottom: 9px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    font-size: 12px;
  }
`;

export const ModalInput = styled.input<{ error: boolean }>`
  width: 100%;
  padding: 9px;
  margin-bottom: 4px;
  border: 1px solid ${(props) => (props.error ? "red" : "#ccc")};
  border-radius: 4px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const ModalLabel = styled.p`
  font-size: 12px;
  color: #878a99;

  @media (max-width: 768px) {
    margin-left: 0;
    font-size: 11px;
  }
`;

export const ModalTextArea = styled.textarea<{ error: boolean }>`
  width: 100%;
  height: 178px;
  padding: 9px;
  border: 1px solid ${(props) => (props.error ? "red" : "#ccc")};
  border-radius: 4px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    height: 89px;
  }
`;

export const ModalButton = styled.button`
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
`;