/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SendOutlined, PlusOutlined, SoundOutlined, MinusOutlined } from "@ant-design/icons";
import {
  TypeContent,
  TypeTextArea,
  SendButton,
  IconContainer,
  BadgeSelectorContainer,
  ButtonGroup,
} from "./styled";
import { RiAddLine, RiMicLine, RiSendPlane2Fill, RiStopMiniFill } from "@remixicon/react";
import BadgeSelector from "../BadgeSelector";
import { IPrompt, promptsService } from "../../../services/prompts";
import { useAppSelector } from "../../../redux/hooks";
import { selectorInputText } from "../../../redux/chatSlice";
import AudioRecorder from "../AudioRecorder";
import { Button } from "reactstrap";
import AudioRecorder2, { AudioRecorderHandles } from "../AudioRecorderV2";

type TextAreaComponentProps = {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<Element>) => void;
  handleSendMessage: () => Promise<void>;
  setSpeechResponse: (value: string) => void;
  value: string;
  messageCount: number;
  isEmptyState: boolean;
  isBlockedMessage: boolean;
};

const TextAreaComponent: React.FC<TextAreaComponentProps> = ({
  onChange,
  onKeyDown,
  handleSendMessage,
  value,
  messageCount,
  isBlockedMessage,
  setSpeechResponse,
}) => {
  const { t } = useTranslation();
  const [showBadges, setShowBadges] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState<number | null>(null);
  const [topPrompt, setTopPrompt] = useState<IPrompt[]>([]);
  const [likedPrompt, setLikedPrompt] = useState<IPrompt[]>([]);
  const [ownPrompt, setOwnPrompt] = useState<IPrompt[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [canSendMessage, setCanSendMessage] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [recording, setRecording] = useState(false);
  const audioRecorderRef = useRef<AudioRecorderHandles>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const chatInput = document.querySelector("#chat-input") as HTMLTextAreaElement | null;

    const adjustHeight = () => {
      if (chatInput) {
        chatInput.style.height = "auto";
        chatInput.style.height = `${chatInput.scrollHeight}px`;
      }
    };

    chatInput?.addEventListener("input", adjustHeight);
    return () => {
      chatInput?.removeEventListener("input", adjustHeight);
    };
  }, []);

  const handleTrigger = () => {
    setRecording(false);
    setIsDisabled(true);
    if (audioRecorderRef.current) {
      audioRecorderRef.current.triggerAction(); // Chama o método no filho
    }
  };

  useEffect(() => {
    const chatInput = document.querySelector("#chat-input") as HTMLTextAreaElement | null;

    if (value === "") {
      if (chatInput) {
        chatInput.style.height = "50px";
      }
    }
  }, [value]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const [own, liked, top] = await Promise.all([
          promptsService.getFavorited(),
          promptsService.getLiked_with_Favorited(),
          promptsService.getTop(),
        ]);

        setOwnPrompt(own.slice(0, 8));
        setLikedPrompt(liked.slice(0, 8));
        setTopPrompt(top.slice(0, 8));
        setIsLoading(false);
      } catch (e) {
        console.error("Erro ao carregar os prompts:", e);
      }
    })();
  }, []);

  useEffect(() => {
    if (messageCount === 0) {
      setShowBadges(false);
    }
  }, [messageCount]);

  const scrollToTextArea = () => {
    const textarea = document.getElementById("chat-input");
    if (textarea) {
      textarea.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  };

  useEffect(() => {
    setCanSendMessage(value !== "");
  }, [value]);

  const handleEvent = () => {
    if (canSendMessage) {
      handleSendMessage();
    } else {
      setShowAudio(true);
      setRecording(true);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {showBadges && (
        <BadgeSelectorContainer>
          <BadgeSelector
            onBadgeSelect={setSelectedBadge}
            selectedBadge={selectedBadge}
            topPrompt={topPrompt}
            likedPrompt={likedPrompt}
            ownPrompt={ownPrompt}
            isLoading={isLoading}
          />
        </BadgeSelectorContainer>
      )}
      <TypeContent>
        <TypeTextArea className="textarea">
          <Button
            style={{ color: "#00A793" }}
            className="add-button"
            onClick={() => {
              scrollToTextArea();
              setSelectedBadge(0);
              setShowBadges((prev) => !prev);
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#d3d3d3")}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "")}
          >
            <RiAddLine size={20} />
          </Button>
          <textarea
            className="textarea"
            id="chat-input"
            onKeyDown={onKeyDown}
            rows={1}
            onChange={onChange}
            disabled={isBlockedMessage}
            value={value}
            autoFocus={true}
            placeholder={`${t("pages.chat.components.inputPlaceHolder")}`}
            style={{
              maxWidth: "806px",
              maxHeight: "160px",
              paddingLeft: "60px",
              lineHeight: "130%",
            }}
          />
          {showAudio && (
            <AudioRecorder2
              ref={audioRecorderRef}
              onCancel={() => {
                setShowAudio(false);
              }}
              setSpeechResponse={setSpeechResponse}
            />
          )}
        </TypeTextArea>

        {canSendMessage ? (
          <Button
            style={{ height: "50px", width: "50px" }}
            color="primary"
            className="btn-icon"
            onClick={handleEvent}
          >
            <RiSendPlane2Fill size={20} />
          </Button>
        ) : recording ? (
          <Button
            style={{ height: "50px", width: "50px" }}
            color="primary"
            className="btn-icon btn-soft-danger"
            onClick={handleTrigger}
          >
            <RiStopMiniFill size={20} />
          </Button>
        ) : (
          <Button
            style={{ height: "50px", width: "50px" }}
            color="primary"
            className="btn-icon"
            onClick={handleEvent}
          >
            <RiMicLine size={20} />
          </Button>
        )}
      </TypeContent>
      {/* {showAudio && (
        <AudioRecorder
          onCancel={() => {
            setShowAudio(false);
          }}
          setSpeechResponse={setSpeechResponse}
        />
      )} */}
    </div>
  );
};

export { TextAreaComponent };
