/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import "./index.css";
import { SubmissionSpinner2 } from "./Spinner";
import { FixedHeightSpinner, TimerFont } from "./styled";
import { useCountdownTimer2 } from "./timer";
import { useReactMediaRecorder } from "react-media-recorder";
import { useCountdownTimer } from "../AudioRecorder/timer";
import { RecordingSpinner2 } from "./RecorderFrame";
import { chatService } from "../../../services";
import { RotatingLines } from "react-loader-spinner";

interface IAudioRecorderProps {
  onCancel: VoidFunction;
  setSpeechResponse: (value: string) => void;
}

export interface AudioRecorderHandles {
  triggerAction: () => void;
}

const AudioRecorder2 = forwardRef<AudioRecorderHandles, IAudioRecorderProps>(
  ({ onCancel, setSpeechResponse }, ref) => {
    const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
    const [shouldRequest, setShouldRequest] = useState(false);
    const [isRecording, setIsRecording] = useState(false);

    const { startRecording, stopRecording, clearBlobUrl } = useReactMediaRecorder({
      audio: true,
      stopStreamsOnStop: true,
      onStart() {
        startTimer();
        setIsRecording(true);
      },
      onStop(blobUrl: string, blob: Blob) {
        setAudioBlob(blob);
        setIsRecording(false);
      },
    });

    const stopRecordingAndRequest = () => {
      setShouldRequest(true);
      stopRecording();
    };

    const { timeLeft, startTimer } = useCountdownTimer({
      seconds: 30,
      onCountdownExpired: stopRecordingAndRequest,
    });

    useEffect(() => {
      clearBlobUrl();
      startRecording();

      return () => {
        stopRecording();
      };
    }, []);

    const handleAudioSend = async (blob: Blob) => {
      const result = await chatService.sendAudioForTranscription({ audio: blob });
      if ("transcription" in result && typeof result.transcription === "string") {
        setSpeechResponse(result.transcription);
      }
      onCancel();
    };

    const handleCancelAudio = () => {
      stopRecording();
      onCancel();
    };

    useEffect(() => {
      if (audioBlob && shouldRequest) {
        handleAudioSend(audioBlob);
      }
    }, [audioBlob]);

    // Expor método para o componente pai
    useImperativeHandle(ref, () => ({
      triggerAction() {
        stopRecordingAndRequest();
      },
    }));

    return (
      <div className="audio-area">
        <TimerFont>{`00:${String(timeLeft).padStart(2, "0")}`}</TimerFont>
        {isRecording ? (
          <RecordingSpinner2 />
        ) : (
          <RotatingLines
            width="27"
            strokeColor="#46226A"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
          />
        )}
      </div>
    );
  },
);

export default AudioRecorder2;
