import { RiUploadCloud2Fill } from "@remixicon/react";
import { useDropzone } from "react-dropzone";
import { DropzoneContainer, ThumbsContainer } from "./styled";
import { Spinner } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { FileThumbs } from "./fileThumbs";
import { ISource } from "../../../services";
import { useTranslation } from "react-i18next";

interface DropzoneFileProps {
  loading: boolean;
  setSources: React.Dispatch<React.SetStateAction<ISource[]>>;
  setFilesToUpload: React.Dispatch<React.SetStateAction<File[]>>;
  setDirt: React.Dispatch<React.SetStateAction<boolean>>;
  accessToDelete: boolean;
  accessToUpload: boolean;
  sources: ISource[];
}

const HUNDRED_MB = 104_857_400;

export function DropzoneFile({
  loading,
  setSources,
  accessToDelete,
  accessToUpload,
  setDirt,
  sources,
  setFilesToUpload,
}: DropzoneFileProps) {
  const id = sessionStorage.getItem("project-id-form");
  const { t } = useTranslation();

  const isCreating = !parseInt(id as string);

  const { getRootProps, getInputProps } = useDropzone({
    disabled: !accessToUpload,
    multiple: true,
    maxSize: HUNDRED_MB,
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "text/plain": [".txt"],
      "text/csv": [".csv"],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    },
    onDrop: (acceptedFiles) => {
      setDirt(true);
      setFilesToUpload((prevFiles) => [...prevFiles, ...acceptedFiles]);
      setSources((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) => ({
          id: uuidv4(),
          project_id: parseInt(id as string),
          file_name: file.name,
          media_type: file.type,
          size_in_bytes: file.size,
          file_path: "",
        })),
      ]);
    },
  });

  return (
    <DropzoneContainer>
      <div {...getRootProps({ className: "dropzone" })}>
        {loading ? (
          <Spinner
            size="sm"
            className="button-icon flex-shrink-0 align-middle fs-16 me-2"
          ></Spinner>
        ) : (
          <>
            <input {...getInputProps()} disabled={!accessToUpload} />
            <RiUploadCloud2Fill size={53.2} className="drop-icon" />
            {accessToUpload ? (
              <p>{t("pages.projects.components.createEditPage.dropzone.permitted")}</p>
            ) : (
              <p className="text-warning">
                {t("pages.projects.components.createEditPage.dropzone.notPermitted")}
              </p>
            )}
          </>
        )}
      </div>
      <div className="form-text">
        {t("pages.projects.components.createEditPage.dropzone.inputHelper")}
      </div>
      <ThumbsContainer>
        {sources?.map((file) => (
          <FileThumbs
            key={file.id}
            isCreating={isCreating}
            file={file}
            accessToDelete={!file.file_path ? true : accessToDelete}
            setSources={setSources}
            setDirt={setDirt}
          />
        ))}
      </ThumbsContainer>
    </DropzoneContainer>
  );
}
