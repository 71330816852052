const ACCESS_TOKEN_KEY = "jwt";

export const getAccessTokenFromStorage = () => {
  let token = undefined;
  token = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (!token) {
    token = sessionStorage.getItem(ACCESS_TOKEN_KEY);
  }
  return token;
};

export const isTokenExpired = (): boolean => {
  const token = getAccessTokenFromStorage();
  if (token) {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const expiry = payload.exp;
    const now = Math.floor(Date.now() / 1000);
    return now >= expiry;
  }
  return true;
};
