import { Button, Modal } from "reactstrap";
import { ModalContainer } from "./styled";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routesManager } from "../../../routes/routesManager";

interface SuccessCreateGPTModalProps {
  isOpen: boolean;
  toggle: () => void;
}

export function SuccessCreateGPTModal({ isOpen, toggle }: SuccessCreateGPTModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleRedirect(route: string) {
    navigate(route);
    toggle();
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalContainer>
        <i>
          <img src="/modals/confetti.gif" alt="success" height={'96px'}/>
        </i>
        <h6 className="modal-title">{t("pages.projects.components.successCreateProjectTitle")}</h6>
        <p className="modal-message">
          {t("pages.projects.components.successCreateProjectMessage")}
        </p>
        <div className="action-container">
          <Button color="light" onClick={() => handleRedirect(routesManager.getProjectsRoute())}>
            {t("pages.projects.components.exploreProjectsBtn")}
          </Button>
          <Button color="primary" onClick={() => handleRedirect(routesManager.getHomeRoute())}>
            {t("pages.projects.components.initChatBtn")}
          </Button>
        </div>
      </ModalContainer>
    </Modal>
  );
}
