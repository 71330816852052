/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { routesManager } from "../../routes/routesManager";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { LoadingPage } from "../../pages";
import {
  actionLogout,
  actionUpdateAuthenticationStatus,
  selectIsAuthenticated,
} from "../../redux/authenticationSlice";
import { isTokenExpired } from "../../utils/getTokenFromStorage";

interface IPrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute = ({ children }: IPrivateRouteProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = () => {
      if (isTokenExpired()) {
        dispatch(actionLogout());
      }
    };

    checkToken();
    const intervalId = setInterval(checkToken, 60000); // Verifica a cada 60 segundos

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente é desmontado
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated === undefined) {
      dispatch(actionUpdateAuthenticationStatus());
    }
  }, []);

  if (isAuthenticated === undefined) {
    return <LoadingPage />;
  } else if (isAuthenticated) {
    return children;
  } else {
    const url = location.pathname + location.search + location.hash;
    return <Navigate to={routesManager.getLoginRoute()} state={{ next: url }}></Navigate>;
  }
};

export { PrivateRoute };
