import styled from "styled-components";

export const GrantedUsersContainer = styled.div`
  > .input-group {
    margin-bottom: 1rem;
    > .input-group-text {
      background-color: #fff;
      border-right: none;
    }
    > .input-search {
      border-left: none;
      padding-left: 0;
    }
  }

  > .pagination-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;
    margin-top: 1rem;

    .pagination-info {
      font-weight: 400;
      font-size: 13.2px;
      line-height: 20px;
      color: var(--vz-gray-600);
      order: 2;
    }

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .pagination-info {
        order: 0;
      }
    }
  }
`;
