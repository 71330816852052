import { useState, useEffect } from "react";

interface ICountdownPropops {
  seconds: number;
  onCountdownExpired: VoidFunction;
}

export function useCountdownTimer({ seconds, onCountdownExpired }: ICountdownPropops) {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  const startTimer = () => {
    setTimeLeft(seconds);
  };

  useEffect(() => {
    if (timeLeft === null) {
      return;
    }

    if (timeLeft === 0) {
      onCountdownExpired();
      return;
    }

    // Update timer every second
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return {
    timeLeft,
    startTimer,
  };
}
