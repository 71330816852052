import styled from "styled-components";

export const ModalHeaderEmptyState = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  background-color: #ccede9;
  padding: 0 20px;
 padding-bottom: 12px;

  @media (max-width: 768px) {
    height: 44px;
    padding: 10px 20px;
  }
`;

export const ModalHeaderEmptyTitle = styled.h4`
  display: flex;
  padding-bottom: 17px;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  color: #495057;
  padding-top: 18px;
  font-weight: bold;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding-top: 13px;
  }
`;

export const ModalHeaderEmptyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #495057;
  font-size: 19px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
