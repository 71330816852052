/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { IPrompt, promptsService } from "../../services/prompts";
import { useTranslation } from "react-i18next";
import { LANGUAGE_LOCAL_STORAGE } from "../../utils/setLanguageToStorage";
import {
  PromptContainer,
  TitleBox,
  ButtonGroup,
  ActionButton,
  ToggleIcon,
  TitleIconWrapper,
  TitleContainer,
  EmailText,
  TitleEmailDiv,
} from "./styled";
import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
  StarBorder as StarBorderIcon,
  Star as StarIcon,
} from "@mui/icons-material";
import { Menu, MenuItem, Typography } from "@mui/material";
import {
  RiAlertLine,
  RiDeleteBinLine,
  RiEdit2Fill,
  RiFileCopyLine,
  RiMoreFill,
  RiTodoLine,
  RiCheckboxCircleLine,
  RiPencilLine
} from "@remixicon/react";
import { ConfirmDeleteModal } from "../Modals/ConfirmDeleteModal";
import { EditModalPrompt } from "../Modals/EditPromptModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { AxiosError } from "axios";
import { STATUS_CODES } from "http";

interface IPromptItemProps {
  prompt: IPrompt;
  removeFromPrompts(promptId: number): void;
  currentTab: number;
  initialVisible?: boolean;
  selectedPromptId?: number | null;
  onSelectPrompt?: (promptId: number) => void;
  updatePrompt: (updatedPrompt: IPrompt) => void;
}

interface IToastResponse {
  status_code: number;
  detail: string;
}

const NewPromptItem = ({
  prompt,
  removeFromPrompts,
  currentTab,
  initialVisible = false,
  onSelectPrompt,
  updatePrompt,
}: IPromptItemProps) => {
  const { t } = useTranslation();
  const [likeCount, setLikeCount] = useState(prompt.like_count);
  const [isLiked, setIsLiked] = useState(prompt.user_has_liked);
  const [isFavorited, setIsFavorited] = useState(prompt.favorite);
  const [isFavoritedWithLike, setIsFavoritedWithLike] = useState(prompt.user_has_favorited);
  const [promptDate, setPromptDate] = useState<string>("");
  const [isContentVisible, setIsContentVisible] = useState(initialVisible);
  const [isEditing, setIsEditing] = useState(false);
  const [editedPrompt, setEditedPrompt] = useState({ ...prompt });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const selectedLanguage = localStorage.getItem(LANGUAGE_LOCAL_STORAGE);

  useEffect(() => {
    if (!selectedLanguage) return;
    moment.updateLocale(selectedLanguage, {
      relativeTime: {
        future: t("global.relativeTime.future"),
        past: t("global.relativeTime.past"),
        s: t("global.relativeTime.s"),
        m: t("global.relativeTime.m"),
        mm: t("global.relativeTime.mm"),
        h: t("global.relativeTime.h"),
        hh: t("global.relativeTime.hh"),
        d: t("global.relativeTime.d"),
        dd: t("global.relativeTime.dd"),
        M: t("global.relativeTime.M"),
        MM: t("global.relativeTime.MM"),
        y: t("global.relativeTime.y"),
        yy: t("global.relativeTime.yy"),
      },
    });

    setPromptDate(moment(prompt.created_at).fromNow());
  }, [selectedLanguage]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(prompt.prompt);
    toast((t("Toast.copySuccess")), {
      toastId: "success1",
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      autoClose: 5000,
      className: "bg-success text-white",
      closeButton: true,
      icon: <RiCheckboxCircleLine />
    });
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    setIsDeleteModalOpen(true);
  };

  const handleEditClick = () => {
    setEditedPrompt({ ...prompt });
    setIsEditing(true);
    setIsEditModalOpen(true);
    setAnchorEl(null);
  };

  const handleFav = async () => {
    if (isFavorited) return;
    setIsFavorited(true);
    try {
      await promptsService.favorite(prompt.id, true);
    } catch (error) {
      setIsFavorited(false);
      const axiosErro = error as AxiosError;
      if (axiosErro.response) {
        const statusCode: number = axiosErro.response.status;
      //const erroTratado = axiosErro.response?.data as IToastResponse;
      let errorMessage = '';
      if (statusCode === 400) {
        errorMessage = t("promptFavoriteError.detail400");
      } else if (statusCode === 404) {
        errorMessage = t("promptFavoriteError.detail404");
      }
      toast(errorMessage, {
        toastId: "error2",
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        autoClose: 5000,
        className: "bg-warning text-white",
        closeButton: true,
        icon: <RiAlertLine />,
      });}
    }
  };

  const handleFavWithLike = async () => {
    if (isFavoritedWithLike) return;
    setIsFavoritedWithLike(true);
    try {
      await promptsService.favorite_like(prompt.id, true);
    } catch (error) {
      setIsFavoritedWithLike(false);
      const axiosErro = error as AxiosError;
      if (axiosErro.response) {
        const statusCode: number = axiosErro.response.status;
      //const erroTratado = axiosErro.response?.data as IToastResponse;
      let errorMessage = '';
      if (statusCode === 400) {
        errorMessage = t("promptFavoriteError.detail400");
      } else if (statusCode === 404) {
        errorMessage = t("promptFavoriteError.detail404");
      }
      toast(errorMessage, {
        toastId: "error2",
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        autoClose: 5000,
        className: "bg-warning text-white",
        closeButton: true,
        icon: <RiAlertLine />,
      });}
    }
  };

  const handleUnFav = async () => {
    if (!isFavorited) return;
    setIsFavorited(false);
    try {
      await promptsService.favorite(prompt.id, false);
    } catch (error) {
      setIsFavorited(true);
    }
  };

  const handleUnFavWithLike = async () => {
    if (!isFavoritedWithLike) return;
    setIsFavoritedWithLike(false);
    try {
      await promptsService.favorite_like(prompt.id, false);
    } catch (error) {
      setIsFavoritedWithLike(true);
    }
  };

  const handleLike = async () => {
    if (isLiked) return;
    setIsLiked(true);
    setLikeCount((prev) => prev + 1);
    try {
      await promptsService.like(prompt.id);
    } catch (error) {
      setIsLiked(false);
      setLikeCount((prev) => prev - 1);
    }
  };

  const handleUnlike = async () => {
    if (!isLiked) return;
    setIsLiked(false);
    setLikeCount((prev) => prev - 1);
    try {
      await promptsService.unlike(prompt.id);
    } catch (error) {
      setIsLiked(true);
      setLikeCount((prev) => prev + 1);
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      removeFromPrompts(prompt.id);
      setIsDeleteModalOpen(false);
      await promptsService.remove(prompt.id);
    } catch (error) {
      toast((t("Toast.deletePrompt")), {
        toastId: "error1",
        position: "top-right",
        autoClose: 5000,
        className: "bg-danger text-white",
        icon: <RiDeleteBinLine />
      });
    }
  };

  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
    onSelectPrompt && onSelectPrompt(prompt.id);
  };

  return (
    <>
      <EditModalPrompt
        ModalToggle={isEditModalOpen}
        setModalToggle={setIsEditModalOpen}
        prompt={editedPrompt}
        setEditedPrompt={setEditedPrompt}
        updatePrompt={updatePrompt}
      />
      <PromptContainer
        onClick={() => {
          onSelectPrompt && onSelectPrompt(prompt.id);
          setIsContentVisible(!isContentVisible);
        }}
        isSelected={isContentVisible}
      >
        <TitleBox isSelected={isContentVisible}>
          <TitleContainer>
            <TitleIconWrapper>
              <RiTodoLine size="2rem" color="#00A793" />
            </TitleIconWrapper>
            <TitleEmailDiv>
              <h6 style={{ fontSize: "13.2px", fontWeight: "bold" }} color="#363D48" className="mb-1">
                {prompt.title}
              </h6>
              <EmailText>{prompt.user.email}</EmailText>
            </TitleEmailDiv>
          </TitleContainer>
          <ButtonGroup>
            {(currentTab === 3 || currentTab === 2) &&
              (currentTab === 3 ? (
                <ActionButton
                  onClick={(e) => {
                    e.stopPropagation();
                    isFavorited ? handleUnFav() : handleFav();
                  }}
                >
                  {isFavorited ? (
                    <StarIcon sx={{ color: "#FFD700" }} />
                  ) : (
                    <StarBorderIcon sx={{ color: "#363D48" }} />
                  )}
                </ActionButton>
              ) : (
                <ActionButton
                  onClick={(e) => {
                    e.stopPropagation();
                    isFavoritedWithLike ? handleUnFavWithLike() : handleFavWithLike();
                  }}
                >
                  {isFavoritedWithLike ? (
                    <StarIcon sx={{ color: "#FFD700" }} />
                  ) : (
                    <StarBorderIcon sx={{ color: "#363D48" }} />
                  )}
                </ActionButton>
              ))}
            <ActionButton
              onClick={(e) => {
                e.stopPropagation();
                isLiked ? handleUnlike() : handleLike();
              }}
            >
              {isLiked ? (
                <FavoriteIcon
                  fontSize="small"
                  sx={{ fontSize: "20px", color: "#FF0000", padding: "1px" }}
                />
              ) : (
                <FavoriteBorderIcon
                  fontSize="small"
                  sx={{ fontSize: "20px", color: "#363D48", padding: "1px" }}
                />
              )}
              <Typography sx={{ color: "#363D48", marginLeft: "5px", fontSize: "16px" }}>
                {likeCount}
              </Typography>
            </ActionButton>
            {currentTab === 1 || currentTab === 2 || currentTab === 0 ? (
              <ActionButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopy();
                }}
              >
                <RiFileCopyLine size={17} />
              </ActionButton>
            ) : (
              <>
                <ActionButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMenuClick(e);
                  }}
                >
                  <RiMoreFill size="20" />
                </ActionButton>
                <Menu
                  disableScrollLock={true}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleCopy} sx={{ fontSize: "12px", width: "135px" }}>
                    <RiFileCopyLine size="15" className="me-2" /> {t("pages.contractAnalysis.actions.copy")}
                  </MenuItem>
                  <MenuItem onClick={handleEditClick} sx={{ fontSize: "12px" }}>
                    <RiPencilLine size="15" className="me-2" /> {t("global.edit")}
                  </MenuItem>
                  <MenuItem onClick={handleDeleteClick} sx={{ fontSize: "12px" }}>
                    <RiDeleteBinLine size="15" className="me-2" /> {t("global.remove")}
                  </MenuItem>
                </Menu>
              </>
            )}
            <ActionButton
              onClick={(e) => {
                e.stopPropagation();
                toggleContentVisibility();
              }}
            >
              <ToggleIcon
                sx={{ fontSize: "20px", color: "#363D48", padding: "1px" }}
                fontSize="small"
                isVisible={isContentVisible}
              />
            </ActionButton>
          </ButtonGroup>
        </TitleBox>
        {isContentVisible && (
          <div
            className="div-prompt-text"
            style={{ padding: "20px", borderRadius: "4px 4px 0 0;" }}
          >
            <h6 className="mb-1">{prompt.prompt}</h6>
          </div>
        )}
      </PromptContainer>
      <ConfirmDeleteModal
        isOpen={isDeleteModalOpen}
        toggle={handleCancelDelete}
        message="Tem certeza de que deseja excluir este prompt?"
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </>
  );
};

export { NewPromptItem };
