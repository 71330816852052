import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { Typography } from "antd";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column: 1 / span 12;
`;

export const ChatColumn = styled.div`
  grid-column: 1 / span 12;
`;

export const BubbleContainer = styled.div`
  width: 100%;
  display: inline-block;
  padding: 20px 0;
  position: relative;
`;

export const BubbleContent = styled.div<{ $owner: "bot" | "user" }>`
  grid-column: ${(props) => (props.$owner === "user" ? "2 / span 11" : "1 / span 12")};
  max-width: ${(props) => (props.$owner === "user" ? "calc(96% - 100px)" : "100%")};
  background-color: ${(props) => (props.$owner === "user" ? "#F3F6F9" : "transparent")};
  border-radius: 20px;
  border-bottom-right-radius: ${(props) => (props.$owner === "user" ? "0" : "20px")};
  border-top-left-radius: ${(props) => (props.$owner === "user" ? "20px" : "0")};
  color: #3e4352;
  float: ${(props) => (props.$owner === "user" ? "right" : "left")};
  position: relative;
  margin-left: ${(props) => (props.$owner === "user" ? "5px" : "55px")};
  margin-right: ${(props) => (props.$owner === "user" ? "38px" : "0")};
  padding: 5px;
  z-index: 1;

  @media (max-width: 480px) {
    margin-left: ${(props) => (props.$owner === "user" ? "-1px" : "-17px")};
    margin-right: ${(props) => (props.$owner === "user" ? "-1px" : "0")};
    max-width: ${(props) => (props.$owner === "user" ? "calc(100% - 41px)" : "calc(100% - -83px)")};
  }

  a {
    color: #3e4352;
    text-decoration: underline;
    font-weight: bold;
  }

  > .message_time {
    position: absolute;
    height: 20px;
    bottom: -20px;
    right: ${(props) => (props.$owner === "user" ? "10px" : "auto")};
    left: ${(props) => (props.$owner === "user" ? "auto" : "33px;")};

    @media (max-width: 480px) {
      right: ${(props) => (props.$owner === "user" ? "10px" : "auto")};
      left: ${(props) => (props.$owner === "user" ? "auto" : "24px;")};
    }
  }

  > .bot_avatar {
    display: none;
  }

  @media (min-width: 800px) {
    > .bot_avatar {
      display: ${(props) => (props.$owner === "user" ? "none" : "block")};
    }
  }
`;

export const ReferenceContainer = styled.div`
  border-radius: 19px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 5px 28px;
  @media (max-width: 480px) {
    padding-left: 1rem;
    max-width: 346px;
  }
`;

export const MessageBox = styled.div`
  padding: 15px 25px 15px 30px;
  line-height: 1.4;
  @media (max-width: 480px) {
    padding: 15px 15px 15px 18px;
  }
`;

export const StyledMarkdown = styled(ReactMarkdown)`
  p {
    margin-bottom: 1em;
  }

  p:last-child {
    margin-bottom: 0;
  }

  ul,
  ol {
    padding-left: 1em;
    margin: 1em 0 0 1em;
  }

  li {
    margin: 0.5em 0;
  }

  li:last-child {
    margin-bottom: 1em;
  }

  h3 {
    font-size: 18px;
  }
  h4,
  h5,
  h6 {
    margin: 1em 0 0.5em;
  }

  blockquote {
    margin: 1em 0;
    padding-left: 1em;
    border-left: 4px solid #ccc;
    color: #666;
  }

  code {
    background-color: #f5f5f5;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    word-wrap: break-word;
  }

  pre {
    background-color: #f5f5f5;
    padding: 1em;
    border-radius: 3px;
    overflow: auto;
    max-width: 690px;
  }

  @media (max-width: 480px) {
    pre {
      max-width: 280px;
    }

    h3 {
      font-size: 19px;
    }

    h4 {
      font-size: 18px;
    }
  }

`;

export const LegendText = styled(Typography.Text)`
  font-family: Inter, sans-serif;
  font-size: 11.55px;
  line-height: 13.98px;
  color: #74757b;
  margin-top: 8px;
  display: block;
  padding-left: 6px;
`;

export const ReferenceBox = styled.div`
  background-color: #f3f6f9;
  width: 80%;
  border-radius: 5px;
`;
