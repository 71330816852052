import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../redux/authenticationSlice";
import { useTranslation } from "react-i18next";
import { ISource } from "../../../services";

interface FormData {
  name: string;
  description: string;
  prompt: string;
  isPublic: boolean;
  internalKnowledgeOnly: boolean;
  temperature?: number;
  seed?: number;
  number_of_documents_retrived_to_context?: number;
  min_score?: number;
  icon?: string;
  owner?: { email: string; id: number };
  external?: boolean;
}

interface FormErrors {
  name?: string;
  description?: string;
  prompt?: string;
}

export function useGPTForm() {
  const userData = useSelector(selectUserData);
  const { t } = useTranslation();
  const id = sessionStorage.getItem("project-id-form");

  const [formData, setFormData] = useState<FormData>({
    name: "",
    description: "",
    prompt: "",
    isPublic: false,
    internalKnowledgeOnly: true,
    temperature: 0.7,
    seed: 42,
    number_of_documents_retrived_to_context: 4,
    min_score: 0.1,
    icon: "defaultIcon.svg",
    external: false,
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [dirt, setDirt] = useState<boolean>(false);

  const shouldDisableFields = useMemo(() => {
    if (formData.isPublic && isEditing) {
      return !userData?.is_admin && !userData?.is_superuser;
    } else if (isEditing && !formData.isPublic) {
      return userData?.id !== formData.owner?.id && !userData?.is_admin && !userData?.is_superuser;
    } else {
      return false;
    }
  }, [userData, formData.isPublic, formData.owner, isEditing]);

  function getHasError(): boolean {
    return Object.keys(errors).length > 0;
  }
  function getAllFieldsFilled(sources: ISource[]): boolean {
    const isNameFilled = formData.name.trim().length > 0;
    const isDescriptionFilled = formData.description.trim().length > 0;
    const isPromptFilled = formData.prompt.trim().length > 0;
    const hasSources = sources.length > 0;
    return isNameFilled && isDescriptionFilled && (isPromptFilled || hasSources);
  }

  function validateForm(): void {
    const newErrors: FormErrors = {};

    if (formData.name.length > 20) {
      newErrors.name = t("pages.projects.components.createEditPage.name.error");
    }

    if (formData.description.length > 180) {
      newErrors.description = t("pages.projects.components.createEditPage.description.error");
    }

    if (formData.prompt.length > 12000) {
      newErrors.prompt = t("pages.projects.components.createEditPage.basePrompt.error");
    }

    setErrors(newErrors);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { name, value, type } = e.target;

    if (e.target instanceof HTMLInputElement && type === "checkbox") {
      const checked = name === "internalKnowledgeOnly" ? !e.target.checked : e.target.checked;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value || "",
      }));
    }
    setDirt(true);
    validateForm();
  };

  useEffect(() => {
    if (!userData) return;
    if (id && parseInt(id) !== 0) {
      setIsEditing(true);
      setFormData((prev) => ({ ...prev, owner: { email: userData.email, id: userData.id } }));
    }
  }, [id, userData]);

  return {
    formData,
    setFormData,
    errors,
    shouldDisableFields,
    getHasError,
    getAllFieldsFilled,
    validateForm,
    isEditing,
    handleChange,
    dirt,
    setDirt,
  };
}
