import styled from "styled-components";
import ReactMarkdown from "react-markdown";

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 53%;
  width: 755px;
  height: 543px;
  background: #fff;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px){
    padding: 16px;
    width: 327px;
    height: 491px;
  }
`;

export const ModalHeaderCreatPrompt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  background-color: #ccede9;
  padding: 0 20px;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    height: auto;
    padding: 10px 20px;
  }
`;

export const ModalHeaderTitle = styled.h4`
  display: flex;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  color: #495057;
  padding-top: 18px;
  font-weight: bold;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding-top: 13px;
  }
`;

export const ModalHeaderButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #495057;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const ModalSubtitle = styled.div`
  height: 20px;
  width: auto;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #212529;
  margin-bottom: 2px;
  padding-bottom: 21px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    font-size: 12px;
  }
`;

export const ModalSubtitleTextArea = styled.div`
  height: 20px;
  width: auto;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #212529;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    font-size: 12px;
  }
`;

export const ModalInput = styled.input<{ error: boolean }>`
  width: 100%;
  padding: 9px;
  margin-bottom: 1px;
  border: 1px solid ${(props) => (props.error ? "red" : "#ccc")};
  border-radius: 4px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const ModalLabel = styled.p`
  font-size: 12px;
  color: #878a99;

  @media (max-width: 768px) {
    margin-left: 0;
    font-size: 11px;
  }
`;

export const ModalTextArea = styled.textarea<{ error: boolean }>`
  width: 100%;
  height: 178px;
  padding: 9px;
  border: 1px solid ${(props) => (props.error ? "red" : "#ccc")};
  border-radius: 4px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    height: 89px;
  }
`;

export const ModalButton = styled.button`
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
`;

export const LoadingSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #00a793;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    border-width: 4px;
  }
`;

export const PromptList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 712px;
  gap: 20px;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const NoPromptsMessage = styled.div`
  font-size: 20px;
  color: #777;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const StyledMarkdown = styled(ReactMarkdown)`
  p {
    margin-bottom: 1em;
  }

  p:last-child {
    margin-bottom: 0;
  }

  ul,
  ol {
    padding-left: 1em;
    margin: 1em 0 0 1em;
  }

  li {
    margin: 0.5em 0;
  }

  li:last-child {
    margin-bottom: 1em;
  }

  h3 {
    font-size: 18px;
  }
  h4,
  h5,
  h6 {
    margin: 1em 0 0.5em;
  }

  blockquote {
    margin: 1em 0;
    padding-left: 1em;
    border-left: 4px solid #ccc;
    color: #666;
  }

  code {
    background-color: #f5f5f5;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    word-wrap: break-word;
  }

  pre {
    background-color: #f5f5f5;
    padding: 1em;
    border-radius: 3px;
    overflow: auto;
    max-width: 690px;
  }

  @media (max-width: 480px) {
    pre {
      max-width: 280px;
    }

    h3 {
      font-size: 19px;
    }

    h4 {
      font-size: 18px;
    }
  }
`;