import React, { useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import {
  LAYOUT_TYPES,
  LEFT_SIDEBAR_SIZE_TYPES,
  SIDEBAR_VISIBILITY_TYPES,
  LAYOUT_POSITION_TYPES,
} from "../components/constants/layout";
import { useDispatch } from "react-redux";
import {
  changeLayout,
  changeSidebarVisibility,
  changeLeftsidebarSizeType,
  changeLayoutPosition,
} from "../slices/thunks";
import { chatService, projectService } from "../services";
import { actionSelectChat, actionSetConversations } from "../redux/chatSlice";
import { actionSetProjects } from "../redux/projectSlice";
import { usersService } from "../services/users";
import { actionSetUsers } from "../redux/userSlice";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
  const layoutType = LAYOUT_TYPES.VERTICAL;
  const sidebarVisibilitytype = SIDEBAR_VISIBILITY_TYPES.SHOW;
  const leftsidbarSizeType = LEFT_SIDEBAR_SIZE_TYPES.DEFAULT;
  const layoutPositionType = LAYOUT_POSITION_TYPES.FIXED;
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (layoutType || leftsidbarSizeType || sidebarVisibilitytype) {
      window.dispatchEvent(new Event("resize"));
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
      dispatch(changeLayout(layoutType));
      dispatch(changeSidebarVisibility(sidebarVisibilitytype));
      dispatch(changeLayoutPosition(layoutPositionType));
    }
  }, [layoutType, leftsidbarSizeType, sidebarVisibilitytype, layoutPositionType, dispatch]);

  //busca as conversas entre as rotas(nao tirar isso, porque se a pessoa entrar direto na rota pelo link o menu lateral nao é carregado)
  useEffect(() => {
    (async () => {
      try {
        const fetchedConversations = await chatService.listAllChats();

        const sortedConversations = fetchedConversations.sort((a, b) => {
          const dateA = new Date(a.created_at).getTime();
          const dateB = new Date(b.created_at).getTime();

          return dateB - dateA;
        });
        dispatch(actionSetConversations(sortedConversations));
        dispatch(actionSelectChat({ selectedChatId: Number(process.env.REACT_APP_DEFAULT_CHAT_ID), emptyChat: true }));
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  //busca os projetos entre as rotas(nao tirar isso, porque se a pessoa entrar direto na rota pelo link o menu lateral nao é carregado)
  useEffect(() => {
    (async () => {
      try {
        const fetchedProjects = await projectService.listProjects();

        dispatch(actionSetProjects(fetchedProjects));
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  //busca os usuarios da microsoft
  useEffect(() => {
    (async () => {
      try {
        const fetchedUsers = await usersService.getMicrosoftUsers();
        dispatch(actionSetUsers(fetchedUsers));
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header headerClass="" />
        <Sidebar />
        <div className="main-content">{props.children}</div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
