import styled from "styled-components";
import newBackgroundIa from "../../assets/logo_center_kyno.png";

export const LoginPageBackground = styled.main`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: #f3f6f9;
  padding-left: 11vw;
  background-image: url(${newBackgroundIa});
  background-repeat: no-repeat;
  background-position: center bottom;

  @media (max-width: 480px) {
    background-image: none;
    background-color: #f3f6f9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  max-width: 419px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LogoKynoImage = styled.image`
  max-height: 310px;
  max-width: 288px;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-image: url(${newBackgroundIa});
`;

export const AlingLogo = styled.div`
  display: flex;
  max-width: 419px;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

export const Logo = styled.img`
  max-width: 150px;
  object-fit: contain;

  @media (max-width: 480px) {
    margin-bottom: 0;
    margin-left: 0;
    max-width: 280px;
    order: 2;
    padding-top: 1rem;
  }
`;

export const ContainerProduct = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  max-width: 419px;
  width: 100%;
`;

export const TitleForm = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: 16.25px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin-bottom: 21px;
  color: #00a793;
`;

export const ParagraphForm = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #9698a6;
`;

export const BoxButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

export const FormContainer = styled.div`
  max-width: 419px;
  height: 180px;
  background: #ffffff;
  border-radius: 4px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;

  @media (max-width: 480px) {
    width: 400px;
    padding: 30px;
  }

  @media (max-height: 600px) {
    padding-bottom: 0rem;
  }

  @media (max-height: 900px) {
    width: 400px;
    padding-bottom: 4rem;
  }
`;

export const ContentContainer = styled.div`
  flex: 1;
  padding-top: 22px;
  width: 50%;

  @media (max-width: 480px) {
    flex: 1;
    padding-top: 13px;
    width: 50%;
  }
`;

export const RectanguleVideo = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 55%;
  height: 99%;
  z-index: 2;

  @media (max-width: 480px) {
    display: none;
  }
`;
