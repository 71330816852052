import { Card } from "reactstrap";
import styled from "styled-components";

export const GptCardContainer = styled(Card)`
  width: 100%;
  flex: 1;
  user-select: none;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid var(--vz-gray-400);

  .action-container {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    .btn-light {
      border: none;
      background-color: transparent;
      &:hover {
        border: none;
      }
    }

    .show {
      z-index: 1;
    }
    .dropdown-menu {
      z-index: 2;
      .dropdown-item {
        display: flex;
        align-items: center;
      }
    }
  }

  .card-body {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 0 0 1rem 0;
    margin: 0;
    width: 100%;
    .gpt-card-icon {
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      background-color: var(--vz-gray-100);
      border-radius: 4px;

      .icon {
        width: 20px;
        height: 20px;
      }
    }

    .gpt-card-content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      width: 100%;

      .gpt-card-title {
        font-weight: 600;
        line-height: 20px;
        font-size: 13.2px;
        color: var(--vz-gray-800);
        margin-bottom: 0.25rem;
      }

      .gpt-card-description {
        font-weight: 400;
        line-height: 13.98px;
        font-size: 11.55px;
        color: var(--vz-gray-800);
        margin-bottom: 0;
        // 100% - action buttons
        max-width: calc(100% - 100px);
      }

      .gpt-card-owner {
        font-weight: 600;
        line-height: 13.98px;
        font-size: 11.55px;
        color: var(--vz-gray-600);
        span {
          font-weight: 400;
        }
      }
    }
  }
`;
