export const msalConfig = {
  auth: {
    clientId: "59aa6962-6a36-41a6-8caf-50da13e95359",
    authority: "https://login.microsoftonline.com/67822b61-6451-4d22-a5a8-ec5321cd6b5d",
    redirectUri: process.env.REACT_APP_BASE_FRONT_URL || "",
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
