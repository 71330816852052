import styled, { css, keyframes } from "styled-components";

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`;

export const FileThumbCard = styled.div<{ loading: boolean }>`
  width: 100%;
  border: 1px dashed var(--vz-gray-300);
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  ${({ loading }) =>
    loading
      ? css`
          animation: ${pulse} 1s infinite;
        `
      : "animation: none;"}

  .file-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    background-color: var(--vz-gray-100);
    > svg {
      color: var(--vz-secondary);
    }
  }

  .action-btn-container {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    margin-left: auto;
    svg {
      color: var(--vz-gray-600);
    }
  }

  .file-info-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    h6 {
      font-size: 13px;
      line-height: 15.85px;
      letter-spacing: 0.2px;
      font-weight: 600;
      color: var(--vz-gray-700);
      text-transform: capitalize;
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      font-size: 13.2px;
      line-height: 20px;
      font-weight: 400;
      color: var(--vz-gray-500);
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;

    .file-info-container {
      display: block; /* Ajuste para o layout de grid */
    }
  }
`;
