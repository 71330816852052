import styled from "styled-components";

export const EmptyStateGPTCardContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: flex-start;

  .empty-content {
    max-width: 326px;
  }

  .empty-state-title {
    font-size: 13.2px;
    line-height: 20px;
    font-weight: 600;
  }

  .empty-state-description {
    font-size: 11.55px;
    line-height: 13.98px;
    font-weight: 400;
    color: var(--vz-gray-700);
    span {
      font-weight: 600;
    }
  }

  .empty-icon {
    height: 3.18rem;
  }
`;
