import React, { useState, useCallback } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { ShareModalBody } from "./styled";
import { RiDeleteBinLine, RiSearch2Line } from "@remixicon/react";
import { IUserMicrosoft } from "../../../services/users";
import { SelectedUsersWithPermissions } from "../../../pages/Projects/form";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../redux/authenticationSlice";

interface ShareGPTModalProps {
  open: boolean;
  toggle: () => void;
  setGrantedUsers: React.Dispatch<React.SetStateAction<SelectedUsersWithPermissions[]>>;
  grantedUsers: SelectedUsersWithPermissions[];
  microsoftUsers: IUserMicrosoft[];
}

interface Permissions {
  delete: boolean;
  upload: boolean;
}

const DEBOUNCE_TIME = 200;

export function ShareGPTModal({
  open,
  toggle,
  setGrantedUsers,
  grantedUsers = [],
  microsoftUsers,
}: ShareGPTModalProps) {
  const { t } = useTranslation();
  const userData = useSelector(selectUserData);

  const [selectedUsers, setSelectedUsers] = useState<Set<IUserMicrosoft>>(new Set());
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState<IUserMicrosoft[]>([]);
  const [permissions, setPermissions] = useState<Permissions>({
    delete: false,
    upload: false,
  });

  const debouncedFetchUsers = useCallback(
    _.debounce((query) => {
      const lowerQuery = query.toLowerCase();
      const filteredUsers = microsoftUsers.filter(
        (user) =>
          (user.email.toLowerCase().includes(lowerQuery) ||
            user.name.toLowerCase().includes(lowerQuery)) &&
          user.email !== userData?.email &&
          !selectedUsers.has(user) &&
          !grantedUsers.some((grantedUser) => grantedUser.user.email === user.email),
      );
      setSuggestions(filteredUsers.slice(0, 3));
    }, DEBOUNCE_TIME),
    [microsoftUsers, selectedUsers],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    if (newQuery.length > 0) {
      debouncedFetchUsers(newQuery);
    } else {
      setSuggestions([]);
    }
  };

  const handleUserClick = (user: IUserMicrosoft) => {
    setSelectedUsers((prev) => new Set(prev).add(user));
    setQuery("");
    setSuggestions([]);
  };

  const getHighlightedText = (text: string, highlight: string) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? <b key={index}>{part}</b> : part,
        )}
      </>
    );
  };

  const renderSuggestion = (user: IUserMicrosoft) => {
    const lowerQuery = query.toLowerCase();
    const isSearchingByEmail = user.email.toLowerCase().includes(lowerQuery);
    const isSearchingByName = user.name.toLowerCase().includes(lowerQuery);

    return (
      <li
        key={user.email}
        onClick={() => handleUserClick(user)}
        className="autocomplete-suggestion"
      >
        <span>
          {isSearchingByName ? getHighlightedText(user.name, query) : user.name}{" "}
          <span className="text-muted">
            ({isSearchingByEmail ? getHighlightedText(user.email, query) : user.email})
          </span>
        </span>
      </li>
    );
  };

  const handleDeleteUser = (email: string) => {
    setSelectedUsers((prev) => {
      const updatedUsers = new Set(prev);
      updatedUsers.forEach((user) => {
        if (user.email === email) updatedUsers.delete(user);
      });
      return updatedUsers;
    });
  };

  const handleShareGPT = () => {
    const grantedUsersWithPermission = Array.from(selectedUsers).map((user) => ({
      user,
      delete: permissions.delete,
      upload: permissions.upload,
      change: true,
    }));
    setGrantedUsers((prev) => [...prev, ...grantedUsersWithPermission]);
    setSelectedUsers(new Set());
    setPermissions({ delete: false, upload: false });
    toggle();
  };

  return (
    <Modal isOpen={open} toggle={toggle} centered size="xl" unmountOnClose={true}>
      <ModalHeader toggle={toggle}>
        {t("pages.projects.components.createEditPage.sharing.modal.title")}
      </ModalHeader>
      <ShareModalBody>
        <div className="search-container">
          <InputGroup>
            <InputGroupText>
              <RiSearch2Line className="text-muted" size={11} />
            </InputGroupText>
            <Input
              placeholder={t(
                "pages.projects.components.createEditPage.sharing.modal.searchPlaceholder",
              )}
              type="search"
              className="input-search"
              value={query}
              onChange={handleInputChange}
            />
          </InputGroup>
          {suggestions.length > 0 && (
            <ul className="autocomplete-suggestions">{suggestions.map(renderSuggestion)}</ul>
          )}
        </div>
        {selectedUsers.size > 0 ? (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">
                    {t("pages.projects.components.createEditPage.sharing.table.user")}
                  </th>
                  <th scope="col">
                    {t("pages.projects.components.createEditPage.sharing.table.action")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.from(selectedUsers).map((user) => (
                  <tr key={user.email}>
                    <td className="table-user-column">
                      {user.name} <span className="text-muted">({user.email})</span>
                    </td>
                    <td className="table-action-column">
                      <Button
                        type="button"
                        onClick={() => handleDeleteUser(user.email)}
                        className="delete-user-btn"
                      >
                        <RiDeleteBinLine className="text-muted" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="permission-container">
              <div className="form-check form-switch">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="isPublic"
                  id="upload-permission"
                  checked={permissions.upload}
                  onChange={() => setPermissions((prev) => ({ ...prev, upload: !prev.upload }))}
                />
                <Label className="form-check-label" htmlFor="upload-permission">
                  {t("pages.projects.components.createEditPage.sharing.modal.upload")}
                </Label>
              </div>
              <div className="form-check form-switch">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="isPublic"
                  id="delete-permission"
                  checked={permissions.delete}
                  onChange={() => setPermissions((prev) => ({ ...prev, delete: !prev.delete }))}
                />
                <Label className="form-check-label" htmlFor="delete-permission">
                  {t("pages.projects.components.createEditPage.sharing.modal.delete")}
                </Label>
              </div>
            </div>
          </div>
        ) : (
          <div className="granted-users-table">
            <i>
              <img src="/emptyStateGrantedUserIcon.svg" alt="Empty state" />
            </i>
            <p className="empty-message">
              {t("pages.projects.components.createEditPage.sharing.modal.message")}
            </p>
          </div>
        )}
      </ShareModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggle}>
          {t("global.cancel")}
        </Button>
        <Button color="primary" onClick={handleShareGPT} disabled={selectedUsers.size === 0}>
          {t("pages.projects.components.createEditPage.sharing.modal.add")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
