import { Button, Modal } from "reactstrap";
import { ModalContainer } from "./styled";
import { useTranslation } from "react-i18next";

interface ConfirmDeleteModalProps {
  isOpen: boolean;
  message: string;
  toggle: () => void;
  onConfirm: () => void;
  onCancel?: () => void;
}

export function ConfirmDeleteModal({
  isOpen,
  toggle,
  message,
  onConfirm,
  onCancel,
}: ConfirmDeleteModalProps) {
  const { t } = useTranslation();
  function handleConfirm() {
    onConfirm();
    toggle();
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalContainer>
        <i>
          <img src="/modals/trash-bin.gif" alt="trash" height={'115px'}/>
        </i>
        <h6 className="modal-title">{t("global.confirmDeleteTitle")}</h6>
        <p className="modal-message">{message}</p>
        <div className="action-container">
          <Button color="light" onClick={onCancel || toggle}>
            {t("global.cancel")}
          </Button>
          <Button color="danger" onClick={handleConfirm}>
            {t("global.delete")}
          </Button>
        </div>
      </ModalContainer>
    </Modal>
  );
}
