import {
  Button,
  CardBody,
  CardText,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { GptCardContainer } from "./styled";
import {
  RiChat4Line,
  RiDeleteBinLine,
  RiEyeLine,
  RiMoreFill,
  RiPencilLine,
} from "@remixicon/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actionSelectChat } from "../../../../redux/chatSlice";
import { actionSetPageTitle } from "../../../../redux/layout/reducer";
import { IUserData } from "../../../../redux/authenticationSlice";
import { GPTCardProps } from "../../../../pages";
import { projectService } from "../../../../services";
import { actionDisplayNotification } from "../../../../redux/notificationSlice";
import { useTranslation } from "react-i18next";
import { actionSetProjects } from "../../../../redux/projectSlice";
import { useDeleteDialog } from "../../../../context/useDeleteDialog";
import { routesManager } from "../../../../routes/routesManager";

interface GPTCardsProps {
  gpt: GPTCardProps;
  userData?: IUserData | null;
  loading?: boolean;
  corporate?: boolean;
}

export function GPTCard({ gpt, userData, loading, corporate }: GPTCardsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onDeleteDialog } = useDeleteDialog();

  const isAdmin = userData?.is_admin || userData?.is_superuser;
  const isOwner = gpt.owner && gpt.owner.id === userData?.id;

  function handleGptSelection() {
    dispatch(actionSelectChat({ selectedChatId: gpt.id, emptyChat: true }));
    dispatch(actionSetPageTitle(gpt.title));
    navigate(routesManager.getHomeRoute());
  }

  function handleMenuOptions(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    event.preventDefault();
    navigate(routesManager.getProjectDetailsRoute(gpt.id));
  }

  async function handleDelete() {
    try {
      await projectService.deleteProject(gpt.id);
      const projects = await projectService.listProjects();
      dispatch(actionSetProjects(projects));
    } catch (error) {
      dispatch(
        actionDisplayNotification({
          messages: [t("global.failureDeleteMessage")],
          severity: "error",
        }),
      );
    }
  }

  if (loading) {
    return (
      <GptCardContainer>
        <CardBody>
          <div className="gpt-card-icon">
            <span className="placeholder col-5 text-primary"></span>
          </div>
          <div className="gpt-card-content">
            <CardTitle tag="p" className="gpt-card-title">
              <span className="placeholder col-5 placeholder-sm text-muted"></span>
            </CardTitle>
            <CardText className="gpt-card-description">
              <span className="placeholder col-10 placeholder-sm text-muted"></span>
            </CardText>
          </div>
        </CardBody>
      </GptCardContainer>
    );
  }

  return (
    <GptCardContainer>
      <div className="action-container">
        <Button className="text-primary" color="light" onClick={handleGptSelection}>
          {t("global.init")}
        </Button>
        <UncontrolledDropdown>
          <DropdownToggle
            color="light"
            className="btn-icon"
            onClick={(event) => event.stopPropagation()}
          >
            <RiMoreFill className="text-muted" size="16" />
          </DropdownToggle>
          <DropdownMenu color="light">
            {isOwner || isAdmin ? (
              <>
                <DropdownItem onClick={handleMenuOptions}>
                  <RiPencilLine size="13" className="me-2" />
                  {t("global.edit")}
                </DropdownItem>
                <DropdownItem
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteDialog({
                      callback: handleDelete,
                      message: t("pages.projects.components.deleteConfirmationMessage"),
                    });
                  }}
                >
                  <RiDeleteBinLine size="13" className="me-2" />
                  {t("global.remove")}
                </DropdownItem>
              </>
            ) : (
              <DropdownItem onClick={handleMenuOptions}>
                <RiEyeLine size="13" className="me-2" />
                {t("global.details")}
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <CardBody>
        <div className="gpt-card-icon">
          {corporate ? (
            <i>
              <img src={`/icons/${gpt.icon}`} alt="sharedGPT" className="icon" />
            </i>
          ) : !isOwner ? (
            <i>
              <img src="sharedGPT.svg" alt="sharedGPT" className="icon" />
            </i>
          ) : (
            <RiChat4Line className="text-primary icon" />
          )}
        </div>
        <div className="gpt-card-content">
          <CardTitle tag="p" className="gpt-card-title">
            {gpt.title}
          </CardTitle>
          <CardText className="gpt-card-description">{gpt.subtitle}</CardText>
          {gpt.owner && !isOwner && !corporate && (
            <CardText className="gpt-card-owner">
              <span>de </span>
              {gpt.owner.email}
            </CardText>
          )}
        </div>
      </CardBody>
    </GptCardContainer>
  );
}
