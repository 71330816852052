import styled from "styled-components";

export const ProjectPage = styled.section`
  width: auto;
  max-width: 920px;
  margin: 0 auto;
  min-height: calc(100svh - 70px);
  padding: 1rem;

  .section-title {
    font-size: 13px;
    font-weight: 600;
    color: var(--vz-grey-700);
    margin-bottom: 1rem;
  }

  .gpts-container {
    margin-top: 3.06rem;
  }
`;

export const PageHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 20px;
  z-index: 1000;
  background-color: var(--vz-white);
  padding-top: calc(70px + 30px);
  .page-header-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    .title {
      font-size: 26px;
      font-weight: 600;
      letter-spacing: 0.3px;
      color: var(--vz-gray-700);
    }
  }

  .nav-tabs-custom {
    font-size: 11px;
    justify-content: space-between;
    flex-wrap: nowrap;
    @media (min-width: 768px) {
      font-size: unset;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
`;

export const CorporateGptsContainer = styled.div`
  width: 100%;
  padding: 0 1px;
`;

export const CorporateChatList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    align-items: stretch;
    gap: 1rem;
  }
`;

export const MyGptsContainer = styled.div`
  width: 100%;
  &:last-child {
    padding-bottom: 4rem;
  }
`;

export const VerticalChatList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
