import { useCallback, useEffect, useRef, useState } from "react";
import { ProjectPage, PageHeader, MyGptsContainer, VerticalChatList } from "./styled";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import classnames from "classnames";
import { selectUserData } from "../../redux/authenticationSlice";
import { Button, Nav, NavItem, NavLink, Spinner } from "reactstrap";
import { RiAddBoxLine } from "@remixicon/react";
import { GPTCard } from "../../components/Projects/GPTs/GPTCard";
import { IProject, projectService } from "../../services";
import { actionDisplayNotification } from "../../redux/notificationSlice";
import { EmptyStateGPTCard } from "../../components/Projects/GPTs/emptyStateGPTCard";
import { IChat } from "../../components/chat";
import { useNavigate } from "react-router-dom";
import { actionSetProjects, selectorProjects } from "../../redux/projectSlice";
import { useNavTab } from "./useNavTab";
import { routesManager } from "../../routes/routesManager";

export interface GPTCardProps extends IChat {
  icon: string;
  priority: number;
  owner?: {
    id: number;
    email: string;
  };
}

const ProjectsPage = () => {
  const { t } = useTranslation();
  const projects = useAppSelector(selectorProjects);
  const userData = useAppSelector(selectUserData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const firstRender = useRef(true);
  const { navItems, setNavItems, handleScrollTab, getNavLinkClassName } = useNavTab();

  const [loading, setLoading] = useState(true);
  const [corporateGpts, setCorporateGpts] = useState<GPTCardProps[]>([]);
  const [myGpts, setMyGpts] = useState<GPTCardProps[]>([]);
  const [sharedWithMe, setSharedWithMe] = useState<GPTCardProps[]>([]);

  const navigateToCreateGPT = () => {
    navigate(routesManager.getProjectDetailsRoute(0));
  };

  const fetchProjects = useCallback(async () => {
    try {
      const res = await projectService.listProjects();
      dispatch(actionSetProjects(res));
      return res;
    } catch (err) {
      dispatch(
        actionDisplayNotification({
          messages: [t("pages.projects.listErrorMessage")],
          severity: "error",
        }),
      );
      throw err;
    } finally {
      setLoading(false);
    }
  }, [dispatch, t]);

  const filterGPTs = useCallback(
    (gpts: IProject[]) => {
      const corporate: GPTCardProps[] = [];
      const mine: GPTCardProps[] = [];
      const shared: GPTCardProps[] = [];

      gpts.forEach((gpt) => {
        const gptProps: GPTCardProps = {
          id: gpt.id,
          title: gpt.title,
          subtitle: gpt.description,
          icon: gpt.icon || "",
          priority: gpt.priority,
          date: "",
          isSelected: false,
          owner: {
            id: gpt.user_owner.id,
            email: gpt.user_owner.email,
          },
        };

        if (gpt.is_public) {
          corporate.push(gptProps);
        } else if (gpt.user_owner.id === userData?.id) {
          mine.push(gptProps);
        } else {
          shared.push(gptProps);
        }
      });

      if (shared.length > 0 && !navItems.some((item) => item.id === 3)) {
        setNavItems((prevNavItems) => [
          ...prevNavItems,
          { id: 3, label: t("pages.projects.components.gptTipes.sharedWithMe") },
        ]);
      }

      setCorporateGpts(corporate.sort((a, b) => a.priority - b.priority));
      setMyGpts(mine);
      setSharedWithMe(shared);
    },
    [userData?.id, navItems, t],
  );

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (projects.length) {
      filterGPTs(projects);
    }
  }, [projects, filterGPTs]);

  return (
    <ProjectPage>
      <PageHeader id="project-header-container">
        <div className="page-header-top">
          <h2 className="title">{t("global.agents")}</h2>
          <Button
            color="primary"
            className={classnames({ "btn-load": loading }, "btn-label")}
            onClick={navigateToCreateGPT}
            disabled={loading}
          >
            {loading ? (
              <i className="label-icon align-middle fs-16 me-2">
                <Spinner size="sm"></Spinner>
              </i>
            ) : (
              <i className="label-icon align-middle fs-16 me-2">
                <RiAddBoxLine size="1rem" />
              </i>
            )}
            {t("pages.projects.components.newProjectBtn")}
          </Button>
        </div>
        <Nav tabs className="nav-tabs-custom nav-primary">
          {navItems.map((item) => (
            <NavItem key={item.id}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={getNavLinkClassName(item.id)}
                onClick={() => handleScrollTab(item.id)}
              >
                {item.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </PageHeader>
      <MyGptsContainer className="gpts-container" id="gpt-container-1">
        <h6 className="section-title">
          {t("pages.projects.components.gptTipes.corporative").toUpperCase()}
        </h6>
        <VerticalChatList>
          {loading
            ? Array.from({ length: 3 }).map((_, index) => (
                <GPTCard key={index} loading={loading} gpt={{} as GPTCardProps} corporate={true} />
              ))
            : corporateGpts.map((gpt) => <GPTCard gpt={gpt} key={gpt.id} corporate={true} />)}
        </VerticalChatList>
      </MyGptsContainer>
      <MyGptsContainer className="gpts-container" id="gpt-container-2">
        <h6 className="section-title">
          {t("pages.projects.components.gptTipes.personal").slice(0, 4).toUpperCase() +
            t("pages.projects.components.gptTipes.personal").slice(4).toUpperCase()}
        </h6>
        <VerticalChatList>
          {loading ? (
            Array.from({ length: 3 }).map((_, index) => (
              <GPTCard key={index} loading={loading} gpt={{} as GPTCardProps} userData={userData} />
            ))
          ) : myGpts.length ? (
            myGpts.map((gpt) => <GPTCard gpt={gpt} key={gpt.id} userData={userData} />)
          ) : (
            <EmptyStateGPTCard navigateToCreateGPT={navigateToCreateGPT} loading={loading} />
          )}
        </VerticalChatList>
      </MyGptsContainer>
      {sharedWithMe.length ? (
        <MyGptsContainer className="gpts-container" id="gpt-container-3">
          <h6 className="section-title">
            {t("pages.projects.components.gptTipes.sharedWithMe").toUpperCase()}
          </h6>
          <VerticalChatList>
            {sharedWithMe.map((gpt) => (
              <GPTCard gpt={gpt} key={gpt.id} userData={userData} />
            ))}
          </VerticalChatList>
        </MyGptsContainer>
      ) : null}
    </ProjectPage>
  );
};

export { ProjectsPage };
