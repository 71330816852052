import React, { useEffect } from "react";
import { AppRoutes } from "./routes";
import { ThemeProvider } from "@mui/material";
import { DefaultTheme, config } from "./themes";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ConfigProvider } from "antd";
import { CustomSnackbar } from "./components/shared";
import { TermsOfUseTimenowModal } from "./components/shared/TermsOfUseTimenowModal";
import { GoogleOAuthProvider } from "@react-oauth/google";
import TagManager from "react-gtm-module";
import "./assets/scss/themes.scss";
import { ToastContainer } from "react-toastify";

const tagManagerArgs = {
  gtmId: String(process.env.REACT_APP_BASE_GTM),
};

function App() {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
      <Provider store={store}>
        <ConfigProvider theme={config}>
          <ThemeProvider theme={DefaultTheme}>
            <CustomSnackbar />
            <TermsOfUseTimenowModal />
            <AppRoutes />
            <ToastContainer className="mt-5" />
          </ThemeProvider>
        </ConfigProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
