import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LayoutState {
  pageTitle?: string;
}

const initialState: LayoutState = {};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    actionSetPageTitle(state, action: PayloadAction<string>) {
      state.pageTitle = action.payload;
    },
  },
});

export const { actionSetPageTitle } = layoutSlice.actions;
export default layoutSlice.reducer;
