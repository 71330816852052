import React from "react";
import { Progress } from "reactstrap";
import { Overlay } from "./styled";
import { useSelector } from "react-redux";
import { selectorLoading } from "../../../redux/projectSlice";

export function LoadingOverlay() {
  const loadingProject = useSelector(selectorLoading);

  if (!loadingProject.load) return null;
  return (
    <Overlay>
      <div style={{ width: "80%" }}>
        <Progress striped value={loadingProject.loadingProgess} />
        <div style={{ marginTop: "10px", color: "white", textAlign: "center" }}>
          <span>{loadingProject.loadingText}</span>
        </div>
      </div>
    </Overlay>
  );
}
