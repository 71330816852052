import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface microsoftUser{
    name: string;
    email: string;
}


interface userState{
    users: microsoftUser[];
}

const initialState: userState = {
    users: [],
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        actionSetUsers(state, action: PayloadAction<microsoftUser[]>){
            state.users = action.payload;
        },
    },
});

export const { actionSetUsers } = userSlice.actions;
export const selectorMicrosoftUsers = (state: RootState) => state.user.users;

export default userSlice.reducer;