/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  UncontrolledAccordion,
} from "reactstrap";
import { IPrompt, promptsService } from "../../services/prompts";
import { useState } from "react";
import "./index.css";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
} from "@mui/icons-material";
import { useAppDispatch } from "../../redux/hooks";
import { actionSetInputText } from "../../redux/chatSlice";

interface IEmptyItemProps {
  prompt: IPrompt;
}

const EmptyPromptItem = ({ prompt }: IEmptyItemProps) => {
  const [open, setOpen] = useState<string | undefined>(undefined);
  const [isLiked, setIsLiked] = useState(prompt.user_has_liked);
  const [likeCount, setLikeCount] = useState(prompt.like_count);

  const dispatch = useAppDispatch();

  const toggle = (id: string) => {
    if (open === id) {
      setOpen(undefined);
    } else {
      setOpen(id);
    }
  };

  const copyPrompt = () => {
    dispatch(actionSetInputText(prompt.prompt));
    // const chatInput = document.querySelector("#chat-input") as HTMLTextAreaElement | null;
    // if (chatInput) {
    //   chatInput.value = prompt.prompt;
    //   chatInput?.focus();
    //   chatInput?.addEventListener("input", () => {
    //     chatInput.style.height = `${chatInput.scrollHeight}px`;
    //   });
    // }
  };

  const setLike = () => {
    if (isLiked) {
      handleUnlike();
    } else {
      handleLike();
    }
  };

  const handleLike = async () => {
    try {
      await promptsService.like(prompt.id);
      setIsLiked(true);
      setLikeCount((prev) => prev + 1);
    } catch (error) {
      setIsLiked(false);
      if (likeCount > 0) {
        setLikeCount((prev) => prev - 1);
      }
    }
  };

  const handleUnlike = async () => {
    try {
      await promptsService.unlike(prompt.id);
      setIsLiked(false);
      if (likeCount > 0) {
        setLikeCount((prev) => prev - 1);
      }
    } catch (error) {
      setIsLiked(true);
      setLikeCount((prev) => prev + 1);
    }
  };

  const handleRightDivClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  return (
    <UncontrolledAccordion toggle={toggle} className="mb-1">
      <AccordionItem className="custom-accordion-item">
        <AccordionHeader targetId="1" className="custom-accordion-header">
          <div className="accordion-header-content">
            <div className="accordion-header-left">
              <img src="/icons/icon6.svg" className="me-2" width={25} />
              <div>
                <Typography className="accordion-prompt-title">{prompt.title}</Typography>
                <Typography className="accordion-prompt-email">{prompt.user.email}</Typography>
              </div>
            </div>
            <div className="accordion-header-right" onClick={handleRightDivClick}>
              <a className="me-3" onClick={copyPrompt}>
                Iniciar
              </a>
              <div onClick={setLike}>
                {isLiked ? (
                  <FavoriteIcon sx={{ color: "#FF0000", padding: "1px" }} />
                ) : (
                  <FavoriteBorderIcon sx={{ color: "#363D48", padding: "1px" }} />
                )}
              </div>
              <Typography className="me-3">{likeCount}</Typography>
            </div>
          </div>
        </AccordionHeader>
        <AccordionBody accordionId="1">{prompt.prompt}</AccordionBody>
      </AccordionItem>
    </UncontrolledAccordion>
  );
};

export { EmptyPromptItem };
