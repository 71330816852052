import { useMemo } from "react";
import {
  RiFile2Line,
  RiFileTextLine,
  RiSlideshow2Line,
  RiTable2,
  RiTBoxLine,
} from "@remixicon/react";

function useFileIcon(mimeType: string) {
  const icon = useMemo(() => {
    const mimeMap: { [key: string]: any } = {
      "application/pdf": RiFileTextLine,
      "application/vnd.ms-powerpoint": RiSlideshow2Line,
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": RiSlideshow2Line,
      "text/plain": RiTBoxLine,
      "application/msword": RiTBoxLine,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": RiTBoxLine,
      "text/csv": RiTable2,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": RiTable2,
      // Adicione mais tipos MIME conforme necessário
    };

    return mimeMap[mimeType] || RiFile2Line;
  }, [mimeType]);

  return icon;
}

export default useFileIcon;
