import styled from "styled-components";

export const RecorderContent = styled.div`
  width: 99%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--vz-gray-100);
  margin-top: 20px;
  gap: 5px;
  position: relative;

  &:before {
    content: "";
    background-color: var(--vz-gray-100);
    position: absolute;
    height: calc(100% + 20px);
    width: 4000px;
    left: -2000px;
    bottom: -20px;
    z-index: -1;
  }
`;

export const SpaceButtons = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`;

export const FixedHeightSpinner = styled.div`
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AnimatedSVG8Frames = styled.svg`
  #frame1 {
    visibility: hidden;
    animation: 1s show infinite;
  }

  #frame2 {
    visibility: hidden;
    animation: 1s show infinite;
    animation-delay: 0.125s;
  }

  #frame3 {
    visibility: hidden;
    animation: 1s show infinite;
    animation-delay: 0.25s;
  }

  #frame4 {
    visibility: hidden;
    animation: 1s show infinite;
    animation-delay: 0.375s;
  }

  #frame5 {
    visibility: hidden;
    animation: 1s show infinite;
    animation-delay: 0.5s;
  }

  #frame6 {
    visibility: hidden;
    animation: 1s show infinite;
    animation-delay: 0.625s;
  }

  #frame7 {
    visibility: hidden;
    animation: 1s show infinite;
    animation-delay: 0.75s;
  }

  #frame8 {
    visibility: hidden;
    animation: 1s show infinite;
    animation-delay: 0.865s;
  }

  @keyframes show {
    0% {
      visibility: visible;
    }
    12.5% {
      visibility: visible;
    }
    12.6% {
      visibility: hidden;
    }
    100% {
      visibility: hidden;
    }
  }
`;

export const AnimatedSVG4Frames = styled.svg`
  #frame1 {
    visibility: hidden;
    animation: 1s show infinite;
  }

  #frame2 {
    visibility: hidden;
    animation: 1s show infinite;
    animation-delay: 0.25s;
  }

  #frame3 {
    visibility: hidden;
    animation: 1s show infinite;
    animation-delay: 0.5s;
  }

  #frame4 {
    visibility: hidden;
    animation: 1s show infinite;
    animation-delay: 0.75s;
  }

  @keyframes show {
    0% {
      visibility: visible;
    }
    25.0% {
      visibility: visible;
    }
    25.1% {
      visibility: hidden;
    }
    100% {
      visibility: hidden;
    }
  }
`;

export const TimerFont = styled.p`
  //styleName: Text/Small;
  font-family: Inter;
  font-size: 11.55px;
  font-weight: 400;
  line-height: 13.98px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--vz-gray-600);
  margin: 0;
  margin-right: 10px;
`;
