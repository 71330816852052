/* eslint-disable @typescript-eslint/no-unused-vars */
import "./index.css";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Spinner } from "../../components/loaders";
import CompleteChat from "../../components/chat/CompleteChat/Index";
import { projectService } from "../../services";
import {
  IChatList,
  selectorConversation,
  selectorChatId,
  actionSetConversations,
} from "../../redux/chatSlice";
import { useDispatch } from "react-redux";
import { actionSetPageTitle } from "../../redux/layout/reducer";
import { useSelector } from "react-redux";

const HomePage = () => {
  const dispatch = useDispatch();
  const selectedChat = useSelector(selectorChatId);
  const conversations = useSelector(selectorConversation);

  const [obj, setObj] = useState({});
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [newMessagePrompt, setNewMessagePrompt] = useState<string>();
  const [updatedChat, setUpdatedChat] = useState<any>({});

  const handleObjChange = (newObj: any) => {
    setObj(newObj);
    getProjectTitle(newObj.projectId, newObj.projectTitle);
  };

  const openObj = (id: number) => {
    if (selectedChat?.emptyChat) {
      handleObjChange({
        id,
        projectId: id,
      });
    } else {
      const obj = conversations.find((item) => item.id === id);
      handleObjChange({
        id,
        projectId: obj?.project_id,
      });
    }
  };

  const handleChatUpdate = (chat: any) => {
    setUpdatedChat(chat);
  };

  const getProjectTitle = (projectId: number, projectName: string) => {
    if (projectId > 0) {
      (async () => {
        try {
          const project = await projectService.getProject(projectId);
          dispatch(actionSetPageTitle(project.title));
        } catch (err) {
          console.error(err);
        }
      })();
    } else {
      dispatch(actionSetPageTitle(projectName));
    }
  };

  const updateConversation = () => {
    return conversations.map((chat) => {
      if (chat.id === updatedChat.chat_id) {
        return { ...chat, title: updatedChat.message, project_id: updatedChat.project_id };
      }
      return chat;
    });
  };

  useEffect(() => {
    if (updatedChat?.type === "createChat") {
      const chatItem: IChatList = {
        project_id: updatedChat.project_id,
        title: updatedChat.message,
        id: updatedChat.chat_id,
        created_at: new Date().toISOString(),
      };
      const listConversation = [...conversations, chatItem];
      const sortedConversations = listConversation.sort((a, b) => {
        const dateA = new Date(a.created_at).getTime();
        const dateB = new Date(b.created_at).getTime();

        return dateB - dateA;
      });
      dispatch(actionSetConversations(sortedConversations));
    } else if (updatedChat?.type === "updateChat") {
      // Atualiza um chat existente
      dispatch(actionSetConversations(updateConversation()));
    }
  }, [updatedChat]);

  useEffect(() => {
    if (selectedChat?.selectedChatId) {
      openObj(selectedChat.selectedChatId);
    }
  }, [selectedChat]);

  return (
    <div className="page-content">
      {isLoadingMessages ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Spinner />
        </Box>
      ) : (
        <div translate="no">
          <CompleteChat
            obj={obj}
            onChatSend={handleChatUpdate}
            isLoadingMessages={isLoadingMessages}
            setIsLoadingMessages={setIsLoadingMessages}
            newMessagePrompt={newMessagePrompt}
            setNewMessagePrompt={setNewMessagePrompt}
          />
        </div>
      )}
    </div>
  );
};

export default HomePage;
