import { configureStore } from "@reduxjs/toolkit";
import { notificationSliceReducer } from "./notificationSlice";
import { authSliceReducer } from "./authenticationSlice";
import chatSlice from "./chatSlice";
import layoutSlice from "./layout/reducer";
import projectSlice from "./projectSlice";
import userSlice from "./userSlice";

const store = configureStore({
  reducer: {
    notification: notificationSliceReducer,
    auth: authSliceReducer,
    chat: chatSlice,
    layout: layoutSlice,
    project: projectSlice,
    user: userSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export { store };
