/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from "react-i18next";
import "./index.css";
import { Typography } from "antd";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import EmptyCard from "../EmptyCard";
import { IPrompt, promptsService } from "../../../services/prompts";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Col,
  Row,
  Container,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { routesManager } from "../../../routes/routesManager";
import { EmptyPromptItem } from "../../Prompts/EmptyPromptItem";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";

interface IUserData {
  name: string;
  role: string;
  picture: string;
}

const EmptyState = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");

  useEffect(() => {
    const sessionData = localStorage.getItem("userData");
    if (sessionData) {
      try {
        const dados: IUserData = JSON.parse(sessionData);
        setName(dados.name.split(" ")[0]);
      } catch (e) {
        console.error("Erro ao converter os dados:", e);
      }
    }
  }, []);

  return (
    <div className="emptyStateContainer">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card style={{ boxShadow: "none" }}>
              <CardBody>
                <div className="emptyTextContainer">
                  <Typography className="title">
                    {t("pages.home.emptyState.title", { name: name })}
                  </Typography>
                  <Typography className="message">
                    {t("pages.home.emptyState.message")}
                  </Typography>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EmptyState;
