/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from "react-i18next";
import { HistoricContainer2, MainMessagesContainer2 } from "../../../pages/Chat/styled";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useRef, useState } from "react";
import { IChat, IMessage } from "../types";
import { MessageList } from "../MessageList";
import { TextChatContainer, TextChatContainer2 } from "../TextChat/styled";
import { TextChat } from "../TextChat/TextChat";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { ICustomerData, selectCustomerData } from "../../../redux/authenticationSlice";
import { chatService } from "../../../services";
import { actionDisplayNotification } from "../../../redux/notificationSlice";
import TagManager from "react-gtm-module";
import "./index.css";
import { actionSelectChat, selectorChatId, selectorInputText } from "../../../redux/chatSlice";
import EmptyState from "../EmptyState";
import { jwtDecode } from "jwt-decode";

interface CompleteChatProps {
  obj: any;
  onChatSend: any;
  isLoadingMessages: boolean;
  setIsLoadingMessages: React.Dispatch<React.SetStateAction<boolean>>;
  newMessagePrompt: string | undefined;
  setNewMessagePrompt: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface IUserData {
  email: string;
}

const CompleteChat: React.FC<CompleteChatProps> = ({
  obj,
  onChatSend,
  isLoadingMessages,
  setIsLoadingMessages,
  newMessagePrompt,
  setNewMessagePrompt,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedChat = useAppSelector(selectorChatId);
  const bottomRef = useRef<HTMLInputElement>(null);
  const INITIAL_TEXT = t("pages.chat.initialMessage", {
    //documents: `[listados aqui](${routesManager.getSourcesRoute(id)} 'Knowledge base documents')`,
    documents: "do Time Knowledge",
  });
  const DEFAULT_MESSAGE: IMessage = {
    id: uuidv4(),
    type: "bot",
    date_time: Date(),
    text: INITIAL_TEXT,
  };
  const [messageList, setMessageList] = useState<IMessage[]>([DEFAULT_MESSAGE]);
  const [messageCount, setMessageCount] = useState(1);
  const customerData: ICustomerData | undefined | null = useAppSelector(selectCustomerData);
  const [currentMessage, setCurrentMessage] = useState("");
  const [isLoadingAiResponse] = useState(false);
  const [, setChats] = useState<IChat[]>();
  const [showEmptyState, setShowEmptyState] = useState(true);
  const [isBlockedMessage, setisBlockedMessage] = useState(false);

  const inputText = useAppSelector(selectorInputText);

  useEffect(() => {
    setCurrentMessage(inputText ?? "");
  }, [inputText]);

  //Função para enviar eventos para o GTM
  const trackGTMEvent = (eventName: string, eventData: Record<string, unknown>) => {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...eventData,
        timestamp: new Date().toISOString(),
      },
    });
  };

  useEffect(() => {
    setMessageCount(messageList.length);
  }, [messageList]);

  const handleNewChat = async () => {
    setIsLoadingMessages(true);
    const newChat = await chatService.createNewChat({
      projectId: obj.project_id,
    });

    setChats((prevChatList: any) => {
      if (!prevChatList) return [];
      return [
        {
          id: newChat.id,
          date: newChat.created_at,
          isSelected: true,
          email: obj?.user?.email,
          subtitle: t("pages.chat.components.newChatBtn"),
          title: newChat.title,
        },
        ...prevChatList,
      ];
    });
    dispatch(actionSelectChat({ selectedChatId: newChat.id, emptyChat: false }));

    trackGTMEvent("newChatCreated", {
      action: "User created a new chat",
      chatId: newChat.id,
      projectId: obj.project_id,
      userEmail: obj?.user?.email,
    });

    setIsLoadingMessages(false);
  };

  // Send message when Enter is pressed
  const handleEnterPressed = (event: React.KeyboardEvent<Element>) => {
    if (event.key === "Enter" && !event.shiftKey && currentMessage.trim() !== "") {
      handleSendMessage();
    } else if (event.key === "Enter" && event.shiftKey) {
      setCurrentMessage((prevMessage) => prevMessage + "\n");
    }
  };

  // Keep track of the state
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if ((e.nativeEvent as unknown as { inputType: string }).inputType === "insertLineBreak") return;
    let text = e.target.value;
    if ((e.nativeEvent as unknown as { inputType: string }).inputType === "insertFromPaste") {
      text = text.trim();
    }
    setCurrentMessage(text);
  };

  const handleSendMessage = async (prompt?: string) => {
    if (
      !selectedChat ||
      (currentMessage === "" && !prompt) ||
      isLoadingAiResponse ||
      isLoadingMessages
    ) {
      dispatch(
        actionDisplayNotification({
          messages: [t("pages.chat.components.notifications.waitForAIResponseToSend")],
          severity: "warning",
        }),
      );
      return;
    }
    if (selectedChat.emptyChat) {
      setShowEmptyState(false);
      const newChat = await chatService.createNewChat({
        projectId: obj.projectId,
      });

      const jwt = localStorage.getItem("jwt") as string;
      const decodedToken = jwtDecode(jwt) as IUserData;

      trackGTMEvent("newChatCreated", {
        action: "User created a new chat",
        chatId: newChat.id,
        projectId: obj.project_id,
        username: decodedToken.email.split("@")[0],
      });

      let message = currentMessage;
      if (!!prompt && typeof prompt === "string") {
        message = prompt;
      }
      const newUserMessage: IMessage = {
        id: uuidv4(),
        type: "user",
        date_time: Date(),
        text: message,
      };
      const newAiResponse: IMessage = {
        id: uuidv4(),
        type: "bot",
        date_time: Date(),
        text: "|",
      };
      onChatSend({
        type: "createChat",
        chat_id: newChat.id,
        project_id: obj.projectId,
        message: message,
      });
      setCurrentMessage("");
      setMessageList((prevMessageList) => [...prevMessageList, newUserMessage, newAiResponse]);
      try {
        await chatService.sendMessageStream({
          prompt: message,
          projectId: obj.projectId,
          sessionId: newChat.id,
          callback(data) {
            setisBlockedMessage(true);
            setMessageList((prevMessageList) => {
              const updatedMessages = [...prevMessageList];
              const lastMessage = updatedMessages[updatedMessages.length - 1];
              if (data.references && Array.isArray(data.references)) {
                lastMessage.references = data.references;
              }
              if (data.finish_reason) {
                lastMessage.text = lastMessage.text.replace(/\|$/, "");
              } else {
                lastMessage.text = lastMessage.text.replace(/\|$/, "") + data.delta + "|";
              }
              return updatedMessages;
            });
          },
        });

        setisBlockedMessage(false);
      } catch (error) {
        setMessageList((prevMessageList) => prevMessageList.slice(0, -2));
        dispatch(
          actionDisplayNotification({
            messages: [(error as { message: string }).message],
          }),
        );
      }
      dispatch(actionSelectChat({ selectedChatId: newChat.id, emptyChat: false }));
    } else {
      setShowEmptyState(false);
      let message = currentMessage;
      if (!!prompt && typeof prompt === "string") {
        message = prompt;
      }
      const newUserMessage: IMessage = {
        id: uuidv4(),
        type: "user",
        date_time: Date(),
        text: message,
      };
      const newAiResponse: IMessage = {
        id: uuidv4(),
        type: "bot",
        date_time: Date(),
        text: "|",
      };

      onChatSend({
        type: "updateChat",
        chat_id: selectedChat,
        project_id: obj.projectId,
        message: message,
      });
      setCurrentMessage("");
      setMessageList((prevMessageList) => [...prevMessageList, newUserMessage, newAiResponse]);
      try {
        if (selectedChat.selectedChatId) {
          setisBlockedMessage(true);
          await chatService.sendMessageStream({
            prompt: message,
            projectId: obj.projectId,
            sessionId: selectedChat.selectedChatId,
            callback(data) {
              setMessageList((prevMessageList) => {
                const updatedMessages = [...prevMessageList];
                const lastMessage = updatedMessages[updatedMessages.length - 1];
                if (data.references && Array.isArray(data.references)) {
                  lastMessage.references = data.references;
                }
                if (data.finish_reason) {
                  lastMessage.text = lastMessage.text.replace(/\|$/, "");
                } else {
                  lastMessage.text = lastMessage.text.replace(/\|$/, "") + data.delta + "|";
                }
                return updatedMessages;
              });
            },
          });
          setisBlockedMessage(false);
        }
      } catch (error) {
        setMessageList((prevMessageList) => prevMessageList.slice(0, -2));
        dispatch(
          actionDisplayNotification({
            messages: [(error as { message: string }).message],
          }),
        );
        setisBlockedMessage(false);
      }
    }
  };

  //Runs when a new chat is selected, updates the selected chat
  useEffect(() => {
    (async () => {
      if (!selectedChat) {
        return;
      }
      setCurrentMessage("");
      if (!selectedChat.emptyChat && selectedChat.selectedChatId) {
        setShowEmptyState(false);
        const chat = await chatService.retrieveChat({
          projectId: obj.project_id,
          sessionId: selectedChat.selectedChatId,
        });
        const messages: IMessage[] = [];
        chat.conversation.forEach((item) => {
          messages.push({
            id: item.date_time,
            type: "user",
            date_time: item.date_time,
            text: item.user_query,
          });
          messages.push({
            id: item.date_time,
            type: "bot",
            date_time: item.date_time,
            text: item.ai_response,
            references: item.references,
          });
        });

        setMessageList(messages);
      } else {
        setMessageList([]);
        setShowEmptyState(true);
      }
    })();
  }, [selectedChat]);

  // Scrolls to bottom every time messageList or isLoadingAiResponse is modified
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messageList, isLoadingAiResponse]);

  //create new chat if user select a prompt
  useEffect(() => {
    if (newMessagePrompt) {
      handleNewChat();
    }
  }, [newMessagePrompt]);

  useEffect(() => {
    (async () => {
      if (newMessagePrompt && !isLoadingMessages) {
        await handleSendMessage(newMessagePrompt);
        setNewMessagePrompt(undefined);
      }
    })();
  }, [isLoadingMessages]);

  return (
    <>
      {showEmptyState ? (
        // <div className="emptyStateContainer">
        //   <img src="emptyStar.svg" className="mb-4" />
        //   <label className="emptyStateTitle">{t("pages.chat.emptyStateTitle")}</label>
        //   <label className="emptyStateText">{t("pages.chat.emptyStateText")}</label>
        // </div>
        <EmptyState />
      ) : (
        <HistoricContainer2>
          <MainMessagesContainer2>
            <MessageList messages={messageList} />
            <div ref={bottomRef} />
          </MainMessagesContainer2>
        </HistoricContainer2>
      )}

      <TextChatContainer2>
        <TextChat
          isEmptyState={showEmptyState}
          customerData={customerData}
          currentMessage={currentMessage}
          handleChange={handleChange}
          handleEnterPressed={handleEnterPressed}
          handleSendMessage={handleSendMessage}
          messageCount={messageCount}
          isBlockedMessage={isBlockedMessage}
          setSpeechResponse={(value: string) => setCurrentMessage(value)}
        />
      </TextChatContainer2>
    </>
  );
};

export default CompleteChat;
