import { ModalBody } from "reactstrap";
import styled from "styled-components";

export const ShareModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  height: 70svh;

  .search-container {
    margin-bottom: 1.5rem;
    position: relative;
    > .input-group {
      > .input-group-text {
        background-color: #fff;
        border-right: none;
      }
      > .input-search {
        border-left: none;
        padding-left: 0;
      }
    }

    .autocomplete-suggestions {
      position: absolute;
      background-color: #fff;
      border: 1px solid #e5e5e5;
      border-top: none;
      width: fit-content;
      max-height: 200px;
      overflow-y: auto;
      z-index: 1000;
      border-radius: 0 0 0.25rem 0.25rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 0.5rem 0;
      margin-top: 1px;
      left: 40.78px;

      .autocomplete-suggestion {
        padding: 0.5rem 1rem;
        cursor: pointer;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }

  .table-responsive {
    position: relative;
    .table {
      .table-light {
        position: sticky;
        top: 0;
      }
      .delete-user-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: var(--vz-gray-300);
        }
      }
    }
    .permission-container {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;
      position: sticky;
      bottom: 0;
      background-color: #fff;
    }
  }

  .granted-users-table {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3rem;

    .empty-message {
      font-size: 13.2px;
      line-height: 20px;
      text-align: center;
      max-width: 459px;
      color: var(--vz-gray-600);
    }
  }
`;
