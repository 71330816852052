import styled from "styled-components";

export const TextChatContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  max-width: 70%;
  margin: 0 auto;
  z-index: 1000;
  transform: translateX(-50%);
  position: absolute;
  bottom: 0;
  background: #ffffff;
  padding: 40px 20px 20px 20px;
  margin-left: 57%;

  @media (max-width: 1200px) {
    max-width: 92%;
  }

  @media (max-width: 992px) {
    max-width: 80%;
    left: 67%;
  }

  @media (max-width: 768px) {
    max-width: 70%;
    left: 132%;
  }

  @media (max-width: 576px) {
    left: 109%;
    max-width: 100%;
  }
`;

export const TextChatContainer2 = styled.div`
  background: #ffffff;
  position: fixed;
  width: 100%;
  max-width: 880px;
  z-index: 1000;
  bottom: 0;
  left: 50%;
  transform: translateX(-43%);
  padding-bottom: 20px;

  @media (max-width: 1189px) {
    max-width: 86%;
    left: 48%;
  }

  @media (max-width: 768px) {
    max-width: 86%;
    left: 45%;
  }

  @media (max-width: 576px) {
    max-width: 93%;
    left: 44%;
  }
`;
