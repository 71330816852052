import { AnimatedSVG4Frames } from "./styled";

export const RecordingSpinner2 = () => {
  return (
    <AnimatedSVG4Frames width="50px" height="96px">
      <g id="frame1">
        <svg
          width="50"
          height="96"
          viewBox="0 0 200 96"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="38"
            width="10.0264"
            height="20"
            rx="5.01319"
            fill="url(#paint0_linear_2611_79)"
          />
          <rect
            x="84.4327"
            y="38"
            width="10.0264"
            height="20"
            rx="5.01319"
            fill="url(#paint1_linear_2611_79)"
          />
          <rect
            x="42.2164"
            y="18"
            width="10.0264"
            height="60"
            rx="5.01319"
            fill="url(#paint2_linear_2611_79)"
          />
          <rect
            x="126.649"
            y="18"
            width="10.0264"
            height="60"
            rx="5.01319"
            fill="url(#paint3_linear_2611_79)"
          />
          <rect
            x="168.865"
            y="38"
            width="10.0264"
            height="20"
            rx="5.01319"
            fill="url(#paint4_linear_2611_79)"
          />
          <rect
            x="21.1082"
            y="28"
            width="10.0264"
            height="40"
            rx="5.01319"
            fill="url(#paint5_linear_2611_79)"
          />
          <rect
            x="105.541"
            y="28"
            width="10.0264"
            height="40"
            rx="5.01319"
            fill="url(#paint6_linear_2611_79)"
          />
          <rect
            x="63.3245"
            y="28"
            width="10.0264"
            height="40"
            rx="5.01319"
            fill="url(#paint7_linear_2611_79)"
          />
          <rect
            x="147.757"
            y="28"
            width="10.0264"
            height="40"
            rx="5.01319"
            fill="url(#paint8_linear_2611_79)"
          />
          <rect
            x="189.974"
            y="44"
            width="10.0264"
            height="10"
            rx="5"
            fill="url(#paint9_linear_2611_79)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2611_79"
              x1="5.16359"
              y1="35.5"
              x2="17.8686"
              y2="44.9306"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.498021" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2611_79"
              x1="89.5963"
              y1="35.5"
              x2="102.301"
              y2="44.9306"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.498021" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_2611_79"
              x1="47.3799"
              y1="10.5"
              x2="65.9483"
              y2="15.0943"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.498021" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_2611_79"
              x1="131.813"
              y1="10.5"
              x2="150.381"
              y2="15.0943"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.498021" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_2611_79"
              x1="174.029"
              y1="35.5"
              x2="186.734"
              y2="44.9306"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.498021" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_2611_79"
              x1="26.2718"
              y1="23"
              x2="43.5912"
              y2="29.4279"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.498021" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_2611_79"
              x1="110.704"
              y1="23"
              x2="128.024"
              y2="29.4279"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.498021" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_2611_79"
              x1="68.4881"
              y1="23"
              x2="85.8076"
              y2="29.4279"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.498021" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_2611_79"
              x1="152.921"
              y1="23"
              x2="170.24"
              y2="29.4279"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.498021" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_2611_79"
              x1="195.137"
              y1="42.75"
              x2="201.288"
              y2="51.8805"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.498021" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
          </defs>
        </svg>
      </g>
      <g id="frame2">
        <svg
          width="50"
          height="96"
          viewBox="0 0 200 96"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="28"
            width="10.0264"
            height="40"
            rx="5.01319"
            fill="url(#paint0_linear_2611_81)"
          />
          <rect
            x="84.4327"
            y="44"
            width="10.0264"
            height="10"
            rx="5"
            fill="url(#paint1_linear_2611_81)"
          />
          <rect
            x="42.2164"
            y="28"
            width="10.0264"
            height="40"
            rx="5.01319"
            fill="url(#paint2_linear_2611_81)"
          />
          <rect
            x="126.649"
            y="28"
            width="10.5541"
            height="40"
            rx="5.27704"
            fill="url(#paint3_linear_2611_81)"
          />
          <rect
            x="168.865"
            y="28"
            width="10.0264"
            height="40"
            rx="5.01319"
            fill="url(#paint4_linear_2611_81)"
          />
          <rect
            x="21.1082"
            y="18"
            width="10.0264"
            height="60"
            rx="5.01319"
            fill="url(#paint5_linear_2611_81)"
          />
          <rect
            x="105.541"
            y="38"
            width="10.0264"
            height="20"
            rx="5.01319"
            fill="url(#paint6_linear_2611_81)"
          />
          <rect
            x="63.3245"
            y="38"
            width="10.0264"
            height="20"
            rx="5.01319"
            fill="url(#paint7_linear_2611_81)"
          />
          <rect
            x="147.757"
            y="18"
            width="10.0264"
            height="60"
            rx="5.01319"
            fill="url(#paint8_linear_2611_81)"
          />
          <rect
            x="189.974"
            y="38"
            width="10.0264"
            height="20"
            rx="5.01319"
            fill="url(#paint9_linear_2611_81)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2611_81"
              x1="5.01319"
              y1="28"
              x2="18.089"
              y2="31.7692"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2611_81"
              x1="89.4459"
              y1="44"
              x2="95.5255"
              y2="51.0099"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_2611_81"
              x1="47.2295"
              y1="28"
              x2="60.3053"
              y2="31.7692"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_2611_81"
              x1="131.926"
              y1="28"
              x2="145.577"
              y2="32.1421"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_2611_81"
              x1="173.879"
              y1="28"
              x2="186.954"
              y2="31.7692"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_2611_81"
              x1="26.1214"
              y1="18"
              x2="39.7792"
              y2="20.6247"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_2611_81"
              x1="110.554"
              y1="38"
              x2="121.183"
              y2="44.128"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_2611_81"
              x1="68.3377"
              y1="38"
              x2="78.9671"
              y2="44.128"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_2611_81"
              x1="152.77"
              y1="18"
              x2="166.428"
              y2="20.6247"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_2611_81"
              x1="194.987"
              y1="38"
              x2="205.616"
              y2="44.128"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
          </defs>
        </svg>
      </g>
      <g id="frame3">
        <svg
          width="50"
          height="96"
          viewBox="0 0 200 96"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="28"
            width="10.0264"
            height="40"
            rx="5.01319"
            fill="url(#paint0_linear_2611_81)"
          />
          <rect
            x="84.4327"
            y="44"
            width="10.0264"
            height="10"
            rx="5"
            fill="url(#paint1_linear_2611_81)"
          />
          <rect
            x="42.2164"
            y="28"
            width="10.0264"
            height="40"
            rx="5.01319"
            fill="url(#paint2_linear_2611_81)"
          />
          <rect
            x="126.649"
            y="28"
            width="10.5541"
            height="40"
            rx="5.27704"
            fill="url(#paint3_linear_2611_81)"
          />
          <rect
            x="168.865"
            y="28"
            width="10.0264"
            height="40"
            rx="5.01319"
            fill="url(#paint4_linear_2611_81)"
          />
          <rect
            x="21.1082"
            y="18"
            width="10.0264"
            height="60"
            rx="5.01319"
            fill="url(#paint5_linear_2611_81)"
          />
          <rect
            x="105.541"
            y="38"
            width="10.0264"
            height="20"
            rx="5.01319"
            fill="url(#paint6_linear_2611_81)"
          />
          <rect
            x="63.3245"
            y="38"
            width="10.0264"
            height="20"
            rx="5.01319"
            fill="url(#paint7_linear_2611_81)"
          />
          <rect
            x="147.757"
            y="18"
            width="10.0264"
            height="60"
            rx="5.01319"
            fill="url(#paint8_linear_2611_81)"
          />
          <rect
            x="189.974"
            y="38"
            width="10.0264"
            height="20"
            rx="5.01319"
            fill="url(#paint9_linear_2611_81)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2611_81"
              x1="5.01319"
              y1="28"
              x2="18.089"
              y2="31.7692"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2611_81"
              x1="89.4459"
              y1="44"
              x2="95.5255"
              y2="51.0099"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_2611_81"
              x1="47.2295"
              y1="28"
              x2="60.3053"
              y2="31.7692"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_2611_81"
              x1="131.926"
              y1="28"
              x2="145.577"
              y2="32.1421"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_2611_81"
              x1="173.879"
              y1="28"
              x2="186.954"
              y2="31.7692"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_2611_81"
              x1="26.1214"
              y1="18"
              x2="39.7792"
              y2="20.6247"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_2611_81"
              x1="110.554"
              y1="38"
              x2="121.183"
              y2="44.128"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_2611_81"
              x1="68.3377"
              y1="38"
              x2="78.9671"
              y2="44.128"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_2611_81"
              x1="152.77"
              y1="18"
              x2="166.428"
              y2="20.6247"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_2611_81"
              x1="194.987"
              y1="38"
              x2="205.616"
              y2="44.128"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.50411" stopColor="#3F6BA3" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
          </defs>
        </svg>
      </g>
      <g id="frame4">
        <svg
          width="50"
          height="96"
          viewBox="0 0 200 96"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="38"
            width="10.0264"
            height="20"
            rx="5.01319"
            fill="url(#paint0_linear_2611_105)"
          />
          <rect
            x="84.4327"
            y="38"
            width="10.0264"
            height="20"
            rx="5.01319"
            fill="url(#paint1_linear_2611_105)"
          />
          <rect
            x="42.2164"
            y="28"
            width="10.0264"
            height="40"
            rx="5.01319"
            fill="url(#paint2_linear_2611_105)"
          />
          <rect
            x="126.649"
            y="18"
            width="10.0264"
            height="60"
            rx="5.01319"
            fill="url(#paint3_linear_2611_105)"
          />
          <rect
            x="168.865"
            y="28"
            width="10.0264"
            height="40"
            rx="5.01319"
            fill="url(#paint4_linear_2611_105)"
          />
          <rect
            x="21.1082"
            y="28"
            width="10.0264"
            height="40"
            rx="5.01319"
            fill="url(#paint5_linear_2611_105)"
          />
          <rect
            x="105.541"
            y="44"
            width="10.0264"
            height="10"
            rx="5"
            fill="url(#paint6_linear_2611_105)"
          />
          <rect
            x="63.3245"
            y="18"
            width="10.0264"
            height="60"
            rx="5.01319"
            fill="url(#paint7_linear_2611_105)"
          />
          <rect
            x="147.757"
            y="38"
            width="10.0264"
            height="20"
            rx="5.01319"
            fill="url(#paint8_linear_2611_105)"
          />
          <rect
            x="189.974"
            y="38"
            width="10.0264"
            height="20"
            rx="5.01319"
            fill="url(#paint9_linear_2611_105)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2611_105"
              x1="5.01319"
              y1="38"
              x2="14.7039"
              y2="43.5648"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.496207" stopColor="#3D6DA2" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2611_105"
              x1="89.4459"
              y1="38"
              x2="99.1366"
              y2="43.5648"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.496207" stopColor="#3D6DA2" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_2611_105"
              x1="47.2295"
              y1="28"
              x2="59.1343"
              y2="31.4181"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.496207" stopColor="#3D6DA2" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_2611_105"
              x1="131.662"
              y1="18"
              x2="144.093"
              y2="20.3794"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.496207" stopColor="#3D6DA2" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_2611_105"
              x1="173.879"
              y1="28"
              x2="185.783"
              y2="31.4181"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.496207" stopColor="#3D6DA2" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_2611_105"
              x1="26.1214"
              y1="28"
              x2="38.0261"
              y2="31.4181"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.496207" stopColor="#3D6DA2" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_2611_105"
              x1="110.554"
              y1="44"
              x2="116.111"
              y2="50.3818"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.496207" stopColor="#3D6DA2" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_2611_105"
              x1="68.3377"
              y1="18"
              x2="80.7685"
              y2="20.3794"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.496207" stopColor="#3D6DA2" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_2611_105"
              x1="152.77"
              y1="38"
              x2="162.461"
              y2="43.5648"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.496207" stopColor="#3D6DA2" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_2611_105"
              x1="194.987"
              y1="38"
              x2="204.677"
              y2="43.5648"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7438B0" />
              <stop offset="0.496207" stopColor="#3D6DA2" />
              <stop offset="1" stopColor="#00A793" />
            </linearGradient>
          </defs>
        </svg>
      </g>
    </AnimatedSVG4Frames>
  );
};
