import styled from "styled-components";

export const DivMain = styled.section`
  width: auto;
  max-width: 920px;
  margin: 0 auto;
  min-height: calc(100svh - 70px);
  padding: 1rem;
  margin-top: 113px;

  @media (max-width: 768px) {
    padding-top: 2rem;
    width: auto;
    padding-left: 2rem;
  }

  .nav-tabs-custom {
    padding-top: 11px;
    font-size: 11px;
    justify-content: space-between;
    flex-wrap: nowrap;
    @media (min-width: 768px) {
      font-size: unset;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
`;

export const PageHeaderPrompt = styled.div`
  margin-top: -10px;
  padding-bottom: 5px;
  .page-header-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    .title {
      font-size: 26px;
      font-weight: 600;
      letter-spacing: 0.3px;
      color: var(--vz-gray-700);
    }
  }
`;

export const DivSearch = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;

  .input-container {
    position: relative;
    width: 100%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .btn-new-prompt {
    width: 166px;
  }

  @media (max-width: 768px) {
    .btn-new-prompt {
      max-width: 149px;
      width: 100%;
    }
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
  }

  .input-with-icon {
    height: 40px;
    width: 100%;
    padding-left: 35px;
    max-width: 96%;
    background-color: #f3f6f9;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 3rem;
      display: flex;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 3rem;
    width: auto;
    display: flex;
    justify-content: space-between;
  }
`;

export const InputSearch = styled.input`
  width: 100%;
  height: 38px;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 4px;

  @media (max-width: 768px) {
    width: 624px;
    height: 36px;
    margin-right: 23px;
  }
`;

export const ButtonSearch = styled.button`
  width: 143px;
  height: 38px;
  background-color: #26b4a3;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0 4px 4px 0; /* Right corners rounded */

  @media (max-width: 768px) {
    height: 36px;
    font-family: "Inter";
    font-size: 11px;
  }
`;

export const ButtonAddSearch = styled.button`
  width: 36px;
  height: 38px;
  margin-left: 10px;
  background-color: #00a793;

  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px 0 0 4px;

  @media (max-width: 768px) {
    width: 30px;
    height: 36px;
    margin-left: 0;
  }
`;

export const TabContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: auto;
  }

  .nav-tabs-custom {
    font-size: 11px;
    justify-content: space-between;
    flex-wrap: nowrap;
    @media (min-width: 768px) {
      font-size: unset;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
`;

export const NavTabs = styled.ul`
  display: flex;
  gap: 20px;
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #ddd;

  @media (max-width: 768px) {
    gap: 6px;
  }
`;

export const NavTab = styled.li`
  padding: 10px 20px;
  cursor: pointer;
  color: #000;
  &.active {
    border-bottom: 3px solid #00a793;
    color: #00a793;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
  }
`;

export const Modal = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 53%;
  width: 755px;
  height: 543px;
  background: #fff;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 16px;
    width: 327px;
    height: 491px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  background-color: #ccede9;
  padding: 0 20px;
  margin-bottom: 17px;

  @media (max-width: 768px) {
    height: auto;
    padding: 10px 20px;
  }
`;

export const ModalHeaderTitle = styled.h4`
  display: flex;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  color: #495057;
  padding-top: 18px;
  font-weight: bold;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding-top: 13px;
  }
`;

export const ModalHeaderButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #495057;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const ModalSubtitle = styled.div`
  height: 20px;
  width: 702px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #212529;
  margin-left: 23px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    font-size: 12px;
  }
`;

export const ModalInput = styled.input<{ error: boolean }>`
  width: 702px;
  padding: 9px;
  margin-bottom: 20px;
  border: 1px solid ${(props) => (props.error ? "red" : "#ccc")};
  border-radius: 4px;
  margin-left: 23px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const ModalLabel = styled.p`
  font-size: 12px;
  color: #878a99;
  margin-left: 23px;

  @media (max-width: 768px) {
    margin-left: 0;
    font-size: 11px;
  }
`;

export const ModalTextArea = styled.textarea<{ error: boolean }>`
  width: 702px;
  height: 178px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid ${(props) => (props.error ? "red" : "#ccc")};
  border-radius: 4px;
  margin-left: 23px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    height: 89px;
  }
`;

export const ModalButton = styled.button`
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
`;

export const LoadingSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #00a793;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    border-width: 4px;
  }
`;

export const PromptList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 17px;
  margin-top: 42px;

  @media (max-width: 768px) {
    width: auto;
  }
`;

export const NoPromptsMessage = styled.div`
  font-size: 20px;
  color: #777;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Container = styled.div`
  width: 242px;
  height: 111px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  @media (max-width: 768px) {
  }
`;

export const InnerRectangle = styled.div`
  width: 86.69px;
  height: 103.84px;
  border-radius: 4px;
  background-color: #ffffff;
  position: absolute;
  top: 30.16px;
  left: 76px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Message = styled.h5`
  margin-top: 20px;
  font-size: 16px;
  color: #adb5bd;
  text-align: center;
  z-index: 1;
  margin-top: 118px;
  font-family: "Montserrat";
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
  padding-top: 39px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
