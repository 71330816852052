/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  actionSelectChat,
  selectorConversation,
  actionSetConversations,
  actionSetInputText,
} from "../../redux/chatSlice";
import { useDispatch } from "react-redux";
import { actionSetPageTitle } from "../../redux/layout/reducer";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { RiDeleteBinLine } from "@remixicon/react";
import { chatService, IProject } from "../../services";
import { selectorProjects } from "../../redux/projectSlice";
import { ConfirmDeleteModal } from "../../components/Modals/ConfirmDeleteModal";

const VerticalLayout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const conversations = useSelector(selectorConversation);
  const projects = useSelector(selectorProjects);
  const location = useLocation();
  const navigate = useNavigate();

  const [modalToggle, setModalToggle] = useState(false);
  const [itemIdToRemove, setItemIdToRemove] = useState(null);
  const [projectIdToRemove, setProjectIdToRemove] = useState(null);
  const [activeItemId, setActiveItemId] = useState(-1);

  const projectMap: { [key: number]: IProject } = projects.reduce(
    (map, project) => {
      map[project.id] = project;
      return map;
    },
    {} as { [key: number]: IProject },
  );

  const handleCancelDelete = () => {
    setModalToggle(false);
  };

  const combinedChats = conversations.map((conversation) => {
    const project = projectMap[conversation.project_id];
    return {
      ...conversation,
      project_title: project ? project.title : "Unknown Project",
      is_public: project ? project.is_public : false,
      icon: project ? project.icon : "unknownIcon.svg",
    };
  });
  const filteredChats = combinedChats.filter((chat) => !chat.is_public);
  const sortedChats = filteredChats.sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );
  const uniqueTitles = new Set();
  const uniqueChats = sortedChats.filter((chat) => {
    if (uniqueTitles.has(chat.project_title)) {
      return false;
    } else {
      uniqueTitles.add(chat.project_title);
      return true;
    }
  });
  const recentChats = uniqueChats.slice(0, 4);

  function redirectToChat(item: any, newChat: boolean): void {
    if (newChat) {
      console.log("item", item);
      if (item.project_id) {
        dispatch(actionSelectChat({ selectedChatId: item.project_id, emptyChat: newChat }));
        setActiveItemId(item.project_id);
      } else {
        dispatch(actionSelectChat({ selectedChatId: item.id, emptyChat: newChat }));
        setActiveItemId(item.id);
      }
    } else {
      dispatch(actionSelectChat({ selectedChatId: item.id, emptyChat: newChat }));
      setActiveItemId(item.id);
    }

    if (location.pathname !== "/home") {
      navigate("/home");
    }
  }

  function changePageTitle(title: string = "") {
    dispatch(actionSetPageTitle(title));
  }

  const handleRemoveClick = (itemId: any, projectId: any) => {
    setItemIdToRemove(itemId);
    setProjectIdToRemove(projectId);
    setModalToggle(true);
  };

  const handleConfirm = async () => {
    if (projectIdToRemove !== null && itemIdToRemove !== null) {
      const success = await chatService.deleteChat({
        projectId: projectIdToRemove,
        sessionId: itemIdToRemove,
      });
      if (success) {
        const updatedConversations = conversations.filter((item) => item.id !== itemIdToRemove);
        dispatch(actionSetConversations(updatedConversations));
        setItemIdToRemove(null);
        setProjectIdToRemove(null);
        setActiveItemId(1);
        dispatch(
          actionSelectChat({
            selectedChatId: Number(process.env.REACT_APP_DEFAULT_CHAT_ID),
            emptyChat: true,
          }),
        );
      }
    }
  };

  function getDataCategory(data: string) {
    const formatterDate = moment(data, true);
    const today = moment();
    const yesterday = moment().subtract(1, "days");
    const sevenDaysAgo = moment().subtract(7, "days");
    const thirtyDaysAgo = moment().subtract(30, "days");
    const firstDayOfLastMonth = moment().subtract(1, "months").startOf("month");
    const lastDayOfLastMonth = moment().subtract(1, "months").endOf("month");
    const firstDayOfTheMonthBeforeLast = moment().subtract(2, "months").startOf("month");
    const lastDayOfTheMonthBeforeLast = moment().subtract(2, "months").endOf("month");

    if (formatterDate.isSame(today, "day")) {
      return t("pages.chat.components.timestamp.today");
    } else if (formatterDate.isSame(yesterday, "day")) {
      return t("pages.chat.components.timestamp.yesterday");
    } else if (formatterDate.isAfter(sevenDaysAgo)) {
      return t("pages.chat.components.timestamp.sevenDaysAgo");
    } else if (formatterDate.isAfter(thirtyDaysAgo)) {
      return t("pages.chat.components.timestamp.thirtyDaysAgo");
    } else if (formatterDate.isBetween(firstDayOfLastMonth, lastDayOfLastMonth, "day", "[]")) {
      return t("pages.chat.components.timestamp.lastMonth");
    } else if (
      formatterDate.isBetween(
        firstDayOfTheMonthBeforeLast,
        lastDayOfTheMonthBeforeLast,
        "day",
        "[]",
      )
    ) {
      return t("pages.chat.components.timestamp.monthBeforeLast");
    }

    // Se não se encaixar em nenhuma categoria, retorna a própria data
    return t("pages.chat.components.timestamp.moreThanSixtyDaysAgo");
  }

  return (
    <React.Fragment>
      <li className="menu-title">
        <span className="sidemenu-title-chat">
          {t("pages.chat.components.menu.corporateAgents").toUpperCase()}
        </span>
      </li>
      {projects
        .filter((item) => item.is_public)
        .map((item, index) => {
          return (
            <li
              key={item.id}
              className="nav-item navbar-main"
              onClick={() => redirectToChat(item, true)}
            >
              <div className="nav-link menu-link hover-background">
                <i>
                  <img src={`/icons/${item.icon}`} className="me-3 mb-1" alt="" />
                </i>
                <span data-key="t-apps">{item.title}</span>
              </div>
            </li>
          );
        })}
      <li className="nav-item mb-3 navbar-main" onClick={() => changePageTitle("")}>
        <Link to="/projects" className="nav-link menu-link hover-background">
          <i>
            <img src="/icons/icon2.svg" className="me-3" />
          </i>
          <span>{t("pages.projects.tab")}</span>
        </Link>
      </li>
      <li className="menu-title">
        <span className="sidemenu-title-chat">
          {t("pages.chat.components.menu.personalAgents").toUpperCase()}
        </span>
      </li>
      {recentChats.map((item, index) => (
        <li key={index} className="nav-item navbar-main" onClick={() => redirectToChat(item, true)}>
          <div className="nav-link menu-link hover-background ">
            <i>
              <img src={`/icons/${item.icon}`} className="me-3 mb-1" />
            </i>
            <span data-key="t-apps">{item.project_title}</span>
          </div>
        </li>
      ))}
      <li style={{ paddingTop: "13px" }} className="menu-title">
        <span className="sidemenu-title-chat">
          {t("pages.chat.components.menu.conversations").toUpperCase()}
        </span>
      </li>
      {conversations.map((item, index) => {
        const date = getDataCategory(item.created_at);
        return (
          <div key={item.id}>
            {index === 0 || getDataCategory(conversations[index - 1].created_at) !== date ? (
              <li style={{ color: "#878A99" }} className="menu-title chatTime">
                <span style={{ color: "#878A99" }} className="chatTitle">
                  {date}
                </span>
              </li>
            ) : (
              ""
            )}
            <li className="nav-item" onClick={() => redirectToChat(item, false)}>
              <div
                className={`chatContent ${activeItemId === item.id ? "nav-link active chat" : "nav-link chat"}`} // Adiciona a classe 'active' se o item for o ativo
              >
                <span>{item.title}</span>
                <div className="parent-container menu-icon">
                  <UncontrolledDropdown>
                    <DropdownToggle tag="span">...</DropdownToggle>
                    <DropdownMenu end container="body">
                      <DropdownItem onClick={() => handleRemoveClick(item.id, item.project_id)}>
                        <RiDeleteBinLine className="me-2" />
                        <span>Remover</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </li>
          </div>
        );
      })}
      <ConfirmDeleteModal
        isOpen={modalToggle}
        toggle={handleCancelDelete}
        message={t("pages.chat.components.notifications.deleteConfirmation")}
        onConfirm={handleConfirm}
        onCancel={handleCancelDelete}
      />
    </React.Fragment>
  );
};

export default VerticalLayout;
