import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

//Import Components
import VerticalLayout from "./VerticalLayouts";
import { Container } from "reactstrap";
import "./layout.css";
import { useDispatch } from "react-redux";
import { actionSelectChat } from "../redux/chatSlice";

const Sidebar = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  function setInitialAgent(): void {
    dispatch(
      actionSelectChat({
        selectedChatId: Number(process.env.REACT_APP_DEFAULT_CHAT_ID),
        emptyChat: true,
      }),
    );
  }

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu background-sidebar">
        <div className="navbar-brand-box">
          <Link to="/home" className="logo logo-dark" onClick={() => setInitialAgent()}>
            <span className="logo-sm">
              <img src="/kyno-low-logo.svg" alt="" height="35" />
            </span>
            <span className="logo-lg">
              <img src="/kyno-high-logo.svg" alt="" height="40" />
            </span>
            {/* <span className="logo-sm">
              <img src="/logo.png" alt="" height="35" />
            </span>
            <span className="logo-lg">
              <img src="/timenow-horizontal-cor.png" alt="" height="40" />
            </span> */}
          </Link>
        </div>
        <React.Fragment>
          <SimpleBar id="scrollbar" className="simplebar-with-footer">
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <VerticalLayout />
              </ul>
            </Container>
          </SimpleBar>
          <div className="sidebar-footer">
            <img src="/a-timenow-product.svg" className="high-logo" />
            <img src="/timenow-product.svg" className="little-logo" />
          </div>
          <div className="sidebar-background"></div>
        </React.Fragment>
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
