import { Button, Input, Label } from "reactstrap";
import {
  ActionButtonContainer,
  FormInputText,
  GptForm,
  GptKnowledgeContainer,
  ProjectDetailsContainer,
} from "./styled";
import { DropzoneFile } from "../../../components/shared/DropzoneFile";
import { useEffect, useState } from "react";
import { ISource, projectService, sourcesService } from "../../../services";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../redux/authenticationSlice";
import { useBlocker, useNavigate } from "react-router-dom";
import { ShareGPTModal } from "../../../components/Modals/ShareGPTModal";
import { IUserMicrosoft, PermissionsArray } from "../../../services/users";
import { GrantedUsersTable } from "../../../components/Projects/GrantedUsersTable";
import { routesManager } from "../../../routes/routesManager";
import { LoadingOverlay } from "../../../components/shared/LoadingOverlay";
import { useDispatch } from "react-redux";
import { actionSetLoading, selectorLoadingProgress } from "../../../redux/projectSlice";
import { actionSelectChat } from "../../../redux/chatSlice";
import { actionDisplayNotification } from "../../../redux/notificationSlice";
import { useTranslation } from "react-i18next";
import { SuccessCreateGPTModal } from "../../../components/Projects/SuccessCreateGPTModal";
import { useGPTForm } from "./useGPTForm";
import { selectorMicrosoftUsers } from "../../../redux/userSlice";
import { ExitWithoutSaveModal } from "../../../components/Modals/ExitWithoutSaveModal";

export interface SelectedUsersWithPermissions {
  user: IUserMicrosoft;
  delete: boolean;
  upload: boolean;
  change?: boolean;
}

export function ProjectDetails() {
  const userData = useSelector(selectUserData);
  const microsoftUsers = useSelector(selectorMicrosoftUsers);
  const loadingProgress = useSelector(selectorLoadingProgress);
  const dispatch = useDispatch();
  const id = sessionStorage.getItem("project-id-form");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    errors,
    formData,
    getAllFieldsFilled,
    getHasError,
    setFormData,
    shouldDisableFields,
    validateForm,
    isEditing,
    dirt,
    setDirt,
    handleChange,
  } = useGPTForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [openSuccessCreateModal, setOpenSuccessCreateModal] = useState<boolean>(false);
  const [selectedGrantedUsers, setSelectedGrantedUsers] = useState<SelectedUsersWithPermissions[]>(
    [],
  );
  const [sources, setSources] = useState<ISource[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [accessToUpload, setAccessToUpload] = useState<boolean>(false);
  const [accessToDelete, setAccessToDelete] = useState<boolean>(false);

  const { state, proceed, reset } = useBlocker(
    ({ currentLocation, nextLocation }) =>
      nextLocation.pathname !== currentLocation.pathname && dirt,
  );

  const onFileUpload = async (projectId: number) => {
    await sourcesService.uploadSources({
      files: filesToUpload,
      projectId,
    });
  };

  const onInviteUsers = async (projectId: number) => {
    // segregate users by permissions
    const usersWithPermissions = selectedGrantedUsers
      .filter((users) => users.change)
      .reduce(
        (acc, user) => {
          if (user.delete && user.upload) {
            acc.deleteAndUpload.push(user.user.email);
          } else if (user.delete) {
            acc.delete.push(user.user.email);
          } else if (user.upload) {
            acc.upload.push(user.user.email);
          } else {
            acc.onlyRead.push(user.user.email);
          }
          return acc;
        },
        {
          delete: [] as string[],
          upload: [] as string[],
          deleteAndUpload: [] as string[],
          onlyRead: [] as string[],
        },
      );
    const inviteParams = [
      {
        projectId,
        emails: usersWithPermissions.delete,
        permissions: ["files:delete"] as PermissionsArray,
      },
      {
        projectId,
        emails: usersWithPermissions.upload,
        permissions: ["files:upload"] as PermissionsArray,
      },
      {
        projectId,
        emails: usersWithPermissions.deleteAndUpload,
        permissions: ["files:delete", "files:upload"] as PermissionsArray,
      },
      {
        projectId,
        emails: usersWithPermissions.onlyRead,
        permissions: [] as PermissionsArray,
      },
    ]
      .filter((param) => param.emails.length)
      .map(async (param) => await projectService.inviteManyUserToProject(param));
    return inviteParams;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    validateForm();
    if (getHasError()) return;
    setDirt(false);

    const body = {
      title: formData.name,
      description: formData.description,
      system_tone: formData.prompt,
      is_public: formData.isPublic,
      internal_knowledge_only: formData.internalKnowledgeOnly,
      icon: formData.icon,
      priority: 0,
      temperature: formData.temperature,
      seed: formData.seed,
      number_of_documents_retrived_to_context: formData.number_of_documents_retrived_to_context,
      min_score: formData.min_score,
      external: formData.internalKnowledgeOnly ? false : formData.external,
    };

    try {
      let projectId = parseInt(id as string);
      const ADD_PROGRESS = 20;
      dispatch(
        actionSetLoading({
          load: true,
          loadingProgess: loadingProgress + ADD_PROGRESS,
          loadingText: "Salvando projeto",
        }),
      );
      setLoading(true);

      if (isEditing && id && (formData.owner?.id === userData?.id || userData?.is_superuser)) {
        dispatch(
          actionSetLoading({
            load: true,
            loadingProgess: loadingProgress + ADD_PROGRESS,
            loadingText: "Seu projeto está sendo editado",
          }),
        );
        const editedGpt = await projectService.editProject({ project: body, project_id: id });
        projectId = editedGpt.id;
      } else if (!isEditing) {
        dispatch(
          actionSetLoading({
            load: true,
            loadingProgess: loadingProgress + ADD_PROGRESS,
            loadingText: "Seu projeto está sendo criado",
          }),
        );
        const createdGpt = await projectService.createProject(body);
        projectId = createdGpt.id;
      }
      if (!!filesToUpload.length && accessToUpload) {
        dispatch(
          actionSetLoading({
            load: true,
            loadingProgess: loadingProgress + ADD_PROGRESS,
            loadingText: "Seus arquivos estão sendo enviados",
          }),
        );
        await onFileUpload(projectId);
      }
      if (!!selectedGrantedUsers.length && !formData.isPublic) {
        dispatch(
          actionSetLoading({
            load: true,
            loadingProgess: loadingProgress + ADD_PROGRESS,
            loadingText: "Usuários estão sendo convidados",
          }),
        );
        await onInviteUsers(projectId);
      }
      dispatch(actionSelectChat({ selectedChatId: projectId, emptyChat: true }));
      if (!isEditing) {
        setOpenSuccessCreateModal(true);
      } else {
        navigate(routesManager.getProjectsRoute());
      }
    } catch (error) {
      let message = t("global.failureCreateMessage");
      if (isEditing) {
        message = t("global.failureUpdateMessage");
      }
      dispatch(
        actionDisplayNotification({
          messages: [message],
          severity: "error",
        }),
      );
      setDirt(true);
    } finally {
      setLoading(false);
      dispatch(actionSetLoading({ load: false, loadingProgess: 0, loadingText: "" }));
    }
  };

  async function fetchGrantedUser(projectId: string, userOwnerId?: number) {
    try {
      const res = await projectService.getGrantedUsers(projectId);
      const grantedUsers = res.map((user) => ({
        user: {
          email: user.email,
          name:
            microsoftUsers.find((microsoftUser) => microsoftUser.email === user.email)?.name || "",
        },
        delete: user.permissions.includes("files:delete"),
        upload: user.permissions.includes("files:upload"),
        change: false,
      }));
      setSelectedGrantedUsers(grantedUsers);
      const findUser = res.find((user) => user.id === userData?.id);
      const userThatsHasAccessToUpload = findUser?.permissions?.includes("files:upload");
      const userThatsHasAccessToDelete = findUser?.permissions?.includes("files:delete");
      const isAdmin = userData?.is_admin || userData?.is_superuser;
      const isOwner = userOwnerId === userData?.id;
      setAccessToUpload(isAdmin || isOwner || !!userThatsHasAccessToUpload);
      setAccessToDelete(isAdmin || isOwner || !!userThatsHasAccessToDelete);
    } catch (er) {
      setAccessToUpload(false);
      setAccessToDelete(false);
    }
  }

  async function fetchSources(projectId: number) {
    const data = await sourcesService.listSources({
      projectId: projectId,
    });
    setSources(data);
  }

  async function fetchProject(projectId: number) {
    const data = await projectService.getProject(projectId);
    setFormData({
      name: data.title,
      description: data.description,
      prompt: data.system_tone,
      isPublic: data.is_public,
      internalKnowledgeOnly: data.internal_knowledge_only,
      owner: data.user_owner,
      temperature: data.temperature,
      seed: data.seed,
      icon: data.icon,
      number_of_documents_retrived_to_context: data.number_of_documents_retrived_to_context,
      min_score: data.min_score,
      external: data.external,
    });
    return data;
  }

  useEffect(() => {
    if (id && parseInt(id) !== 0) {
      setLoading(true);
      (async () => {
        try {
          const project = await fetchProject(parseInt(id as string));
          await fetchGrantedUser(id, project?.user_owner.id);
          await fetchSources(parseInt(id as string));
        } catch {
          dispatch(
            actionDisplayNotification({
              messages: [t("global.failureRequestMessage")],
              severity: "error",
            }),
          );
          navigate(routesManager.getProjectsRoute());
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setAccessToDelete(true);
      setAccessToUpload(true);
    }
  }, [id]);

  useEffect(() => {
    const handler = setTimeout(() => {
      validateForm();
    }, 600);

    return () => {
      clearTimeout(handler);
    };
  }, [formData]);

  return (
    <ProjectDetailsContainer>
      {state === "blocked" ? (
        <ExitWithoutSaveModal
          isOpen={state === "blocked"}
          onConfirm={() => proceed()}
          toggle={() => reset!()}
        />
      ) : null}
      <LoadingOverlay />
      <SuccessCreateGPTModal
        isOpen={openSuccessCreateModal}
        toggle={() => setOpenSuccessCreateModal(false)}
      />
      <ShareGPTModal
        open={openShareModal}
        toggle={() => setOpenShareModal((prev) => !prev)}
        grantedUsers={selectedGrantedUsers}
        microsoftUsers={microsoftUsers}
        setGrantedUsers={setSelectedGrantedUsers}
      />
      <h2 className="page-title">
        {isEditing
          ? t("pages.projects.components.createEditPage.editTitle")
          : t("pages.projects.components.createEditPage.createTitle")}
      </h2>
      <GptForm onSubmit={handleSubmit}>
        <div className="form-item">
          <Label htmlFor="gpt-name" className="form-label">
            {t("pages.projects.components.createEditPage.name.label")}
            {formData.name.trim() === "" && <span style={{ color: "red" }}> *</span>}
          </Label>
          <Input
            type="text"
            id="gpt-name"
            name="name"
            required
            className="form-control"
            disabled={loading || shouldDisableFields}
            invalid={!!errors.name}
            value={formData.name}
            onChange={handleChange}
          />

          <FormInputText error={errors.name}>
            <span>
              {errors.name || t("pages.projects.components.createEditPage.name.inputHelper")}
            </span>
            <span>{formData.name.length}/20</span>
          </FormInputText>
        </div>
        <div className="form-item">
          <Label htmlFor="gpt-description" className="form-label">
            {t("pages.projects.components.createEditPage.description.label")}
            {formData.description.trim() === "" && <span style={{ color: "red" }}> *</span>}
          </Label>
          <Input
            id="gpt-description"
            name="description"
            className="form-control"
            invalid={!!errors.description}
            disabled={loading || shouldDisableFields}
            type="textarea"
            rows={3}
            value={formData.description}
            onChange={handleChange}
          />
          <FormInputText error={errors.description}>
            <span>
              {errors.description ||
                t("pages.projects.components.createEditPage.description.inputHelper")}
            </span>
            <span>{formData.description.length}/180</span>
          </FormInputText>
        </div>
        <div className="form-item">
          <Label htmlFor="gpt-prompt" className="form-label">
            {t("pages.projects.components.createEditPage.basePrompt.label")}
          </Label>
          <div className="form-description">
            {t("pages.projects.components.createEditPage.basePrompt.description")}
          </div>
          <Input
            id="gpt-prompt"
            name="prompt"
            className="form-control"
            invalid={!!errors.prompt}
            disabled={loading || shouldDisableFields}
            type="textarea"
            rows={18}
            value={formData.prompt}
            onChange={handleChange}
          />
          <FormInputText error={errors.prompt}>
            <span>
              {errors.prompt ||
                t("pages.projects.components.createEditPage.basePrompt.inputHelper")}
            </span>
            <span>{formData.prompt.length}/12000</span>
          </FormInputText>
        </div>
        {userData?.is_superuser && (
          <>
            <div className="form-item">
              <label>Temperatura</label>
              <input
                type="number"
                className="form-control"
                id="basiInput"
                min="0"
                max="2"
                step="0.1"
                placeholder="Temperature"
                value={formData.temperature}
                onChange={(e) => {
                  const target = e.target as HTMLInputElement;
                  const value = parseFloat(target.value);
                  if (value < 0) target.value = "0";
                  if (value > 2) target.value = "2";
                  setFormData((prevData) => ({
                    ...prevData,
                    temperature: value,
                  }));
                }}
              />
            </div>
            <div className="form-item">
              <label>Seed</label>
              <input
                type="number"
                className="form-control"
                id="basiInput"
                min="1"
                max="100"
                step="1"
                placeholder="Seed"
                value={formData.seed}
                onChange={(e) => {
                  const target = e.target as HTMLInputElement;
                  const value = parseInt(target.value);
                  if (value < 1) target.value = "1";
                  if (value > 100) target.value = "100";
                  setFormData((prevData) => ({
                    ...prevData,
                    seed: value,
                  }));
                }}
              />
            </div>
            <div className="form-item">
              <label>Min_score</label>
              <input
                type="number"
                className="form-control"
                id="basiInput"
                min="0"
                max="1"
                step="0.1"
                placeholder="min_score"
                value={formData.min_score}
                onChange={(e) => {
                  const target = e.target as HTMLInputElement;
                  const value = parseFloat(target.value);
                  if (value < 0) target.value = "0";
                  if (value > 1) target.value = "1";
                  setFormData((prevData) => ({
                    ...prevData,
                    min_score: value,
                  }));
                }}
              />
            </div>
            <div className="form-item">
              <label>Documents</label>
              <input
                type="number"
                className="form-control"
                id="basiInput"
                min="1"
                max="100"
                step="1"
                placeholder="Documents"
                value={formData.number_of_documents_retrived_to_context}
                onChange={(e) => {
                  const target = e.target as HTMLInputElement;
                  const value = parseFloat(target.value);
                  if (value < 1) target.value = "1";
                  if (value > 100) target.value = "100";
                  setFormData((prevData) => ({
                    ...prevData,
                    number_of_documents_retrived_to_context: value,
                  }));
                }}
              />
            </div>
            <div className="form-item">
              <label>Icon</label>
              <input
                type="text"
                className="form-control"
                id="basiInput"
                placeholder="icon"
                value={formData.icon}
                onChange={(e) => {
                  const target = e.target as HTMLInputElement;
                  const value = target.value;
                  setFormData((prevData) => ({
                    ...prevData,
                    icon: value,
                  }));
                }}
              />
            </div>
          </>
        )}

        <GptKnowledgeContainer>
          <h6 className="title">
            {t("pages.projects.components.createEditPage.knowledgeBase.title").toUpperCase()}
          </h6>
          <h6 className="subtitle">
            {t("pages.projects.components.createEditPage.knowledgeBase.documents")}
          </h6>
          <p className="description">
            {t("pages.projects.components.createEditPage.knowledgeBase.subtitle")}
            <a
              className="internal-policy-link"
              href="https://timenow.com.br/politica-de-protecao-de-dados/"
              target="_blank"
              rel="noreferrer"
            >
              {t("pages.projects.components.createEditPage.knowledgeBase.internalPolicy")}
            </a>
          </p>
          <DropzoneFile
            loading={loading}
            setSources={setSources}
            accessToDelete={accessToDelete}
            accessToUpload={accessToUpload}
            setDirt={setDirt}
            sources={sources}
            setFilesToUpload={setFilesToUpload}
          />
          <h6 className="subtitle">
            {t("pages.projects.components.createEditPage.knowledgeBase.gptVersion")}
          </h6>
          <p className="description">
            {t("pages.projects.components.createEditPage.knowledgeBase.gptVersionDescription")}
          </p>
          <p className="description">
            <strong>{t("pages.home.currentModel")}</strong>{" "}
            {t("pages.projects.components.createEditPage.knowledgeBase.gptVersion")}
          </p>
          <div className="form-check form-switch">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              disabled={loading || shouldDisableFields}
              name="internalKnowledgeOnly"
              id="internal-knowledge"
              checked={!formData.internalKnowledgeOnly}
              onChange={handleChange}
            />
            <Label className="form-check-label" htmlFor="internal-knowledge">
              {t("pages.projects.components.createEditPage.knowledgeBase.externalKnowledge")}
            </Label>
          </div>
          {!formData.internalKnowledgeOnly && (
            <div className="form-check form-switch">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                disabled={loading || shouldDisableFields}
                name="external"
                id="external"
                checked={formData.external}
                onChange={handleChange}
              />
              <Label className="form-check-label" htmlFor="external">
                {t("pages.projects.components.createEditPage.knowledgeBase.externalChat")}
              </Label>
            </div>
          )}
          <div className="share-header-container">
            <h6 className="title">
              {t("pages.projects.components.createEditPage.sharing.title").toUpperCase()}
            </h6>
            <Button
              color="primary"
              outline
              type="button"
              onClick={() => setOpenShareModal(true)}
              disabled={loading || shouldDisableFields}
              style={{ marginTop: "48px" }}
            >
              {t("pages.projects.components.createEditPage.sharing.shareBtn")}
            </Button>
          </div>
          <GrantedUsersTable
            grantedUsers={selectedGrantedUsers}
            setGrantedUsers={setSelectedGrantedUsers}
            setDirt={setDirt}
            isEditing={isEditing}
            projectId={parseInt(id as string)}
            isOwner={
              userData?.is_admin || userData?.is_superuser || formData.owner?.id === userData?.id
            }
          />
          {formData.isPublic || userData?.is_admin || userData?.is_superuser ? (
            <>
              <h6 className="subtitle">
                {t("pages.projects.components.createEditPage.privacity.title")}
              </h6>
              <div className="form-check form-switch">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="isPublic"
                  disabled={loading || shouldDisableFields}
                  id="public-gpt"
                  checked={formData.isPublic}
                  onChange={handleChange}
                />
                <Label className="form-check-label" htmlFor="public-gpt">
                  {t("pages.projects.components.createEditPage.privacity.label")}
                </Label>
              </div>
            </>
          ) : null}
        </GptKnowledgeContainer>
        <ActionButtonContainer>
          {shouldDisableFields && !dirt ? (
            <Button
              color="primary"
              type="button"
              onClick={() => navigate(routesManager.getProjectsRoute())}
              className="create-btn"
            >
              {t("global.back")}
            </Button>
          ) : (
            <>
              <Button
                color="danger"
                outline
                type="button"
                onClick={() => navigate(routesManager.getProjectsRoute())}
              >
                {t("global.cancel")}
              </Button>
              <Button
                color="primary"
                className="create-btn"
                disabled={loading || getHasError() || !getAllFieldsFilled(sources)}
              >
                {isEditing
                  ? t("pages.projects.components.createEditPage.editTitle")
                  : t("pages.projects.components.createEditPage.createTitle")}
              </Button>
            </>
          )}
        </ActionButtonContainer>
      </GptForm>
    </ProjectDetailsContainer>
  );
}
