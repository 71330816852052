import { useState, useContext, createContext, ReactNode } from "react";
import { ConfirmDeleteModal } from "../components/Modals/ConfirmDeleteModal";

interface DeleteDialogContextProps {
  onDeleteDialog: ({
    callback,
    message,
  }: {
    callback: () => void | Promise<void>;
    message: string;
  }) => void;
}

const DeleteDialogContext = createContext<DeleteDialogContextProps | undefined>(undefined);

export function DeleteDialogProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState<() => void>(() => {});
  const [dialogMessage, setDialogMessage] = useState<string>(
    "O item será excluído e essa ação não poderá ser desfeita.",
  );

  function onDeleteDialog({
    callback: onConfirmCallback,
    message,
  }: {
    callback: () => void;
    message: string;
  }) {
    setOnConfirm(() => onConfirmCallback);
    setIsOpen(true);
    setDialogMessage(message);
  }

  function closeDeleteDialog() {
    setIsOpen(false);
    setOnConfirm(() => {});
  }

  return (
    <DeleteDialogContext.Provider value={{ onDeleteDialog }}>
      {children}
      {isOpen && (
        <ConfirmDeleteModal
          isOpen={isOpen}
          message={dialogMessage}
          onConfirm={() => {
            onConfirm();
            closeDeleteDialog();
          }}
          toggle={closeDeleteDialog}
        />
      )}
    </DeleteDialogContext.Provider>
  );
}

export function useDeleteDialog() {
  const context = useContext(DeleteDialogContext);
  if (!context) {
    throw new Error("useDeleteModal must be used within a DeleteModalProvider");
  }
  return context;
}
