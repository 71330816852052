import React from "react";
import { Modal, ModalBody } from "reactstrap";
import * as yup from "yup";
import { Formik } from "formik";
import {
  ModalHeaderCreatPrompt,
  ModalHeaderTitle,
  ModalHeaderButton,
  ModalSubtitle,
  ModalInput,
  ModalLabel,
  ModalTextArea,
  ModalButton,
  ModalSubtitleTextArea,
} from "./styled";
import { IPrompt, promptsService } from "../../../services/prompts";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { RiCheckboxCircleLine, RiAlertLine } from "@remixicon/react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";

interface IFormData {
  title: string;
  prompt: string;
  is_public: boolean;
}

interface ToggleCustomSuccessModalPromptCreatProps {
  ModalToggle: boolean;
  setModalToggle: (value: boolean) => void;
  onConfirm: () => void;
  onAddPrompt: (prompt: IPrompt) => void;
}

const CreateModalPrompt: React.FC<ToggleCustomSuccessModalPromptCreatProps> = ({
  ModalToggle,
  setModalToggle,
  onConfirm,
  onAddPrompt,
}) => {
  const handleClose = () => {
    setModalToggle(false);
  };
  const { t } = useTranslation();
  const FormValidationSchema: yup.Schema<IFormData> = yup.object().shape({
    title: yup.string().required("O título é obrigatório."),
    prompt: yup.string().required("O prompt é obrigatório."),
    is_public: yup.boolean().required(),
  });

  return (
    <Modal size="lg" isOpen={ModalToggle} toggle={handleClose} centered>
      <ModalHeaderCreatPrompt>
        <ModalHeaderTitle>{t("pages.prompts.components.createNewPromptModal")}</ModalHeaderTitle>
        <ModalHeaderButton onClick={handleClose}>×</ModalHeaderButton>
      </ModalHeaderCreatPrompt>
      <ModalBody>
        <Formik
          initialValues={{
            title: "",
            prompt: "",
            is_public: false,
          }}
          validate={async (values) => {
            const errors: Record<string, string> = {};
            try {
              await FormValidationSchema.validate(values, {
                abortEarly: false,
              });
            } catch (err) {
              (err as yup.ValidationError).inner.forEach((item) => {
                if (!item.path) return;
                errors[item.path] = item.message;
              });
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const newPrompt = await promptsService.create(values);
              setSubmitting(false);
              setModalToggle(false);
              onAddPrompt(newPrompt);
              toast(t("Toast.createSuccess"), {
                toastId: "success1",
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                autoClose: 5000,
                className: "bg-success text-white",
                closeButton: true,
                icon: <RiCheckboxCircleLine />,
              });
              onConfirm();
            } catch (err) {
              setSubmitting(false);
              toast(t("Toast.createError"), {
                toastId: "error1",
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                autoClose: 5000,
                className: "bg-danger text-white",
                closeButton: true,
                icon: <RiAlertLine />,
              });
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <ModalSubtitle>{t("pages.prompts.components.promptTitle")}</ModalSubtitle>
              <ModalInput
                type="text"
                name="title"
                placeholder={t("pages.prompts.components.enterPrompt")}
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.title && touched.title)}
              />
              {errors.title && touched.title && <div className="error">{errors.title}</div>}
              <ModalLabel>{t("pages.prompts.components.promptDefaultTitle")}</ModalLabel>
              <ModalSubtitleTextArea>{t("pages.prompts.components.enterPrompt")}</ModalSubtitleTextArea>
              <ModalTextArea
                name="prompt"
                placeholder={t("pages.prompts.components.enterPrompt")}
                value={values.prompt}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={2000}
                error={Boolean(errors.prompt && touched.prompt)}
              />
              {errors.prompt && touched.prompt && <div className="error">{errors.prompt}</div>}
              <ModalLabel>
              {t("pages.prompts.components.mustContaintCaracters")}
              </ModalLabel>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name="is_public"
                  checked={values.is_public}
                  onChange={handleChange}
                />
                <Typography.Text className="ms-2">{`    ${t("pages.prompts.components.makePublicPrompt")}`}</Typography.Text>
              </div>
              {/* <label>
                <input
                  type="checkbox"
                  name="is_public"
                  checked={values.is_public}
                  onChange={handleChange}
                />
                Torná-lo público dentro da minha empresa
              </label> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <ModalButton
                  type="button"
                  onClick={handleClose}
                  style={{
                    backgroundColor: "#F3F6F9",
                    color: "#212529",
                  }}
                >
                  {t("global.cancel")}
                </ModalButton>
                <ModalButton
                  type="submit"
                  disabled={isSubmitting}
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "#00A793",
                    color: "#F3F6F9",
                  }}
                >
                  {t("pages.prompts.components.createNewPromptModal")}
                </ModalButton>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export { CreateModalPrompt };
